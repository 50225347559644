import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { useSidebarContext } from "../../context/sidebar_context";

const SidebarOngoingOrders = () => {
  const { isOngoingOrdersSelected } = useSidebarContext();

  return (
    <Wrapper>
      <NavLink
        to="/client/ongoing-orders"
        className={`${
          isOngoingOrdersSelected ? "sidebar-btn-selected" : "sidebar-btn"
        }`}
      >
        In progress
      </NavLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 50px;
  .sidebar-btn {
    clear: both;
    background-color: #ffffff;
    font-size: 18px;
    border: none;
    text-decoration: none;
  }

  .sidebar-btn-selected {
    clear: both;
    font-size: 18px;
    border: none;
    text-decoration: none;
  }
`;

export default SidebarOngoingOrders;
