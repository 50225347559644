import {
  NEW_ORDER_POPPUP_CLOSE,
  NEW_ORDER_POPPUP_CHOOSE_TEMPLATE,
  NEW_ORDER_POPPUP_CHOOSE_PLAN,
  NEW_ORDER_POPPUP_SUBMIT,
  NEW_ORDER_POPPUP_OPEN,
  NEW_ORDER_INCREMENT_STEP,
  NEW_ORDER_DECREMENT_STEP,
} from "../actions";

import { initialState } from "../context/new_order_context";

const new_order_reducer = (state, action) => {
  if (action.type === NEW_ORDER_POPPUP_OPEN) {
    return {
      ...state,
      isEditingNewOrder: true,
    };
  }

  if (action.type === NEW_ORDER_POPPUP_CLOSE) {
    console.log("Close new order pop-up");
    return initialState;
  }

  if (action.type === NEW_ORDER_POPPUP_CHOOSE_TEMPLATE) {
    console.log("Update new order template choice");
    return {
      ...state,
      templateChoice: action.templateChoice,
    };
  }

  if (action.type === NEW_ORDER_POPPUP_CHOOSE_PLAN) {
    console.log("Update new order plan choice");
    return {
      ...state,
      planChoice: action.planChoice,
    };
  }

  if (action.type === NEW_ORDER_POPPUP_SUBMIT) {
    console.log("Save new order");
    // TODO: save to DB
    return initialState;
  }

  if (action.type === NEW_ORDER_INCREMENT_STEP) {
    console.log("New order increment step");
    return {
      ...state,
      step: state.step + 1,
    };
  }

  if (action.type === NEW_ORDER_DECREMENT_STEP) {
    console.log("New order decrement step");
    return {
      ...state,
      step: state.step - 1,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default new_order_reducer;
