import { Routes, Route, Navigate } from "react-router-dom";
import { OperatorHomePage } from "./operator/pages";
import Profile from "./operator/components/Profile/Profile";
import NewOrders from "./operator/components/Orders/NewOrders";
import SubmittedOrders from "./operator/components/Orders/SubmittedOrders";
import InDisputeOrders from "./operator/components/Orders/InDisputeOrders";
import CompletedOrders from "./operator/components/Orders/CompletedOrders";
import OngoingOrders from "./operator/components/Orders/OngoingOrders";

import { Auth0Provider } from "@auth0/auth0-react";
import { SidebarProvider } from "./operator/context/sidebar_context";
import OperatorLoginRedirectPage from "./operator/pages/OperatorLoginRedirectPage";
import { OrderProvider } from "./operator/context/order_context";
import OngoingOrderDetail from "./operator/components/Orders/detail/OngoingOrderDetail";
import { TemplateProvider } from "./operator/context/template_context";
import { CompanyProvider } from "./operator/context/company_context";
import InDisputeOrderDetail from "./operator/components/Orders/detail/InDisputeOrderDetail";
import { DisputeProvider } from "./operator/context/dispute_context";

const OperatorRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<OperatorHomePage />}
        children={[
          <Route
            key="redirect"
            index={true}
            element={<Navigate to="profile" replace />}
          />,
          <Route key="profile" path="profile" element={<Profile />} />,
          <Route key="new-orders" path="new-orders" element={<NewOrders />} />,
          <Route
            key="ongoing-orders"
            path="ongoing-orders"
            element={<OngoingOrders />}
          />,
          <Route
            key="submitted-orders"
            path="submitted-orders"
            element={<SubmittedOrders />}
          />,
          <Route
            key="in-dispute-orders"
            path="in-dispute-orders"
            element={<InDisputeOrders />}
          />,
          <Route
            key="completed-orders"
            path="completed-orders"
            element={<CompletedOrders />}
          />,
          <Route
            key="order-detail"
            path="ongoing-orders/:id"
            element={<OngoingOrderDetail />}
          />,
          <Route
            key={"order-detail-in-dispute"}
            path="in-dispute-order-detail/:id"
            element={<InDisputeOrderDetail />}
          />,
        ]}
      />
      ,
      <Route
        key="operator_login"
        path="login"
        element={<OperatorLoginRedirectPage />}
      />
    </Routes>
  );
};

const OperatorLayout = () => {
  return (
    <Auth0Provider
      domain="dev-huqm3825znexykgx.us.auth0.com"
      clientId="9CfgeRG0lOcmWFT0qh6Ryak8aXVR3bKf"
      authorizationParams={{
        redirect_uri: window.location.origin + "/operator/profile",
        audience: "https://dev-huqm3825znexykgx.us.auth0.com/api/v2/",
      }}
      // Needed for safari
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <SidebarProvider>
        <OrderProvider>
          <TemplateProvider>
            <CompanyProvider>
              <DisputeProvider>
                <OperatorRoutes />
              </DisputeProvider>
            </CompanyProvider>
          </TemplateProvider>
        </OrderProvider>
      </SidebarProvider>
    </Auth0Provider>
  );
};

export default OperatorLayout;
