import styled from "styled-components";

import React, { useState, useEffect, useRef } from "react";
import { Box, IconButton, Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { homeStep1, homeStep2, homeStep3, homeStep4 } from "../../../assets";

const HomePageGuide = () => {
  return (
    <Wrapper>
      <HowToUseTitle>User Guide</HowToUseTitle>
      <ImageSlider />
    </Wrapper>
  );
};

// Image 1: create template page
// Image 2: create order page
// Image 3: monitor overall order progress
// Image 4: view order details to applied companies
const images = [homeStep1, homeStep2, homeStep3, homeStep4];

const ImageSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef();

  // Helper function to get previous index considering circular nature
  const getPrevIndex = (index) => (index - 1 + images.length) % images.length;

  // Helper function to get next index considering circular nature
  const getNextIndex = (index) => (index + 1) % images.length;

  const handleNext = () => {
    setActiveIndex(getNextIndex);
  };

  const handleBack = () => {
    setActiveIndex(getPrevIndex);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      const activeImg = slider.getElementsByClassName("active")[0];
      if (activeImg) {
        const sliderCenter = slider.offsetWidth / 2;
        const activeImgCenter =
          activeImg.offsetLeft + activeImg.offsetWidth / 2;
        const offsetPercentage =
          ((sliderCenter - activeImgCenter) / slider.offsetWidth) * 100;
        slider.style.transform = `translateX(${offsetPercentage}%)`;
      }
    }
  }, [activeIndex]);

  // Calculate indices for visible images
  const prevIndex = getPrevIndex(activeIndex);
  const nextIndex = getNextIndex(activeIndex);

  // Now, use prevIndex, activeIndex, and nextIndex to render only three images
  return (
    <Box
      sx={{
        overflow: "hidden",
        maxWidth: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "50px",
      }}
    >
      <Box
        ref={sliderRef}
        sx={{
          display: "flex",
          justifyContent: "center",
          transition: "transform 0.5s ease",
        }}
      >
        {[prevIndex, activeIndex, nextIndex].map((index) => (
          <Box
            key={index}
            component="img"
            src={images[index]}
            sx={{
              maxHeight: index === activeIndex ? 500 : 300, // Adjust focused image size
              transition: "transform 0.5s ease, opacity 0.5s ease",
              transform: index === activeIndex ? "scale(1.25)" : "scale(1)",
              opacity: index === activeIndex ? 1 : 0.5,
              flexShrink: 0, // Prevent images from shrinking
              marginRight: "10px", // Optional: Adds spacing between images
              marginLeft: "10px", // Optional: Adds spacing between images
            }}
          />
        ))}
      </Box>
      <Grid container justifyContent="center" spacing={2} sx={{ marginTop: 2 }}>
        <Grid item>
          <IconButton onClick={handleBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton onClick={handleNext}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const Wrapper = styled.div`
  background-color: var(--clr-grey-white);
  margin-top: 150px;
  margin-left: 100px;
  margin-right: 100px;
`;

const HowToUseTitle = styled.div`
  font-size: 40px;
  text-align: center;
  margin-top: 150px;
  font-weight: 200;
`;

export default HomePageGuide;
