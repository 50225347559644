import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";

import { useOrderContext } from "../../../context/order_context";
import OrderDetailHeader from "../../../../common/components/OrderDetailHeader";
import { useSidebarContext } from "../../../context/sidebar_context";
import { OrderDetailHeaderTable } from "../../../../common/components/OrderDetailHeaderTable";
import { useTemplateContext } from "../../../context/template_context";
import CompaniesSearchBar from "../../../../common/components/CompaniesSearchBar";
import { InProgressCompaniesTable } from "../table/InProgressCompaniesTable";
import { useCompanyContext } from "../../../context/company_context";
import { axiosPutWithAuthHeader } from "../../../../common/axios_utils";
import { SnackbarFailure } from "../../../../common/snackbar/SnackbarHelper";

const CompleteOrderButton = () => {
  const { selectedOrder } = useOrderContext();
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [failurePopupOpen, setFailurePopupOpen] = useState(false);

  const onClickCompleteOrder = async () => {
    const responseData = await axiosPutWithAuthHeader(
      `/operator/submit-order/${user.sub}/orderid=${selectedOrder.orderId}/`,
      {},
      getAccessTokenSilently,
      "Complete order",
    );
    if (responseData === 1) {
      // success
      navigate("/operator/submitted-orders/");
    } else {
      // fail
      setFailurePopupOpen(true);
    }
  };

  const FailureWarning = () => {
    return (
      <SnackbarFailure
        open={failurePopupOpen}
        setOpen={setFailurePopupOpen}
        message={`Failed to submit order. The order may have already been submitted, or the applied companies are not enough.`}
      />
    );
  };

  if (selectedOrder.appliedCount >= selectedOrder.totalCount) {
    return (
      <CompleteButtonValid onClick={onClickCompleteOrder}>
        <FailureWarning />
        Submit
      </CompleteButtonValid>
    );
  } else {
    return <CompleteButtonInvalid> Submit </CompleteButtonInvalid>;
  }
};

const OngoingOrderDetail = () => {
  const { id } = useParams();
  const { fetchOrderDetail, selectedOrder } = useOrderContext();
  const { selectOngoingOrders } = useSidebarContext();
  const { user } = useAuth0();
  const { triggerTemplatePopupViewOnly } = useTemplateContext();
  const { updateSearchKeyword, fetchCompaniesInfo, setPage, setSortBy } =
    useCompanyContext();

  // Use useEffect to set selectedOrder here.
  useEffect(() => {
    console.log("OrderDetail useEffect, orderId", id);
    fetchOrderDetail(id, user.sub); // eslint-disable-next-line
    selectOngoingOrders(); // eslint-disable-next-line
  }, []);

  const onClickViewTemplate = () => {
    triggerTemplatePopupViewOnly(selectedOrder.templateSnapshot);
  };

  const handleOnClickSearch = () => {
    fetchCompaniesInfo(id, 1, selectedOrder.customerId, null);
    setPage(1);
  };

  return (
    <Wrapper>
      <OrderDetailHeader
        selectedOrder={selectedOrder}
        backLink={"/operator/ongoing-orders/"}
        completeButton={<CompleteOrderButton />}
      />
      <OrderDetailHeaderTable
        orderData={selectedOrder}
        onClickViewTemplate={onClickViewTemplate}
      />
      <CompaniesSearchBar
        orderId={id}
        updateKeyword={updateSearchKeyword}
        handleOnClickSearch={handleOnClickSearch}
        setSortBy={setSortBy}
        userId={selectedOrder.customerId}
      />
      {selectedOrder.customerId === "" ? (
        ""
      ) : (
        <InProgressCompaniesTable
          orderId={id}
          customerId={selectedOrder.customerId}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-left: 30px;
`;

const CompleteButtonValid = styled.button`
  height: 60px;
  width: 200px;
  margin-right: -600px;
  margin-top: 100px;
  margin-bottom: 40px;

  font-size: 20px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;

const CompleteButtonInvalid = styled.button`
  height: 60px;
  width: 200px;
  margin-right: -600px;
  margin-top: 100px;
  margin-bottom: 40px;

  font-size: 20px;
  background-color: var(--clr-grey-light);
  border-radius: 40px;
  font-weight: 200;
`;

export default OngoingOrderDetail;
