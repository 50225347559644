import { useAuth0 } from "@auth0/auth0-react";

import { logo } from "../../../assets";
import SidebarProfile from "./SidebarProfile";
import SidebarMyOrders from "./SidebarMyOrders";
import SidebarCompanies from "./SidebarCompanies";
import SidebarOngoingOrders from "./SidebarOngoingOrders";
import SidebarPastOrders from "./SidebarPastOrders";
import styled from "styled-components";

const Sidebar = () => {
  return (
    <Wrapper>
      <Content>
        <img src={logo} alt="Applier" width="150px" />
        <SidebarContent>
          <SidebarProfile />
          <SidebarMyOrders />
          <MyOrdersContainer>
            <SidebarOngoingOrders />
            <SidebarPastOrders />
          </MyOrdersContainer>
          <SidebarCompanies />
        </SidebarContent>
        <LogoutButton />
      </Content>
    </Wrapper>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <LogoutButtonWrapper
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Log out
    </LogoutButtonWrapper>
  );
};

const LogoutButtonWrapper = styled.button`
  margin-top: 200px;
  text-align: left;
  width: 100%;
  display: block;
  font-size: 18px;
`;

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  width: 300px;
  min-width: 300px;
  .content {
    margin_left: 20rem;
  }
`;

const Content = styled.div`
  margin-top: 1.5rem;
  margin-left: 15%;
`;

const SidebarContent = styled.div`
  margin-top: 0.5rem;
`;

const MyOrdersContainer = styled.div`
  clear: both;
  margin-left: 1.5rem;
`;

export default Sidebar;
