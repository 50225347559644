import { FileUploadZone } from "../../../common/upload/file_drop";
import { useDisputeContext } from "../../context/dispute_context";

const ImageUpload = () => {
  const { image, imageMetadata, imageErrorText, onUpload } =
    useDisputeContext();

  return (
    <FileUploadZone
      file={image}
      metadata={imageMetadata}
      errorText={imageErrorText}
      onUpload={onUpload}
      maxSizeInMb={7}
      supportedFileFormats={["png", "jpg", "jpeg"]}
    />
  );
};

export default ImageUpload;
