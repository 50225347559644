import {
  TEMPLATE_POPPUP_CLOSE,
  TEMPLATE_POPPUP_EDIT,
  TEMPLATE_POPPUP_UPLOAD_RESUME,
  TEMPLATE_POPPUP_INCREMENT_STEP,
  TEMPLATE_POPPUP_DECREMENT_STEP,
  TEMPLATE_POPPUP_UPDATE_QUESTION_ANSWER,
  TEMPLATE_POPPUP_VIEW,
  TEMPLATE_POPPUP_CREATE,
  TEMPLATE_POPPUP_SAVE_BEGIN,
  TEMPLATE_POPPUP_SAVE_SUCCESS,
  TEMPLATE_POPPUP_SAVE_ERROR,
} from "../actions";
import { FileMetadata } from "../../common/api_schema/template_schema";

import { initialState } from "../context/template_context";

const template_reducer = (state, action) => {
  if (action.type === TEMPLATE_POPPUP_SAVE_BEGIN) {
    console.log("Open template pop-up save begin");
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === TEMPLATE_POPPUP_SAVE_SUCCESS) {
    console.log("Open template pop-up save success");
    return initialState;
  }

  if (action.type === TEMPLATE_POPPUP_SAVE_ERROR) {
    console.log("Open template pop-up save error");
    return initialState;
  }

  if (action.type === TEMPLATE_POPPUP_CREATE) {
    console.log("Open template pop-up for creation");
    return {
      ...initialState,
      isEditingTemplate: true,
    };
  }

  if (action.type === TEMPLATE_POPPUP_EDIT) {
    console.log("Edit template pop-up, template data: ", action.templateData);
    if (typeof action.templateData === "undefined") {
      return {
        ...initialState,
        isEditingTemplate: true,
      };
    } else {
      const resumeMetadata = FileMetadata.getDeserializedData(
        action.templateData.resume,
      );
      return {
        ...initialState,
        templateId: action.templateData.templateId,
        resumeMetadata: resumeMetadata,
        questions: JSON.parse(action.templateData.content),
        isEditingTemplate: true,
      };
    }
  }

  if (action.type === TEMPLATE_POPPUP_VIEW) {
    console.log("View template pop-up: ", action.templateSnapshot);
    const snapshot = JSON.parse(action.templateSnapshot);
    return {
      ...state,
      isViewingTemplate: true,
      questions: JSON.parse(snapshot.questionsSerialized),
      resumeMetadata: JSON.parse(snapshot.resumeMetadataSerialized),
    };
  }

  if (action.type === TEMPLATE_POPPUP_CLOSE) {
    console.log("Close template pop-up");
    return initialState;
  }

  if (action.type === TEMPLATE_POPPUP_UPLOAD_RESUME) {
    console.log("Template upload resume", action);
    const resumeMetadata = action.resume
      ? new FileMetadata(action.resume.name, "")
      : null;
    return {
      ...state,
      resume: action.resume,
      resumeMetadata: resumeMetadata,
      resumeErrorText: action.resumeErrorText,
    };
  }

  if (action.type === TEMPLATE_POPPUP_INCREMENT_STEP) {
    console.log("Increment step");
    return {
      ...state,
      step: state.step + 1,
    };
  }

  if (action.type === TEMPLATE_POPPUP_DECREMENT_STEP) {
    console.log("Decrement step");
    return {
      ...state,
      step: state.step - 1,
    };
  }

  if (action.type === TEMPLATE_POPPUP_UPDATE_QUESTION_ANSWER) {
    console.log("Update answer");
    return {
      ...state,
      questions: {
        ...state.questions,
        [action.questionKey]: action.answerValue,
      },
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default template_reducer;
