import { useTemplateContext } from "../../context/template_context";
import { FileUploadZone } from "../../../common/upload/file_drop";

const TemplateResumeUpload = () => {
  const { resume, resumeMetadata, resumeErrorText, onUploadResume } =
    useTemplateContext();

  return (
    <FileUploadZone
      file={resume}
      metadata={resumeMetadata}
      errorText={resumeErrorText}
      onUpload={onUploadResume}
      maxSizeInMb={5}
      supportedFileFormats={["pdf"]}
    />
  );
};

export default TemplateResumeUpload;
