import { TextField } from "@mui/material";
import styled from "styled-components";

const TemplateTextResponseQuestion = ({
  questionKey,
  questionBody,
  maxResponseLength,
  readOnly,
  questions,
  onUpdateQuestion,
}) => {
  const handleChange = (event) => {
    onUpdateQuestion(questionKey, event.target.value);
  };

  return (
    <Wrapper>
      <QuestionBody>{questionBody}</QuestionBody>
      <TextField
        disabled={readOnly}
        multiline
        variant="standard"
        value={questions[questionKey]}
        onChange={handleChange}
        inputProps={{
          style: { fontSize: 16 },
          maxLength: maxResponseLength,
        }}
        sx={{
          "& label.Mui-focused": {
            color: "var(--clr-green-light)",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "var(--clr-green-light)",
          },
          "& .MuiInput-underline:hover": {
            borderBottomColor: "var(--clr-green-light)",
          },
          fontSize: 11,
          width: "80%",
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const QuestionBody = styled.div`
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export default TemplateTextResponseQuestion;
