import Link from "@mui/material/Link";
import styled from "styled-components";

const OrderDetailHeader = ({ selectedOrder, backLink, completeButton }) => {
  return (
    <Header>
      <TitleWrapper>
        <PositionText>{selectedOrder.positionName + " order"}</PositionText>
        <OrderIdText>Order id: {selectedOrder.orderId}</OrderIdText>
      </TitleWrapper>
      <ButtonsWrapper>
        {completeButton ? completeButton : ""}
        <Link
          href={backLink}
          sx={{
            fontSize: "23px",
            fontWeight: 200,
            marginTop: "40px",
            color: "var(--clr-green-light)",
            backgroundColor: "var(--clr-grey-white)",
            float: "right",
          }}
          style={{ textDecoration: "none" }}
        >
          Back to dashboard
        </Link>
      </ButtonsWrapper>
    </Header>
  );
};

const ButtonsWrapper = styled.div`
  float: right;
`;

const Header = styled.div`
  height: 100px;
  margin-bottom: 30px;
`;

const PositionText = styled.h1`
  margin-left: 5px;
  margin-top: 20px;
`;

const OrderIdText = styled.div`
  margin-left: 5px;
  margin-top: -20px;
  font-size: 16px;
`;

const TitleWrapper = styled.div`
  float: left;
`;

export default OrderDetailHeader;
