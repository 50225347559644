import React from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import { Sidebar, MainContent } from "../components";
import TemplatePopup from "../components/Popup/TemplatePopup";
import NewOrderPopup from "../components/Popup/NewOrderPopup";
import TemplateViewOnlyPopup from "../components/Popup/TemplateViewOnlyPopup";
import LoadingPage from "./LoadingPage";

const ClientHomePage = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    navigate("/");
    return null;
  }

  return (
    <Wrapper>
      <Sidebar />
      <MainContent />
      <NewOrderPopup />
      <TemplatePopup />
      <TemplateViewOnlyPopup />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  margin: 0px;
  padding: 0px;
  height: 100%;
  display: flex;
`;

export default ClientHomePage;
