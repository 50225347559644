import {
  SIDEBAR_SELECT_PROFILE,
  SIDEBAR_SELECT_NEW_ORDERS,
  SIDEBAR_SELECT_ONGOING_ORDERS,
  SIDEBAR_SELECT_IN_DISPUTE_ORDERS,
  SIDEBAR_SELECT_SUBMITTED_ORDERS,
  SIDEBAR_SELECT_PAST_ORDERS,
} from "../actions";

const unselectedState = {
  isProfileSelected: false,
  isNewOrderSelected: false,
  isOngoingOrdersSelected: false,
  isInDisputeOrdersSelected: false,
  isSubmittedOrdersSelected: false,
  isCompletedOrdersSelected: false,
};

const sidebar_reducer = (state, action) => {
  if (action.type === SIDEBAR_SELECT_PROFILE) {
    console.log("Sidebar select profile");
    return {
      ...unselectedState,
      isProfileSelected: true,
    };
  }

  if (action.type === SIDEBAR_SELECT_NEW_ORDERS) {
    console.log("Sidebar select new orders");
    return {
      ...unselectedState,
      isNewOrderSelected: true,
    };
  }

  if (action.type === SIDEBAR_SELECT_ONGOING_ORDERS) {
    console.log("Sidebar select ongoing orders");
    return {
      ...unselectedState,
      isOngoingOrdersSelected: true,
    };
  }

  if (action.type === SIDEBAR_SELECT_IN_DISPUTE_ORDERS) {
    console.log("Sidebar select in-dispute orders");
    return {
      ...unselectedState,
      isInDisputeOrdersSelected: true,
    };
  }

  if (action.type === SIDEBAR_SELECT_SUBMITTED_ORDERS) {
    console.log("Sidebar select completed orders");
    return {
      ...unselectedState,
      isSubmittedOrdersSelected: true,
    };
  }

  if (action.type === SIDEBAR_SELECT_PAST_ORDERS) {
    console.log("Sidebar select past orders");
    return {
      ...unselectedState,
      isCompletedOrdersSelected: true,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default sidebar_reducer;
