import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { profileIcon } from "../../../assets";
import { useSidebarContext } from "../../context/sidebar_context";

const SidebarProfile = () => {
  const { isProfileSelected } = useSidebarContext();

  return (
    <Wrapper>
      <NavLink
        to="/client/profile"
        className={`${
          isProfileSelected ? "sidebar-btn-selected" : "sidebar-btn"
        }`}
      >
        Profile
      </NavLink>
      <img src={profileIcon} alt="Profile" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  img {
    float: left;
    margin-left: 30px;
    margin-top: 5px;
    width: 40px;
  }

  .sidebar-btn {
    font-size: 18px;
    margin-top: 14px;
    margin-left: 3px;
    width: 20px;
    float: left;
    text-decoration: none;
  }

  .sidebar-btn-selected {
    font-size: 18px;
    margin-top: 14px;
    margin-left: 3px;
    width: 20px;
    float: left;
    text-decoration: none;
  }
`;

export default SidebarProfile;
