import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import styled from "styled-components";

import { ORDER_IN_DISPUTE, intToStatus } from "../api_schema/order_schema";
import { parseISOLocal } from "../utils";
import { OrderProgress } from "./OrderProgress";
import { TableHeaderCellGrey, TableRowCellGrey } from "../table_styles";

export const OrderDetailHeaderTable = ({ orderData, onClickViewTemplate }) => {
  const isDisputedOrder = orderData.status === ORDER_IN_DISPUTE;
  return (
    <TableContainer sx={{ marginBottom: "80px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="table-header">
            <TableHeaderCellGrey>Position </TableHeaderCellGrey>
            <TableHeaderCellGrey>Status</TableHeaderCellGrey>
            <TableHeaderCellGrey>Application template</TableHeaderCellGrey>
            <TableHeaderCellGrey>Date of creation</TableHeaderCellGrey>
            <TableHeaderCellGrey>
              {isDisputedOrder ? "Remaining issues" : "Progress"}
            </TableHeaderCellGrey>
            {isDisputedOrder ? (
              ""
            ) : (
              <TableHeaderCellGrey>Companies Applied</TableHeaderCellGrey>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={orderData.orderId} sx={{ border: 0 }}>
            <TableRowCellGrey>{orderData.positionName}</TableRowCellGrey>
            <TableRowCellGrey>{intToStatus(orderData.status)}</TableRowCellGrey>
            <TableRowCellGrey>
              <ViewTemplateButton onClick={onClickViewTemplate}>
                View template
              </ViewTemplateButton>
            </TableRowCellGrey>
            <TableRowCellGrey>
              {parseISOLocal(orderData.createTime)}
            </TableRowCellGrey>
            <TableRowCellGrey>
              {isDisputedOrder ? (
                orderData.disputedCount - orderData.resolvedCount
              ) : (
                <OrderProgress order={orderData}></OrderProgress>
              )}
            </TableRowCellGrey>
            {isDisputedOrder ? (
              ""
            ) : (
              <TableRowCellGrey>{orderData.appliedCount}</TableRowCellGrey>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ViewTemplateButton = styled.button`
  color: var(--clr-green-light);
  background-color: var(--clr-grey-white);
  font-size: 15px;
`;
