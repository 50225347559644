import styled from "styled-components";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useTemplateContext } from "../../context/template_context";
import {
  requiredQuestions,
  QUESTION_MULTI_CHOICE,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TEXT_RESPONSE,
} from "../../../common/questions/questions";
import TemplateResumeUpload from "./TemplateResumeUpload";
import TemplateRequiredQuestions from "../../../common/questions/TemplateRequiredQuestions";
import TemplateOptionalQuestions from "../../../common/questions/TemplateOptionalQuestions";
import { closeIcon } from "../../../assets";

const NextButtonRequiredQuestions = () => {
  const { questions, onClickNext } = useTemplateContext();
  for (const q of requiredQuestions) {
    const qKey = q.questionKey;
    if (
      (q.type === QUESTION_MULTI_CHOICE && questions[qKey].length === 0) ||
      (q.type === QUESTION_SINGLE_CHOICE && questions[qKey] === -1) ||
      (q.type === QUESTION_TEXT_RESPONSE && questions[qKey] === "")
    ) {
      return <SaveButtonInvalid> Next </SaveButtonInvalid>;
    }
  }
  return <SaveButtonValid onClick={onClickNext}> Next </SaveButtonValid>;
};

const PopupContent = () => {
  const {
    resumeMetadata,
    step,
    onClickNext,
    onClickBack,
    onSaveTemplate,
    questions,
    onUpdateTemplateQuestionAnswer,
  } = useTemplateContext();

  const { user, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const onSaveTempalteWithUserId = () => {
    onSaveTemplate(user.sub);
  };

  if (step === 1) {
    return (
      <div>
        <TemplateResumeUpload />
        {resumeMetadata ? (
          <SaveButtonValid onClick={onClickNext}> Next </SaveButtonValid>
        ) : (
          <SaveButtonInvalid> Next </SaveButtonInvalid>
        )}
      </div>
    );
  } else if (step === 2) {
    return (
      <div>
        <TemplateRequiredQuestions
          readOnly={false}
          questions={questions}
          onUpdateQuestion={onUpdateTemplateQuestionAnswer}
        />
        <BackButton onClick={onClickBack}>Back</BackButton>
        <NextButtonRequiredQuestions />
      </div>
    );
  } else if (step === 3) {
    return (
      // Try useEffect scroll to 0 to div here
      <div>
        <TemplateOptionalQuestions
          readOnly={false}
          questions={questions}
          onUpdateQuestion={onUpdateTemplateQuestionAnswer}
        />
        <BackButton onClick={onClickBack}>Back</BackButton>
        <SaveButtonValid onClick={onSaveTempalteWithUserId}>
          Save
        </SaveButtonValid>
      </div>
    );
  }
};

const getPopupContentTitle = (step) => {
  if (step === 1) {
    return "Upload resume";
  } else if (step === 2) {
    return "Job application questions";
  } else if (step === 3) {
    return "Optional instructions";
  } else {
    return "";
  }
};

const TemplatePopup = () => {
  const { isEditingTemplate, closeTemplatePopup, step, name } =
    useTemplateContext();
  console.log("Template pop step ", step);

  useEffect(() => {
    if (document.getElementById("popup_inner")) {
      document.getElementById("popup_inner").scrollTo(0, 0);
    }
  }, [step]);

  return isEditingTemplate ? (
    <Wrapper>
      <PopupBackground />
      {/* Pass step as key to force update scroll to the top */}
      <PopupInner id="popup_inner" key={step}>
        <PopupTitle>{name === "" ? "Create a new template" : name}</PopupTitle>
        <SubsectionHeader>{getPopupContentTitle(step)}</SubsectionHeader>
        <CloseButton>
          <img
            src={closeIcon}
            alt="close"
            onClick={closeTemplatePopup}
            className="popup-close-image"
          />
        </CloseButton>
        <PopupContent />
      </PopupInner>
      ;
    </Wrapper>
  ) : (
    ""
  );
};

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  overflow-y: auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
`;

const PopupInner = styled.div`
  position: absolute;
  top: 50px;
  left: 120px;
  padding: 32px;
  width: 80%;
  max-height: 80%;
  min-width: 700px;
  background-color: #fff;
  overflow-y: auto;
`;

const SubsectionHeader = styled.div`
  font-size: 25px;
  margin-left: 35px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  height: 40px;
`;

const SaveButtonValid = styled.button`
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: auto;
  display: block;
  height: 40px;
  width: 130px;
  background-color: var(--clr-green-light);
  border-radius: 20px;
  font-size: 15px;
`;

const SaveButtonInvalid = styled.button`
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: auto;
  display: block;
  height: 40px;
  width: 130px;
  background-color: var(--clr-grey-light);
  border-radius: 20px;
  font-size: 15px;
`;

const BackButton = styled.button`
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: auto;
  display: block;
  height: 40px;
  width: 130px;
  background-color: var(--clr-grey-light);
  border-radius: 20px;
  font-size: 15px;
`;

const PopupTitle = styled.h1`
  margin-left: 30px;
  margin-top: 0;
`;

export default TemplatePopup;
