import styled from "styled-components";
import { useEffect } from "react";

import { useSidebarContext } from "../../context/sidebar_context";
import { ordersIcon } from "../../../assets";
import { InDisputeOrderTable } from "./table/InDisputeOrderTable";

const InDisputeOrders = () => {
  const { selectInDisputeOrders } = useSidebarContext();
  useEffect(() => {
    selectInDisputeOrders(); // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <Header>
        <HeaderOrdersImage src={ordersIcon} alt="Orders" />
        <h1>In-dispute orders</h1>
      </Header>
      <InDisputeOrderTable />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 1000px;
  margin-left: 30px;
  overflow-x: scroll;
`;

const Header = styled.div`
  width: 100%;
  min-width: 1000px;
  height: 100px;
  margin-bottom: 50px;
  h1 {
    display: block;
    float: left;
    margin-left: 5px;
    margin-top: 20px;
  }
`;

const HeaderOrdersImage = styled.img`
  display: block;
  float: left;
  margin-top: 13px;
  margin-left: 0;
  width: 70px;
`;

export default InDisputeOrders;
