import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import { closeIcon, addOrdersIcon } from "../../../assets";
import { useNewOrderContext } from "../../context/new_order_context";
import { useTemplateContext } from "../../context/template_context";
import NewOrderQuestions from "./NewOrderQuestions";
import NewOrderPlanQuestion from "./NewOrderPlanQuestion";
import NewOrderPaymentSummary from "./NewOrderPaymentSummary";
import { useEffect } from "react";
import { useUserContext } from "../../context/user_context";

const SaveButton = () => {
  const { step, templateChoice, planChoice, incrementStep, onClickSave } =
    useNewOrderContext();
  const { user } = useAuth0();

  const onClickSaveNewOrder = () => {
    onClickSave(user.sub);
  };

  if (step === 1) {
    if (templateChoice === -1 || planChoice === -1) {
      return <SaveButtonInvalid> Submit </SaveButtonInvalid>;
    }

    return <SaveButtonValid onClick={incrementStep}> Submit </SaveButtonValid>;
  } else {
    return (
      <SaveButtonValid onClick={onClickSaveNewOrder}> Confirm </SaveButtonValid>
    );
  }
};

const PopupContent = () => {
  const { triggerTemplatePopup } = useTemplateContext();
  const { step, decrementStep } = useNewOrderContext();

  if (step === 1) {
    return (
      <div>
        <NewOrderQuestions />
        <CreateButton
          onClick={() => {
            triggerTemplatePopup();
          }}
        >
          <CreateTemplateImage src={addOrdersIcon} />
          Add template
        </CreateButton>
        <NewOrderPlanQuestion />
        <ContentMargin />
        <SaveButton />
      </div>
    );
  } else if (step === 2) {
    return (
      <div>
        <NewOrderPaymentSummary />
        <BackButton onClick={decrementStep}> Back </BackButton>
        <SaveButton />
      </div>
    );
  } else {
    return "";
  }
};

const NewOrderPopup = () => {
  const { user } = useAuth0();
  const { fetchUserProfileInfo } = useUserContext();
  const { isEditingNewOrder, closeNewOrderPopup, step } = useNewOrderContext();

  useEffect(() => {
    fetchUserProfileInfo(user.sub); // eslint-disable-next-line
  }, []);

  return isEditingNewOrder ? (
    <Wrapper>
      <PopupBackground />
      <PopupInner>
        <PopupTitle>{step === 1 ? "New order" : "Order summary"}</PopupTitle>
        <CloseButton>
          <img
            src={closeIcon}
            alt="close"
            onClick={closeNewOrderPopup}
            className="popup-close-image"
          />
        </CloseButton>
        <PopupContent />
      </PopupInner>
    </Wrapper>
  ) : (
    ""
  );
};

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  overflow-y: auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
`;

const PopupInner = styled.div`
  position: absolute;
  top: 50px;
  left: 15%;
  padding: 32px;
  width: 70%;
  max-height: 80%;
  min-width: 500px;
  background-color: #fff;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  height: 40px;
`;

const SaveButtonValid = styled.button`
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: auto;
  display: block;
  height: 40px;
  width: 130px;
  background-color: var(--clr-green-light);
  border-radius: 20px;
  font-size: 15px;
`;

const SaveButtonInvalid = styled.button`
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: auto;
  display: block;
  height: 40px;
  width: 130px;
  background-color: var(--clr-grey-light);
  border-radius: 20px;
  font-size: 15px;
`;

const ContentMargin = styled.div`
  height: 50px;
`;

const PopupTitle = styled.h1`
  margin-left: 30px;
  margin-top: 0;
`;

const CreateButton = styled.button`
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 40px;
  height: 50px;
  width: 180px;

  display: flex;
  align-items: center;

  font-size: 16px;
  border-color: black;
  border-style: solid;
  border-radius: 40px;
`;

const CreateTemplateImage = styled.img`
  order: 0;
  margin-left: 5%;
  margin-right: 10px;
  height: 25px;
  width: 25px;
`;

const BackButton = styled.button`
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: auto;
  display: block;
  height: 40px;
  width: 130px;
  background-color: var(--clr-grey-light);
  border-radius: 20px;
  font-size: 15px;
`;

export default NewOrderPopup;
