import styled from "styled-components";
import { noOrdersIcon } from "../../../assets";

import { OrderCreationButton } from "./OrderCreationButton";

export const OrdersEmptyPage = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>You don't have any orders yet.</Title>
        <Subtitle>
          Take a step towards your dream job right now. Create an order in three
          clicks
        </Subtitle>
        <OrderCreationButton />
      </TextWrapper>
      <NoOrdersImage src={noOrdersIcon} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const TextWrapper = styled.div``;

const Title = styled.div`
  margin-top: 30px;
  font-size: 30px;
  font-weight: 200;
`;

const Subtitle = styled.div`
  margin-top: 30px;
  font-size: 20px;
  font-weight: 200;
  width: 650px;
`;

const NoOrdersImage = styled.img`
  order: 0;
  margin-top: 30px;
  margin-left: 50px;
  height: 200px;
  width: 360px;
`;
