import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled as MuiStyled } from "@mui/material/styles";
import { useEffect } from "react";
import Link from "@mui/material/Link";
import styled from "styled-components";

import {
  APPLICATION_DISPUTED,
  APPLICATION_PAGE_SIZE,
} from "../../../../common/api_schema/application_schema";
import {
  getNumberOfPages,
  parseISOLocal,
  truncateText,
} from "../../../../common/utils";
import { TableHeaderCell, TableRowCell } from "../../../../common/table_styles";
import { useCompanyContext } from "../../../context/company_context";
import { useDisputeContext } from "../../../context/dispute_context";

const ResolveApplicationButton = ({ applicationId, companyName }) => {
  const { triggerDisputePopup } = useDisputeContext();
  const onClickResolveDispute = () => {
    triggerDisputePopup(applicationId, companyName);
  };
  return (
    <TableRowButton onClick={onClickResolveDispute}>Resolve</TableRowButton>
  );
};

export const InDisputeCompaniesTable = ({ orderId, customerId }) => {
  const { companies, fetchCompaniesInfo, totalCount, page, setPage } =
    useCompanyContext();

  useEffect(() => {
    fetchCompaniesInfo(orderId, 1, customerId, null, APPLICATION_DISPUTED); // eslint-disable-next-line
  }, []);

  const handlePagination = (event, value) => {
    fetchCompaniesInfo(orderId, value, customerId, null, APPLICATION_DISPUTED); // eslint-disable-next-line
    setPage(value);
  };

  console.log("CompaniesTable, companies", companies);

  return (
    <TableContainer sx={{ height: "1300px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="table-header">
            <TableHeaderCell> Company </TableHeaderCell>
            <TableHeaderCell> Position </TableHeaderCell>
            <TableHeaderCell>Link</TableHeaderCell>
            <TableHeaderCell>Date of application</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map((row) => (
            <TableRow key={row.applicationId} sx={{ border: 0 }}>
              <TableRowCell>{row.companyName}</TableRowCell>
              <TableRowCell>{row.positionName}</TableRowCell>
              <TableRowCell>
                <Link
                  sx={{ fontSize: 15, fontWeight: 200, color: "black" }}
                  href={row.positionLink}
                >
                  {truncateText(row.positionLink)}
                </Link>
              </TableRowCell>
              <TableRowCell>{parseISOLocal(row.createTime)}</TableRowCell>
              <TableRowCell>
                <ResolveApplicationButton
                  applicationId={row.applicationId}
                  companyName={row.companyName}
                />
              </TableRowCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StyledPagination
        count={getNumberOfPages(totalCount, APPLICATION_PAGE_SIZE)}
        size="large"
        page={page}
        onChange={handlePagination}
        sx={{ marginTop: "20px", marginLeft: "30%", marginRight: "auto" }}
      />
    </TableContainer>
  );
};

const StyledPagination = MuiStyled(Pagination)({
  "& .MuiPaginationItem-page": {
    fontFamily: "Mulish",
  },
  "& .Mui-selected": {
    color: "var(--clr-green-light)",
    border: 1,
    borderColor: "var(--clr-green-light)",
    borderStyle: "solid",
    backgroundColor: "var(--clr-grey-white)",
  },
});

const TableRowButton = styled.button`
  height: 40px;
  width: 100px;

  align-items: center;

  font-size: 17px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;
