import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import { OrderTable } from "./OrderTable";
import { OrdersEmptyPage } from "./OrdersEmptyPage";
import { useOrderContext } from "../../context/order_context";
import {
  ORDER_SUBMITTED,
  ORDER_PAST,
  ORDER_IN_DISPUTE,
} from "../../../common/api_schema/order_schema";
import { useAuth0 } from "@auth0/auth0-react";

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "green",
  },
});

export const OrdersPastTabs = () => {
  const [value, setValue] = useState("completed");
  const { completedCount, pastCount, inDisputeCount, fetchOrdersCount } =
    useOrderContext();
  const { user, isLoading } = useAuth0();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log("OrdersInProgressTabs useEffect, fetch order counts ");
    fetchOrdersCount(user.sub); // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const completedTabText = "Completed (" + completedCount.toString() + ")";
  const pastTabText = "Past (" + pastCount.toString() + ")";
  const inDisputeTabText =
    "Resolving Issues (" + inDisputeCount.toString() + ")";

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            TabIndicatorProps={{
              style: { background: "var(--clr-green-light)" },
            }}
          >
            <StyledTab label={completedTabText} value="completed" />
            <StyledTab label={inDisputeTabText} value={"resolving_dispute"} />
            <StyledTab label={pastTabText} value="past" />
          </TabList>
        </Box>
        <TabPanel value="completed">
          {completedCount > 0 ? (
            <OrderTable status={ORDER_SUBMITTED} count={completedCount} />
          ) : (
            <OrdersEmptyPage />
          )}
        </TabPanel>
        <TabPanel value="resolving_dispute">
          {inDisputeCount > 0 ? (
            <OrderTable status={ORDER_IN_DISPUTE} count={inDisputeCount} />
          ) : (
            <OrdersEmptyPage />
          )}
        </TabPanel>
        <TabPanel value="past">
          {pastCount > 0 ? (
            <OrderTable status={ORDER_PAST} count={pastCount} />
          ) : (
            <OrdersEmptyPage />
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default OrdersPastTabs;
