export class TemplateData {
  constructor(resume, content, templateId) {
    this.resume = resume;
    this.content = content;
    this.templateId = templateId;
  }

  static constructFromTemplateState(state, resumeFileName) {
    return new TemplateData(
      resumeFileName,
      JSON.stringify(state.questions),
      state.templateId,
    );
  }
}

export class TemplateDeletionRequest {
  constructor(templateId) {
    this.templateId = templateId;
  }
}

export class FileMetadata {
  constructor(fileName, s3FileName) {
    this.fileName = fileName;
    this.s3FileName = s3FileName;
  }

  static getSerializedData(fileName, s3FileName) {
    return JSON.stringify({ fileName: fileName, s3FileName: s3FileName });
  }

  static getDeserializedData(serializedResumeData) {
    const data = JSON.parse(serializedResumeData);
    return new FileMetadata(data.fileName, data.s3FileName);
  }
}
