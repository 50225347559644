import React, { useReducer, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import reducer from "../reducers/new_order_reducer";
import { planChoices } from "../../common/questions/questions";
import {
  OrderCreationRequest,
  OrderTemplateSnapshot,
} from "../../common/api_schema/order_schema";
import {
  NEW_ORDER_POPPUP_CLOSE,
  NEW_ORDER_POPPUP_CHOOSE_TEMPLATE,
  NEW_ORDER_POPPUP_CHOOSE_PLAN,
  NEW_ORDER_POPPUP_OPEN,
  NEW_ORDER_INCREMENT_STEP,
  NEW_ORDER_DECREMENT_STEP,
} from "../actions";
import { axiosPutWithAuthHeader } from "../../common/axios_utils";

export const initialState = {
  isEditingNewOrder: false,
  templateChoice: "",
  planChoice: -1,
  step: 1,
};

const NewOrderContext = React.createContext();

export const NewOrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getAccessTokenSilently } = useAuth0();

  const triggerNewOrderPopup = () => {
    dispatch({ type: NEW_ORDER_POPPUP_OPEN });
  };

  const closeNewOrderPopup = () => {
    dispatch({ type: NEW_ORDER_POPPUP_CLOSE });
  };

  const onChooseTemplate = (templateChoice) => {
    dispatch({
      type: NEW_ORDER_POPPUP_CHOOSE_TEMPLATE,
      templateChoice: templateChoice,
    });
  };

  const onChoosePlan = (planChoice) => {
    dispatch({ type: NEW_ORDER_POPPUP_CHOOSE_PLAN, planChoice: planChoice });
  };

  const incrementStep = () => {
    dispatch({ type: NEW_ORDER_INCREMENT_STEP });
  };

  const decrementStep = () => {
    dispatch({ type: NEW_ORDER_DECREMENT_STEP });
  };

  const onClickSave = async (userId) => {
    // We cannot pass template directly as an object in MUI, so serialize during component and deserialize here
    // Also, we cannot directly deserialize a state variable (throws a not valid json error), so put it here.
    const templateChoice = state.templateChoice;
    const tempalteChoiceDeserialized = JSON.parse(templateChoice);
    const orderTemplateSnapshot = new OrderTemplateSnapshot(
      tempalteChoiceDeserialized.content,
      tempalteChoiceDeserialized.resume,
    );
    const orderCreationRequest = new OrderCreationRequest(
      tempalteChoiceDeserialized.id,
      planChoices[state.planChoice].count,
      tempalteChoiceDeserialized.name,
      orderTemplateSnapshot.toString(),
    );
    console.log("orderCreationRequest", orderCreationRequest);
    await axiosPutWithAuthHeader(
      `/customer/order/${userId}/`,
      orderCreationRequest,
      getAccessTokenSilently,
      "Create new order",
    );
    dispatch({ type: NEW_ORDER_POPPUP_CLOSE });
  };

  return (
    <NewOrderContext.Provider
      value={{
        ...state,
        onChooseTemplate,
        onChoosePlan,
        triggerNewOrderPopup,
        closeNewOrderPopup,
        incrementStep,
        decrementStep,
        onClickSave,
      }}
    >
      {children}
    </NewOrderContext.Provider>
  );
};

export const useNewOrderContext = () => {
  return useContext(NewOrderContext);
};
