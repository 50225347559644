import { Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import { useState } from "react";
import styled from "styled-components";
import { addIcon } from "../../../assets";
import { useUserContext } from "../../context/user_context";
import { TemplateDeletionRequest } from "../../../common/api_schema/template_schema";
import { axiosPutWithAuthHeader } from "../../../common/axios_utils";
import {
  DialogButton,
  DialogText,
} from "../../../common/dialog/DialogComponents";

const ProfileItem = ({
  leftText,
  rightItemType,
  rightItemAction,
  rightItemText,
  templateId,
}) => {
  if (rightItemType === "display_text") {
    return (
      <Wrapper>
        <TextLeft> {leftText} </TextLeft>
        <TextRight> {rightItemText} </TextRight>
      </Wrapper>
    );
  } else if (rightItemType === "edit_button") {
    return (
      <Wrapper>
        <TextLeft> {leftText} </TextLeft>
        <EditButtonRight onClick={rightItemAction}>
          {rightItemText}
        </EditButtonRight>
      </Wrapper>
    );
  } else if (rightItemType === "add") {
    return (
      <WrapperBottomBlack>
        <AddButtonRight>
          <img src={addIcon} alt="add" onClick={rightItemAction} />
        </AddButtonRight>
        <TextBold> {leftText} </TextBold>
      </WrapperBottomBlack>
    );
  } else if (rightItemType === "edit_and_delete") {
    return (
      <Wrapper>
        <TextLeft> {leftText} </TextLeft>
        <DeleteDialog templateName={leftText} templateId={templateId} />
        <EditButtonRight onClick={rightItemAction}>
          {rightItemText}
        </EditButtonRight>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <TextLeft> {leftText} </TextLeft>
      </Wrapper>
    );
  }
};

const DeleteDialog = ({ templateName, templateId }) => {
  const [open, setOpen] = useState(false);
  const { fetchUserProfileInfo } = useUserContext();
  const { user, getAccessTokenSilently } = useAuth0(); // user should already be loaded here so we don't check isLoading

  const deleteTemplate = async () => {
    try {
      const request = new TemplateDeletionRequest(templateId);
      await axiosPutWithAuthHeader(
        `/customer/template/delete/${user.sub}/`,
        request,
        getAccessTokenSilently,
        "deleteTemplate",
      );
      fetchUserProfileInfo(user.sub);
    } catch (error) {
      console.log("Error when deleteTemplate: ", error);
    }
    setOpen(false);
  };

  return (
    <>
      <DeleteButtonRight onClick={() => setOpen(true)}>
        delete
      </DeleteButtonRight>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ fontFamily: "Mulish" }}>
          Delete template confirmation
        </DialogTitle>
        <DialogText>
          Are you sure you want to delete "{templateName}"? You will not be able
          undo this operation after confirming.
        </DialogText>
        <DialogActions>
          <DialogButton onClick={() => setOpen(false)}>CANCEL</DialogButton>
          <DialogButton onClick={deleteTemplate} autoFocus>
            CONFIRM
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Wrapper = styled.div`
  clear: both;
  border-bottom: solid;
  border-bottom-color: var(--clr-grey-light);
  margin-bottom: 30px;
`;

const WrapperBottomBlack = styled.div`
  clear: both;
  border-bottom: solid;
  margin-bottom: 30px;
`;

const TextLeft = styled.div`
  display: block;
  font-size: 15px;
  margin-bottom: 15px;
  margin-left: 3px;
`;

const TextBold = styled.div`
  display: block;
  font-size: 15px;
  font-weight: 900;
  margin-bottom: 15px;
  margin-left: 3px;
`;

const TextRight = styled.div`
  display: block;
  clear: both;
  font-size: 15px;
  color: var(--clr-grey-dark);
  margin-top: -32.5px;
  margin-right: 20px;
  float: right;
`;

const EditButtonRight = styled.button`
  display: block;
  clear: both;
  font-size: 15px;
  color: var(--clr-green-light);
  margin-top: -32.5px;
  margin-right: 20px;
  float: right;
`;

const DeleteButtonRight = styled.button`
  display: block;
  clear: both;
  font-size: 15px;
  color: red;
  margin-top: -32.5px;
  margin-right: 70px;
  float: right;
`;

const AddButtonRight = styled.button`
  display: block;
  clear: both;
  margin-top: -22.5px;
  margin-right: 20px;
  float: right;
  img {
    margin-top: 15px;
    width: 30px;
    margin-right: -3px;
  }
`;

// const DialogDeleteButton = styled.button`
//   color: var(--clr-green-light);
//   margin-bottom: 30px;
//   padding-right: 40px;
//   margin-right: 20px;
//   font-size: 18px;
// `;

export default ProfileItem;
