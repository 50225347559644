import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import { useCompanyContext } from "../../context/company_context";
import { CheckApplicationExistsRequest } from "../../../common/api_schema/application_schema";
import { axiosGetWithAuthHeader } from "../../../common/axios_utils";
import { useOrderContext } from "../../context/order_context";

const hasExactCompanyNameMatch = (companyName, applications) => {
  for (var i = 0; i < applications.length; i++) {
    if (applications[i].companyName === companyName) {
      return true;
    }
  }
  return false;
};

const CheckIfAppliedButton = () => {
  const {
    editingCompanyName,
    onPassCompanyCheck,
    onFailCompanyCheck,
    startConfirmation,
    editingApplicationId,
  } = useCompanyContext();
  const { user, getAccessTokenSilently } = useAuth0();
  const { selectedOrder } = useOrderContext();

  const onClickCheck = async () => {
    if (editingCompanyName.trim() === "") {
      return;
    }

    const request = new CheckApplicationExistsRequest(
      selectedOrder.customerId,
      editingApplicationId,
      editingCompanyName.trim(),
    );
    const responseData = await axiosGetWithAuthHeader(
      request,
      `/operator/check-application/${user.sub}/`,
      getAccessTokenSilently,
      "Check application exists",
    );
    const existingApplications = responseData.applications;
    if (existingApplications.length === 0) {
      // Check passed
      onPassCompanyCheck();
    } else if (
      hasExactCompanyNameMatch(editingCompanyName, existingApplications)
    ) {
      // Check failed
      onFailCompanyCheck();
    } else {
      // Need confirmation
      startConfirmation(
        existingApplications.map((application) => application.companyName),
      );
    }
  };

  return <WrapperGreen onClick={onClickCheck}>check if applied</WrapperGreen>;
};

const ConfirmButton = () => {
  const { onPassCompanyCheck } = useCompanyContext();
  return <WrapperGreen onClick={onPassCompanyCheck}>confirm</WrapperGreen>;
};

const CheckButton = () => {
  const { checkAppliedStatus, pendingConfirmationCompanyNames } =
    useCompanyContext();

  // 0: not checked => display "check if applied" button
  // 1: pass check: display green text "Passed check."
  // 2: failed check: display red text "This company has been applied by the customer before!"
  // 3: need confirmation, display (1) grey text showing similar company names the customer has applied to (2) confirm this company has not been applied
  if (checkAppliedStatus === 0) {
    return <CheckIfAppliedButton />;
  } else if (checkAppliedStatus === 1) {
    return <GreenText>Passed check!</GreenText>;
  } else if (checkAppliedStatus === 2) {
    return (
      <WarningText>
        This company has been applied by the customer before!
      </WarningText>
    );
  } else if (checkAppliedStatus === 3) {
    return (
      <div>
        <InfoText>
          This customer has applied to the following companies with similar
          names: {pendingConfirmationCompanyNames.join(", ")}
        </InfoText>
        <InfoText>
          Please only proceed to confirm is you are sure this is not a
          duplicate.
        </InfoText>
        <ConfirmButton />
      </div>
    );
  }
};

const WrapperGreen = styled.button`
  height: 40px;
  width: 150px;
  margin-right: 100px;

  margin-top: 10px;

  font-size: 17px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;

const GreenText = styled.div`
  width: 150px;
  margin-right: 50px;

  margin-top: 10px;

  font-size: 13px;
  color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;

const WarningText = styled.div`
  width: 150px;
  margin-right: 50px;

  margin-top: 10px;

  font-size: 13px;
  color: red;
  border-radius: 40px;
  font-weight: 200;
`;

const InfoText = styled.div`
  width: 200px;
  margin-right: 10px;

  margin-top: 10px;

  font-size: 13px;
  color: var(--clr-grey-dark);
  border-radius: 40px;
  font-weight: 200;
`;

export default CheckButton;
