import {
  COMPANY_GET_INFO_SUCCESS,
  COMPANY_SET_PAGE,
  COMPANY_SET_SEARCH_KEYWORD,
  COMPANY_SET_SORT_BY,
} from "../actions";

const company_reducer = (state, action) => {
  if (action.type === COMPANY_GET_INFO_SUCCESS) {
    console.log("Get companies info success");
    return {
      ...state,
      companies: action.payload.applications,
      totalCount: action.payload.totalCount,
    };
  }

  if (action.type === COMPANY_SET_SEARCH_KEYWORD) {
    console.log("Set companies search query");
    return {
      ...state,
      searchQuery: { ...state.searchQuery, keyword: action.keyWord },
    };
  }

  if (action.type === COMPANY_SET_PAGE) {
    console.log("Set companies search query page");
    return {
      ...state,
      searchQuery: { ...state.searchQuery, page: action.page },
    };
  }

  if (action.type === COMPANY_SET_SORT_BY) {
    console.log("Set companies sort by");
    return {
      ...state,
      searchQuery: { ...state.searchQuery, sortBy: action.sortBy },
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default company_reducer;
