import styled from "styled-components";
import { Outlet } from "react-router-dom";

const MainContent = () => {
  return (
    <Wrapper>
      <OutletContentWrapper>
        <Outlet />
      </OutletContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  background: #f5f5f5;
  float: left;
  width: 100%;
  min-width: 1000px;
  height: 100%;
  overflow-y: auto;
`;

const OutletContentWrapper = styled.div`
  margin-top: 0;
  margin-left: 1rem;
  margin-right: 3rem;
`;

export default MainContent;
