import { Routes, Route, Navigate } from "react-router-dom";
import { ClientHomePage } from "./client/pages";
import Profile from "./client/components/Profile/Profile";
import Orders from "./client/components/Orders/Orders";
import OrdersPast from "./client/components/Orders/OrdersPast";
import Companies from "./client/components/Company/Companies";

import { Auth0Provider } from "@auth0/auth0-react";
import { SidebarProvider } from "./client/context/sidebar_context";
import { TemplateProvider } from "./client/context/template_context";
import { UserProvider } from "./client/context/user_context";
import { NewOrderProvider } from "./client/context/new_order_context";
import { OrderProvider } from "./client/context/order_context";
import { CompanyProvider } from "./client/context/company_context";
import ClientLoginPage from "./client/pages/ClientLoginPage";
import OrderDetail from "./client/components/Orders/OrderDetailPage";

const ClientRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<ClientHomePage />}
        children={[
          <Route
            key="redirect"
            index={true}
            element={<Navigate to="ongoing-orders" replace />}
          />,
          <Route key="profile" path="profile" element={<Profile />} />,
          <Route
            key="ongoing-orders"
            path="ongoing-orders"
            element={<Orders />}
          />,
          <Route
            key="past-orders"
            path="past-orders"
            element={<OrdersPast />}
          />,
          <Route key="companies" path="companies" element={<Companies />} />,
          <Route
            key="order-detail"
            path="order/:id"
            element={<OrderDetail />}
          />,
        ]}
      />
      <Route key="client-login" path="login" element={<ClientLoginPage />} />
    </Routes>
  );
};

const ClientLayout = () => {
  return (
    <Auth0Provider
      domain="dev-huqm3825znexykgx.us.auth0.com"
      clientId="NePBPXPEF23xNL5pHSJKHEcaQfKsuVzM"
      authorizationParams={{
        redirect_uri: window.location.origin + "/client/ongoing-orders",
        audience: "https://dev-huqm3825znexykgx.us.auth0.com/api/v2/",
      }}
      // Needed for safari
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <SidebarProvider>
        <UserProvider>
          <TemplateProvider>
            <NewOrderProvider>
              <OrderProvider>
                <CompanyProvider>
                  <ClientRoutes />
                </CompanyProvider>
              </OrderProvider>
            </NewOrderProvider>
          </TemplateProvider>
        </UserProvider>
      </SidebarProvider>
    </Auth0Provider>
  );
};

export default ClientLayout;
