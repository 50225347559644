import { TEMPLATE_POPPUP_CLOSE, TEMPLATE_POPPUP_VIEW } from "../actions";
import { initialState } from "../context/template_context";

const template_reducer = (state, action) => {
  if (action.type === TEMPLATE_POPPUP_VIEW) {
    console.log("View template pop-up: ", action.templateSnapshot);
    const snapshot = JSON.parse(action.templateSnapshot);
    // console.log("View template pop-up parsed: ", action.templateSnapshot);
    return {
      ...state,
      isViewingTemplate: true,
      questions: JSON.parse(snapshot.questionsSerialized),
      resumeMetadata: JSON.parse(snapshot.resumeMetadataSerialized),
    };
  }

  if (action.type === TEMPLATE_POPPUP_CLOSE) {
    console.log("Close template pop-up");
    return initialState;
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default template_reducer;
