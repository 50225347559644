import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";

import { logo } from "../../../assets";
import SidebarProfile from "./SidebarProfile";
import SidebarMyOrders from "./SidebarMyOrders";
import styled from "styled-components";
import { useSidebarContext } from "../../context/sidebar_context";
import { useOrderContext } from "../../context/order_context";

const Sidebar = () => {
  const {
    isNewOrderSelected,
    isOngoingOrdersSelected,
    isInDisputeOrdersSelected,
    isSubmittedOrdersSelected,
    isCompletedOrdersSelected,
  } = useSidebarContext();
  const {
    unassignedCount,
    inProgressCount,
    completedCount,
    pastCount,
    inDisputeCount,
  } = useOrderContext();
  return (
    <Wrapper>
      <Content>
        <img src={logo} alt="Applier" width="150px" />
        <SidebarContent>
          <SidebarProfile />
          <SidebarMyOrders />
          <MyOrdersContainer>
            <SidebarOrders
              selected={isNewOrderSelected}
              link={"/operator/new-orders"}
              displayText={`New orders (${unassignedCount})`}
            />
            <SidebarOrders
              selected={isOngoingOrdersSelected}
              link={"/operator/ongoing-orders"}
              displayText={`In progress (${inProgressCount})`}
            />
            <SidebarOrders
              selected={isSubmittedOrdersSelected}
              link={"/operator/submitted-orders"}
              displayText={`Submitted (${completedCount})`}
            />
            <SidebarOrders
              selected={isInDisputeOrdersSelected}
              link={"/operator/in-dispute-orders"}
              displayText={`In dispute (${inDisputeCount})`}
            />
            <SidebarOrders
              selected={isCompletedOrdersSelected}
              link={"/operator/completed-orders"}
              displayText={`Past (${pastCount})`}
            />
          </MyOrdersContainer>
        </SidebarContent>
        <LogoutButton />
      </Content>
    </Wrapper>
  );
};

const SidebarOrders = ({ selected, link, displayText }) => {
  return (
    <OrderWrapper>
      <NavLink
        to={link}
        className={`${selected ? "sidebar-btn-selected" : "sidebar-btn"}`}
      >
        {displayText}
      </NavLink>
    </OrderWrapper>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <LogoutButtonWrapper
      onClick={() =>
        logout({
          logoutParams: {
            returnTo: window.location.origin + "/operator/login",
          },
        })
      }
    >
      Log out
    </LogoutButtonWrapper>
  );
};

const OrderWrapper = styled.div`
  margin-top: 10px;
  .sidebar-btn {
    clear: both;
    background-color: #ffffff;
    font-size: 18px;
    border: none;
    text-decoration: none;
  }

  .sidebar-btn-selected {
    clear: both;
    font-size: 18px;
    border: none;
    text-decoration: none;
  }
`;

const LogoutButtonWrapper = styled.button`
  margin-top: 200px;
  text-align: left;
  width: 100%;
  display: block;
  font-size: 18px;
`;

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  width: 300px;
  min-width: 300px;
  .content {
    margin_left: 20rem;
  }
`;

const Content = styled.div`
  margin-top: 1.5rem;
  margin-left: 15%;
`;

const SidebarContent = styled.div`
  margin-top: 0.5rem;
`;

const MyOrdersContainer = styled.div`
  clear: both;
  margin-left: 1.5rem;
`;

export default Sidebar;
