import styled from "styled-components";
import StartApplyButton from "./StartApplyButton";

const pricingPackages = [
  { name: "Basic Package", count: 50, price: 10 }, // 0.2 per application
  { name: "Standard Package", count: 100, price: 18 }, // 0.18 per application
  { name: "Premium Package", count: 500, price: 75 }, // 0.15 per application
];

const HomePagePricing = () => {
  return (
    <Wrapper>
      <PricingTitle>Packages and Pricing</PricingTitle>
      <PricingItemsWrapper>
        {pricingPackages.map((p, index) => (
          <PricingOption
            key={index}
            name={p.name}
            count={p.count}
            price={p.price}
          />
        ))}
      </PricingItemsWrapper>
      <StartApplyButtonWrapper>
        <StartApplyButton hasBorder={true} />
      </StartApplyButtonWrapper>
    </Wrapper>
  );
};

const PricingOption = ({ name, count, price }) => {
  return (
    <PricingItem>
      <PackageName> {name}</PackageName>
      <PackageCountText> Apply to {count} companies </PackageCountText>
      <PackagePricingText>${price}</PackagePricingText>
    </PricingItem>
  );
};

const Wrapper = styled.div`
  background-color: var(--clr-grey-white);
  margin-top: 150px;
  margin-left: 100px;
  margin-right: 100px;
`;

const PricingTitle = styled.div`
  font-size: 40px;
  text-align: center;
  margin-top: 150px;
  font-weight: 200;
`;

const PricingItemsWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 400px;
  background-color: var(--clr-grey-white);
`;

const PricingItem = styled.div`
  width: 350px;
  height: 300px;
  background-color: var(--clr-green-light);
  margin-left: 50px;
  float: left;
  border-radius: 40px;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
`;

const PackageName = styled.div`
  font-size: 27px;
  text-align: center;
  font-weight: 200;
`;

const PackageCountText = styled.div`
  margin-top: 30px;
  font-size: 20px;
  text-align: center;
  font-weight: 200;
`;

const PackagePricingText = styled.div`
  font-size: 40px;
  text-align: center;
  margin-top: 45px;
  font-weight: 200;
`;

const StartApplyButtonWrapper = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export default HomePagePricing;
