import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import Link from "@mui/material/Link";
import Pagination from "@mui/material/Pagination";
import { styled as MuiStyled } from "@mui/material/styles";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useOrderContext } from "../../../context/order_context";
import { TableRowCell, TableHeaderCell } from "../../../../common/table_styles";
import {
  ORDER_IN_PROGRESS,
  ORDER_PAGE_SIZE,
} from "../../../../common/api_schema/order_schema";
import { getNumberOfPages } from "../../../../common/utils";
import { OrderProgress } from "../../../../common/components/OrderProgress";

export const InProgressOrderTable = () => {
  const [page, setPage] = useState(1);
  const { user } = useAuth0();

  const { fetchOrdersInfo, inProgressCout, orders } = useOrderContext();
  useEffect(() => {
    console.log("OrderTable useEffect ");
    fetchOrdersInfo(ORDER_IN_PROGRESS, 1, user.sub); // eslint-disable-next-line
  }, []);

  const handlePagination = (_, value) => {
    console.log("Order table pagination, fetch page ", value);
    fetchOrdersInfo(ORDER_IN_PROGRESS, value, user.sub); // eslint-disable-next-line
    setPage(value);
  };

  return (
    <TableContainer sx={{ height: "1300px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="table-header">
            <TableHeaderCell>Order Title </TableHeaderCell>
            <TableHeaderCell>Date of Creation</TableHeaderCell>
            <TableHeaderCell>Date of Acceptance</TableHeaderCell>
            <TableHeaderCell>Progress</TableHeaderCell>
            <TableHeaderCell>Companies to apply</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow key={row.orderId} sx={{ border: 0 }}>
              <TableRowCell>
                <div>
                  <PositionName>{row.positionName} </PositionName>
                  <OrderId>Order id: {row.orderId}</OrderId>
                </div>
              </TableRowCell>
              <TableRowCell>{row.createTime}</TableRowCell>
              <TableRowCell>{row.assignedTime}</TableRowCell>
              <TableRowCell>
                {<OrderProgress order={row}></OrderProgress>}
              </TableRowCell>
              <TableRowCell>{row.totalCount - row.appliedCount}</TableRowCell>
              <TableRowCell>
                <OrderButton>
                  <Link
                    href={"/operator/ongoing-orders/" + row.orderId}
                    sx={{ fontSize: 18, fontWeight: 200, color: "black" }}
                    style={{ textDecoration: "none" }}
                  >
                    Continue
                  </Link>
                </OrderButton>
              </TableRowCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StyledPagination
        count={getNumberOfPages(inProgressCout, ORDER_PAGE_SIZE)}
        size="large"
        page={page}
        onChange={handlePagination}
        sx={{ marginTop: "20px", marginLeft: "30%", marginRight: "auto" }}
      />
    </TableContainer>
  );
};

const StyledPagination = MuiStyled(Pagination)({
  "& .MuiPaginationItem-page": {
    fontFamily: "Mulish",
  },
  "& .Mui-selected": {
    color: "var(--clr-green-light)",
    border: 1,
    borderColor: "var(--clr-green-light)",
    borderStyle: "solid",
    backgroundColor: "var(--clr-grey-white)",
  },
});

const PositionName = styled.div`
  font-size: 16px;
  font-weight: 200;
`;

const OrderId = styled.div`
  font-size: 13px;
  font-weight: 200;
  color: var(--clr-grey-dark);
`;

const OrderButton = styled.div`
  height: 40px;
  width: 150px;
  padding-top: 10px;

  text-align: center;

  font-size: 20px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;
