import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import SortByButton from "./CompanySortByButton";

const CompaniesSearchBar = ({
  orderId,
  updateKeyword,
  handleOnClickSearch,
  setSortBy,
  userId,
}) => {
  return (
    <Wrapper>
      <CompanySearchForm
        orderId={orderId}
        updateKeyword={updateKeyword}
        handleOnClickSearch={handleOnClickSearch}
      />
      <SortByButton orderId={orderId} setSortBy={setSortBy} userId={userId} />
    </Wrapper>
  );
};

const CompanySearchForm = ({ orderId, updateKeyword, handleOnClickSearch }) => {
  return (
    <SearchForm>
      <TextField
        id="search-bar"
        className="text"
        onInput={(e) => {
          updateKeyword(e.target.value);
        }}
        variant="outlined"
        placeholder="Search"
        sx={{
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "black",
            },
            height: "40px",
          },
          width: "200px",
        }}
        InputProps={{
          startAdornment: (
            <CompanySearchIcon handleOnClickSearch={handleOnClickSearch} />
          ),
          style: {
            borderRadius: "20px",
            maxLength: 30,
            fontFamily: "Mulish",
            fontSize: "16px",
          },
        }}
      />
    </SearchForm>
  );
};

const CompanySearchIcon = ({ handleOnClickSearch }) => {
  return (
    <IconButton
      onClick={handleOnClickSearch}
      aria-label="search"
      sx={{
        height: "25px",
        marginLeft: "-5px",
      }}
    >
      <SearchIcon
        style={{ fill: "black" }}
        sx={{
          stroke: "var(--clr-grey-white)",
          strokeWidth: 1,
        }}
      />
    </IconButton>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  height: 70px;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  display: block;
`;

const SearchForm = styled.div`
  margin-top: 15px;
  margin-left: 20px;
  float: left;
`;

export default CompaniesSearchBar;
