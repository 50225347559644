import axios from "axios";
import { getFileExtension } from "./file_drop";

const UPLOAD_RESUME_API_ENDPOINT =
  "https://yasjo5sp6e.execute-api.us-west-2.amazonaws.com/default/getPresignedUrlForResume";
const DOWNLOAD_RESUME_API_ENDPOINT =
  "https://we98c74bx9.execute-api.us-west-2.amazonaws.com/default/downloadResumeFromS3";

export const handleUploadToS3 = async (f, getAccessTokenSilently) => {
  console.log(
    "handleUploadToS3 start, file",
    f,
    "file suffix",
    getFileExtension(f),
  );

  try {
    const token = await getAccessTokenSilently();
    console.log("handleUploadToS3 getAccessTokenSilently finishes");

    // GET request: presigned URL
    const response = await axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { fileSuffix: getFileExtension(f) },
      url: UPLOAD_RESUME_API_ENDPOINT,
    });
    console.log("handleUploadToS3 presigned url response", response);

    // PUT request: upload file to S3
    const result = await fetch(response.data.uploadURL, {
      method: "PUT",
      body: f,
    });
    console.log("handleUploadToS3 upload response", result);
    return response.data.filename;
  } catch (error) {
    console.log("handleUploadToS3 error", error);
  }
  return "";
};

export const handleDownloadFromS3 = async (
  s3fileName,
  fileName,
  getAccessTokenSilently,
) => {
  console.log(
    "handleDownloadFromS3, s3fileName",
    s3fileName,
    JSON.stringify({ filePath: s3fileName }),
  );
  try {
    const token = await getAccessTokenSilently();
    const response = await fetch(DOWNLOAD_RESUME_API_ENDPOINT, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ filePath: s3fileName }),
    });
    console.log("handleUploadToS3 response", response);

    const base64Data = await response.text(); // Get base64-encoded string

    // Decode base64 (convert base64 string to a Uint8Array)
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a blob from the Uint8Array
    const blob = new Blob([byteArray], { type: "application/octet-stream" }); // Adjust the MIME type as necessary

    // Create a URL for the blob
    const blobUrl = URL.createObjectURL(blob);

    // Create an anchor element and force a download
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = fileName || s3fileName;
    document.body.appendChild(a); // Append the anchor to the body
    a.click(); // Simulate a click on the anchor to trigger the download
    document.body.removeChild(a); // Clean up
  } catch (error) {
    console.log("handleDownloadFromS3 error", error);
  }
};
