import styled from "styled-components";
import TemplatePopup from "../Popup/TemplatePopup";
import { useTemplateContext } from "../../context/template_context";
import { useUserContext } from "../../context/user_context";

import ProfileItem from "./ProfileItem";

const ProfileTemplatesSection = () => {
  const { triggerTemplatePopup, onCreateTemplate } = useTemplateContext();
  const { templates } = useUserContext();
  console.log("templates", templates);

  const shouldDisplayAddTemplate = templates.length < 10;

  const onClickEdit = (template) => {
    triggerTemplatePopup(template);
  };

  return (
    <Wrapper>
      <ProfileSubsectionContent>
        <h4>My Templates</h4>
        {templates.map((template, index) => {
          return (
            <ProfileItem
              key={template.templateId}
              leftText={template.templateName}
              rightItemType="edit_and_delete"
              rightItemText="edit"
              rightItemAction={() => onClickEdit(template)}
              templateId={template.templateId}
            />
          );
        })}
        {shouldDisplayAddTemplate && (
          <ProfileItem
            leftText="Add a template"
            rightItemType="add"
            rightItemAction={onCreateTemplate}
          />
        )}
        <TemplatePopup />
      </ProfileSubsectionContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  clear: both;
  margin-left: 15px;
  background-color: #ffffff;
  margin-top: 50px;
  h4 {
    display: block;
    font-size: 18px;
    color: var(--clr-green-light);
    margin-bottom: 40px;
  }
`;

const ProfileSubsectionContent = styled.div`
  clear: both;
  overflow: hidden;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
`;

export default ProfileTemplatesSection;
