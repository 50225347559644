import styled from "styled-components";

const HomePageFooter = () => {
  return <Wrapper></Wrapper>;
};

const Wrapper = styled.div`
  margin-top: 150px;
  background-color: white;
  height: 400px;
`;

export default HomePageFooter;
