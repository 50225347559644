import React, { useReducer, useContext } from "react";
import reducer from "../reducers/sidebar_reducers";
import {
  SIDEBAR_SELECT_PROFILE,
  SIDEBAR_SELECT_NEW_ORDERS,
  SIDEBAR_SELECT_ONGOING_ORDERS,
  SIDEBAR_SELECT_IN_DISPUTE_ORDERS,
  SIDEBAR_SELECT_SUBMITTED_ORDERS,
  SIDEBAR_SELECT_PAST_ORDERS,
} from "../actions";

const initialState = {
  isProfileSelected: false,
  isNewOrderSelected: true,
  isOngoingOrdersSelected: false,
  isInDisputeOrdersSelected: false,
  isSubmittedOrdersSelected: false,
  isCompletedOrdersSelected: false,
};

const SidebarContext = React.createContext();

export const SidebarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const selectProfile = () => {
    dispatch({ type: SIDEBAR_SELECT_PROFILE });
  };

  const selectNewOrders = () => {
    dispatch({ type: SIDEBAR_SELECT_NEW_ORDERS });
  };

  const selectInDisputeOrders = () => {
    dispatch({ type: SIDEBAR_SELECT_IN_DISPUTE_ORDERS });
  };

  const selectOngoingOrders = () => {
    dispatch({ type: SIDEBAR_SELECT_ONGOING_ORDERS });
  };

  const selectSubmittedOrders = () => {
    dispatch({ type: SIDEBAR_SELECT_SUBMITTED_ORDERS });
  };

  const selectCompletedOrders = () => {
    dispatch({ type: SIDEBAR_SELECT_PAST_ORDERS });
  };

  return (
    <SidebarContext.Provider
      value={{
        ...state,
        selectProfile,
        selectNewOrders,
        selectOngoingOrders,
        selectInDisputeOrders,
        selectSubmittedOrders,
        selectCompletedOrders,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  return useContext(SidebarContext);
};
