import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled as MuiStyled } from "@mui/material/styles";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useOrderContext } from "../../../context/order_context";
import { TableRowCell, TableHeaderCell } from "../../../../common/table_styles";
import { ORDER_PAGE_SIZE } from "../../../../common/api_schema/order_schema";
import { getNumberOfPages } from "../../../../common/utils";
import { axiosPutWithAuthHeader } from "../../../../common/axios_utils";
import {
  SnackbarFailure,
  SnackbarSuccess,
} from "../../../../common/snackbar/SnackbarHelper";

export const UnassignedOrderTable = () => {
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [failurePopupOpen, setFailurePopupOpen] = useState(false);

  const [page, setPage] = useState(1);
  const { user, isLoading, getAccessTokenSilently } = useAuth0();

  const { fetchNewOrdersInfo, unassignedCount, orders } = useOrderContext();
  useEffect(() => {
    console.log("OrderTable useEffect ");
    fetchNewOrdersInfo(1, user.sub); // eslint-disable-next-line
  }, []);

  const AcceptOrderButtonWrapper = ({ orderId }) => {
    const acceptOrder = async () => {
      console.log("Accept order ", orderId);
      const response = await axiosPutWithAuthHeader(
        `/operator/acceptorder/${user.sub}/orderid=${orderId}/`,
        {},
        getAccessTokenSilently,
        "Accept order",
      );
      if (response === 0) {
        setFailurePopupOpen(true);
      } else {
        // Update success
        fetchNewOrdersInfo(1, user.sub);
        setPage(1);
        setSuccessPopupOpen(true);
      }
    };
    return <AcceptOrderButton onClick={acceptOrder}>Accept</AcceptOrderButton>;
  };

  const handlePagination = (_, value) => {
    console.log("Order table pagination, fetch page ", value);
    fetchNewOrdersInfo(value, user.sub); // eslint-disable-next-line
    setPage(value);
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <TableContainer sx={{ height: "1300px" }}>
      <SnackbarSuccess
        open={successPopupOpen}
        setOpen={setSuccessPopupOpen}
        message={"Order Accepted!"}
      />
      <SnackbarFailure
        open={failurePopupOpen}
        setOpen={setFailurePopupOpen}
        message={
          "Failed to accept order. This order likely has already been accepted and no longer available. Please refresh the page."
        }
      />
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="table-header">
            <TableHeaderCell>Order Title </TableHeaderCell>
            <TableHeaderCell>Date of Creation</TableHeaderCell>
            <TableHeaderCell>Companies to apply</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow key={row.orderId} sx={{ border: 0 }}>
              <TableRowCell>
                <div>
                  <PositionName>{row.positionName} </PositionName>
                  <OrderId>Order id: {row.orderId}</OrderId>
                </div>
              </TableRowCell>
              <TableRowCell>{row.createTime}</TableRowCell>
              <TableRowCell>{row.totalCount}</TableRowCell>
              <TableRowCell>
                <AcceptOrderButtonWrapper orderId={row.orderId}>
                  Accept
                </AcceptOrderButtonWrapper>
              </TableRowCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StyledPagination
        count={getNumberOfPages(unassignedCount, ORDER_PAGE_SIZE)}
        size="large"
        page={page}
        onChange={handlePagination}
        sx={{ marginTop: "20px", marginLeft: "30%", marginRight: "auto" }}
      />
    </TableContainer>
  );
};

const StyledPagination = MuiStyled(Pagination)({
  "& .MuiPaginationItem-page": {
    fontFamily: "Mulish",
  },
  "& .Mui-selected": {
    color: "var(--clr-green-light)",
    border: 1,
    borderColor: "var(--clr-green-light)",
    borderStyle: "solid",
    backgroundColor: "var(--clr-grey-white)",
  },
});

const PositionName = styled.div`
  font-size: 16px;
  font-weight: 200;
`;

const OrderId = styled.div`
  font-size: 13px;
  font-weight: 200;
  color: var(--clr-grey-dark);
`;

const AcceptOrderButton = styled.button`
  height: 50px;
  width: 200px;

  align-items: center;

  font-size: 20px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;
