import React, { useReducer, useContext } from "react";
import reducer from "../reducers/template_reducers";

import { TEMPLATE_POPPUP_CLOSE, TEMPLATE_POPPUP_VIEW } from "../actions";

import {
  QUESTION_LEGALLY_AUTHORIZED_TO_WORK,
  QUESTION_EMPLOYMENT_SPONSORSHIP,
  QUESTION_WILLING_TO_RELOCATE,
  QUESTION_REMOTE_ONSITE_HYBRID,
  QUESTION_JOB_TITLE,
  QUESTION_COUNTRY,
  QUESTION_FULLTIME_PARTTIME,
  QUESTION_SPONSORSHIP_DETAIL,
  QEUSTION_SALARY,
  QUESTION_EXPERIENCE_LEVEL,
  QUESTION_ADDIONAL_INSTRUCTIONS,
  QUESTION_LINKEDIN_LINK,
  QUESTION_PHONE_NUMBER,
} from "../../common/questions/questions";

export const initialTemplateQuestionsState = {
  [QUESTION_LEGALLY_AUTHORIZED_TO_WORK]: -1,
  [QUESTION_EMPLOYMENT_SPONSORSHIP]: -1,
  [QUESTION_WILLING_TO_RELOCATE]: -1,
  [QUESTION_REMOTE_ONSITE_HYBRID]: [],
  [QUESTION_JOB_TITLE]: "",
  [QUESTION_COUNTRY]: "",
  [QUESTION_FULLTIME_PARTTIME]: [],
  // Optional fields
  [QUESTION_SPONSORSHIP_DETAIL]: "",
  [QEUSTION_SALARY]: "",
  [QUESTION_EXPERIENCE_LEVEL]: [],
  [QUESTION_ADDIONAL_INSTRUCTIONS]: "",
  [QUESTION_LINKEDIN_LINK]: "",
  [QUESTION_PHONE_NUMBER]: "",
};

export const initialState = {
  templateId: "",
  isViewingTemplate: false,
  name: "",
  resumeMetadata: undefined,
  questions: initialTemplateQuestionsState,
  isLoading: false,
};

const TemplateContext = React.createContext();

export const TemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  console.log("TemplateProvider state", state);

  const triggerTemplatePopupViewOnly = (templateSnapshot) => {
    console.log("templateSnapshot", templateSnapshot);
    dispatch({
      type: TEMPLATE_POPPUP_VIEW,
      templateSnapshot: templateSnapshot,
    });
  };

  const closeTemplatePopup = () => {
    dispatch({ type: TEMPLATE_POPPUP_CLOSE });
  };

  return (
    <TemplateContext.Provider
      value={{
        ...state,
        triggerTemplatePopupViewOnly,
        closeTemplatePopup,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplateContext = () => {
  return useContext(TemplateContext);
};
