import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";

import FileTextWithIcon from "./FileTextWithIcon";
import { uploadIcon } from "../../assets";

export const isFileSizeSupported = (file, maxSizeInMb) => {
  return file.size < maxSizeInMb * 1024 * 1024;
};

export const getFileExtension = (file) => {
  const parts = file.name.split(".");
  return parts[parts.length - 1].toLowerCase();
};

export const isFileFormatSupported = (file, supportedFormats) => {
  const extension = getFileExtension(file);
  return supportedFormats.includes(extension);
};

export const FileDisplayText = ({ file, fileErrorText, fileMetadata }) => {
  if (typeof fileErrorText !== "undefined" && fileErrorText !== "") {
    return <WarningText>{fileErrorText}</WarningText>;
  } else if (!file && !fileMetadata) {
    return <WarningText>* Resume upload is required</WarningText>;
  } else {
    return (
      <TextWithIconWrapper>
        <FileTextWithIcon metadata={fileMetadata} />
      </TextWithIconWrapper>
    );
  }
};

export const FileUploadZone = ({
  file,
  metadata,
  errorText,
  onUpload,
  maxSizeInMb,
  supportedFileFormats,
}) => {
  // 1. Create new separate triggers for create new vs. edit
  // 2. use this class to handle only the template format
  // 3. create edit, create pop up components, which will pass in a bool param indicating if it's create or edit, and a template object

  // File upload
  // Reference: https://github.com/colbyfayock/my-react-file-upload/blob/main/src/pages/contact.tsx
  const onDrop = useCallback(
    (files) => {
      // Read first file
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      // Check file format
      if (typeof files[0] === "undefined") {
        console.log("No accepted files");
        return;
      }
      console.log(files[0]);

      if (!isFileFormatSupported(files[0], supportedFileFormats)) {
        console.log("File format not supported");
        onUpload(
          null,
          `File format not supported. Supports ${supportedFileFormats}`,
          supportedFileFormats,
        );
        return;
      }

      if (!isFileSizeSupported(files[0], maxSizeInMb)) {
        console.log("File size too large");
        onUpload(null, `File too large (max file size is ${maxSizeInMb} MB)`);
        return;
      }

      // Update state
      onUpload(files[0], "");
    },
    [onUpload, maxSizeInMb, supportedFileFormats],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <PopupContent>
      <div {...getRootProps()}>
        <UploadImage src={uploadIcon} alt="upload" />
        <input {...getInputProps()} />
      </div>
      <FileDisplayText
        file={file}
        fileErrorText={errorText}
        fileMetadata={metadata}
      />
    </PopupContent>
  );
};

const PopupContent = styled.div`
  margin-left: 35px;
  text-align: center;
`;

const UploadImage = styled.img`
  margin-top: 15px;
  text-align: center;
  width: 500px;
`;

const TextWithIconWrapper = styled.div`
  margin-bottom: 50px;
`;

const WarningText = styled.div`
  color: #c50505;
  margin-top: 10px;
  font-size: 15px;
  margin-bottom: 50px;
`;
