import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

const ProfileContactSection = () => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  // console.log("user", user);

  return (
    <Wrapper>
      <ProfileSubsectionContent>
        <h4>Contact information</h4>
        <ItemWrapper>
          <TextLeft> Email </TextLeft>
          <TextRight> {user.email} </TextRight>
        </ItemWrapper>
      </ProfileSubsectionContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  clear: both;
  margin-left: 15px;
  background-color: #ffffff;
  overflow: hidden;
  h4 {
    display: block;
    font-size: 18px;
    color: var(--clr-green-light);
    margin-bottom: 40px;
  }
`;

const ProfileSubsectionContent = styled.div`
  clear: both;
  overflow: hidden;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
`;

const ItemWrapper = styled.div`
  clear: both;
  border-bottom: solid;
  border-bottom-color: var(--clr-grey-light);
  margin-bottom: 30px;
`;

const TextLeft = styled.div`
  display: block;
  font-size: 15px;
  margin-bottom: 15px;
  margin-left: 3px;
`;

const TextRight = styled.div`
  display: block;
  clear: both;
  font-size: 15px;
  color: var(--clr-grey-dark);
  margin-top: -32.5px;
  margin-right: 20px;
  float: right;
`;

export default ProfileContactSection;
