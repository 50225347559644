import styled from "styled-components";
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import { useNewOrderContext } from "../../context/new_order_context";
import { useUserContext } from "../../context/user_context";
import { QUESTION_JOB_TITLE } from "../../../common/questions/questions";

const NewOrderTemplateQuestion = () => {
  const { templateChoice, onChooseTemplate } = useNewOrderContext();
  const { templates } = useUserContext();
  console.log("NewOrderTemplateQuestion templates", templates);

  const handleTemplateChoice = (event) => {
    onChooseTemplate(event.target.value);
  };

  return (
    <Box>
      <FormControl>
        <FormLabel>
          <Box
            component="div"
            fontSize={25}
            color={"#000000"}
            marginTop={3}
            marginBottom={2}
          >
            {"Please choose an application template"}
          </Box>
        </FormLabel>
        <RadioGroup
          name={"new_order_application_template"}
          value={templateChoice}
          onChange={handleTemplateChoice}
        >
          {templates.map((t, index) => {
            const positionName = JSON.parse(t.content)[QUESTION_JOB_TITLE];
            return (
              <FormControlLabel
                sx={{ fontSize: 11 }}
                key={index}
                value={JSON.stringify({
                  name: positionName,
                  id: t.templateId,
                  content: t.content,
                  resume: t.resume,
                })}
                control={
                  <Radio
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "var(--clr-green-light)",
                      },
                    }}
                  />
                }
                label={
                  <Box component="div" fontSize={15}>
                    {t.templateName}
                  </Box>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const NewOrderQuestions = () => {
  return (
    <PopupContent>
      <NewOrderTemplateQuestion />
    </PopupContent>
  );
};

const PopupContent = styled.div`
  margin-left: 35px;
  overflow-y: scroll;
`;

export default NewOrderQuestions;
