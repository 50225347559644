import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "@fontsource/mulish";
import "@fontsource/mulish/200.css";
import "@fontsource/mulish/600.css";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
