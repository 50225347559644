import {
  USER_GET_ACCOUNT_INFO_BEGIN,
  USER_GET_ACCOUNT_INFO_ERROR,
  USER_GET_ACCOUNT_INFO_SUCCESS,
} from "../actions";

const customer_reducer = (state, action) => {
  if (action.type === USER_GET_ACCOUNT_INFO_BEGIN) {
    console.log("Get user account info begin");
    return { ...state, isLoading: true };
  }

  if (action.type === USER_GET_ACCOUNT_INFO_SUCCESS) {
    console.log("Get user account info success");
    return {
      ...state,
      isLoading: false,
      templates: action.payload.templates,
    };
  }

  if (action.type === USER_GET_ACCOUNT_INFO_ERROR) {
    console.log("Get user account info error");
    return { ...state, isLoading: false, error: true };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default customer_reducer;
