import styled from "styled-components";
import { useEffect } from "react";
// import { useAuth0 } from "@auth0/auth0-react";

import ProfileContactSection from "./ProfileContactSection";
import { profileIcon } from "../../../assets";
// import { useUserContext } from "../../context/user_context";
import { useSidebarContext } from "../../context/sidebar_context";

const Profile = () => {
  //   const { fetchUserProfileInfo } = useUserContext();
  const { selectProfile } = useSidebarContext();
  //   const { user, isLoading } = useAuth0();

  useEffect(() => {
    // if (!isLoading) {
    //   fetchUserProfileInfo(user.sub); // eslint-disable-next-line
    // }

    selectProfile(); // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Header>
        <img src={profileIcon} alt="Profile" />
        <h1>Profile</h1>
      </Header>
      <ProfileContactSection />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-left: 30px;
  margin-bottom: 50%;
`;

const Header = styled.div`
  h1 {
    display: block;
    float: left;
    margin-left: 5px;
    margin-top: 20px;
  }

  img {
    display: block;
    float: left;
    margin-top: 13px;
    margin-left: 0;
    width: 70px;
  }
`;

export default Profile;
