import { TableCell } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";

import {
  APPLICATION_COMPLETED_FOR_DISPUTE,
  APPLICATION_DISPUTED,
  APPLICATION_DISPUTE_RESOLVED,
} from "./api_schema/application_schema";

const DisputeOrderToolTip = ({ toolTipText }) => {
  return (
    <Tooltip title={toolTipText}>
      <IconButton>
        <InfoOutlinedIcon
          sx={{ color: "var(--clr-green-light)", height: "20px" }}
        />
      </IconButton>
    </Tooltip>
  );
};

export const TableRowCell = ({ children, data, isFirstColumn = false }) => {
  var applicationInvalid = false;
  var useToolTip = false;
  var toolTipText = "";
  if (data) {
    if (data.status === APPLICATION_DISPUTED) {
      applicationInvalid = true;
      useToolTip = true;
      toolTipText =
        "You have reported an issue with this application. Our operator will apply another company for you as replacement.";
    } else if (data.status === APPLICATION_COMPLETED_FOR_DISPUTE) {
      useToolTip = true;
      const extra = JSON.parse(data.extra);
      const sourceCompanyName = extra.sourceDisputeCompanyName;
      toolTipText = `This application is completed as a replacement for the previous application with ${sourceCompanyName}`;
    } else if (data.status === APPLICATION_DISPUTE_RESOLVED) {
      applicationInvalid = true;
      useToolTip = true;
      const extra = JSON.parse(data.extra);
      const targetCompanyName = extra.targetResolutionCompanyName;
      toolTipText = `This application was previously reported as problematic. We have created a replacement application with ${targetCompanyName} as compensation`;
    }
  }

  useToolTip = useToolTip && isFirstColumn;

  var sx = { fontSize: 15, fontWeight: 200, backgroundColor: "white" };
  if (applicationInvalid) {
    sx.textDecorationLine = "line-through";
  }
  return (
    <TableCell sx={sx}>
      {children}
      {useToolTip ? <DisputeOrderToolTip toolTipText={toolTipText} /> : ""}
    </TableCell>
  );
};

export const TableRowCellGrey = ({ children }) => {
  return (
    <TableCell
      sx={{
        fontSize: 15,
        fontWeight: 200,
        backgroundColor: "var(--clr-grey-white)",
        border: "none",
      }}
    >
      {children}
    </TableCell>
  );
};

export const TableHeaderCell = ({ children }) => {
  return <TableCell className="table-header-cell"> {children} </TableCell>;
};

export const TableHeaderCellGrey = ({ children }) => {
  return (
    <TableCell className="table-header-cell-grey" size="small">
      {children}
    </TableCell>
  );
};
