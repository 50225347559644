import { FileMetadata } from "../../common/api_schema/template_schema";
import {
  DISPUTE_POPUP_OPEN,
  DISPUTE_POPUP_CLOSE,
  DISPUTE_UPLOAD_IMAGE,
} from "../actions";
import { initialState } from "../context/dispute_context";

const template_reducer = (state, action) => {
  if (action.type === DISPUTE_POPUP_OPEN) {
    console.log("Dispute pop-up open");
    return {
      ...state,
      disputedApplicationId: action.applicationId,
      disputedCompanyName: action.companyName,
      isOpen: true,
    };
  }

  if (action.type === DISPUTE_POPUP_CLOSE) {
    console.log("Dispute pop-up close");
    return initialState;
  }

  if (action.type === DISPUTE_UPLOAD_IMAGE) {
    console.log("Dispute upload image");
    const resumeMetadata = action.image
      ? new FileMetadata(action.image.name, "")
      : null;
    return {
      ...state,
      image: action.image,
      imageMetadata: resumeMetadata,
      imageErrorText: action.errorText,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default template_reducer;
