import styled from "styled-components";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const HomePageFAQ = () => {
  return (
    <Wrapper>
      <FAQTitle>FAQ</FAQTitle>
      <FAQAccordion />
    </Wrapper>
  );
};

const FAQAccordion = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (isExpanded, panel) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <FAQAccordianWrapper>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={(_, isExpanded) => handleChange(isExpanded, "panel1")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordianTitle>
            Which platforms do the operators use to submit my job applications?
          </AccordianTitle>
        </AccordionSummary>
        <AccordionDetails>
          <AccordianContent>
            By default, our operators will use LinkedIn to search for job
            postings, but you can also specify which platform you want to use in
            your application template.
          </AccordianContent>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={(_, isExpanded) => handleChange(isExpanded, "panel2")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <AccordianTitle>
            What if a job posting requires registering an applicant account to
            apply?
          </AccordianTitle>
        </AccordionSummary>
        <AccordionDetails>
          <AccordianContent>
            Currently, our operators will only apply to job postings that does
            not require account registration.
          </AccordianContent>
        </AccordionDetails>
      </Accordion>
    </FAQAccordianWrapper>
  );
};

const FAQTitle = styled.div`
  font-size: 40px;
  text-align: center;
  margin-top: 150px;
  font-weight: 200;
`;

const Wrapper = styled.div`
  background-color: var(--clr-grey-white);
  margin-top: 150px;
  margin-left: 100px;
  margin-right: 100px;
`;

const FAQAccordianWrapper = styled.div`
  margin-top: 50px;
`;

const AccordianTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  height: 40px;
  margin-left: 20px;
  margin-right: 30px;
`;

const AccordianContent = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-left: 20px;
  margin-right: 100px;
`;

export default HomePageFAQ;
