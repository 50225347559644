import React, { useReducer, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { axiosGetWithAuthHeader } from "../../common/axios_utils";
import reducer from "../reducers/company_reducers";
import {
  COMPANY_GET_INFO_SUCCESS,
  COMPANY_SET_PAGE,
  COMPANY_SET_SEARCH_KEYWORD,
  COMPANY_SET_SORT_BY,
} from "../actions";
import { APPLICATION_PAGE_SIZE } from "../../common/api_schema/application_schema";

export const initialState = {
  companies: [],
  totalCount: 0,
  searchQuery: {
    keyword: "",
    sortBy: 0,
    page: 1,
  },
};

const CompanyContext = React.createContext();

export const CompanyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getAccessTokenSilently } = useAuth0();

  const fetchCompaniesInfo = async (orderId, startPage, userId, sortBy) => {
    const page = startPage !== null ? startPage : state.searchQuery.page;
    const orderBy = sortBy !== null ? sortBy : state.searchQuery.sortBy;

    const responseData = await axiosGetWithAuthHeader(
      {
        orderId: orderId,
        orderBy: orderBy,
        startPage: page,
        pageSize: APPLICATION_PAGE_SIZE,
        keyword: state.searchQuery.keyword,
        status: -1,
      },
      `/customer/application/${userId}/`,
      getAccessTokenSilently,
      "fetchCompaniesInfo",
    );

    dispatch({
      type: COMPANY_GET_INFO_SUCCESS,
      payload: responseData,
    });
  };

  const updateSearchKeyword = (keyWord) => {
    dispatch({
      type: COMPANY_SET_SEARCH_KEYWORD,
      keyWord: keyWord,
    });
  };

  const setPage = (page) => {
    dispatch({
      type: COMPANY_SET_PAGE,
      page: page,
    });
  };

  const setSortBy = async (orderId, userId, sortBy) => {
    console.log("setSortBy ", sortBy);
    dispatch({
      type: COMPANY_SET_SORT_BY,
      sortBy: sortBy,
    });
    await fetchCompaniesInfo(orderId, null, userId, sortBy);
    setPage(1);
  };

  return (
    <CompanyContext.Provider
      value={{
        ...state,
        fetchCompaniesInfo,
        updateSearchKeyword,
        setPage,
        setSortBy,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => {
  return useContext(CompanyContext);
};
