import {
  QUESTION_SINGLE_CHOICE,
  QUESTION_MULTI_CHOICE,
  QUESTION_TEXT_RESPONSE,
} from "./questions";

import TemplateSingleChoiceQuestion from "./TemplateSingleChoiceQusetion";
import TemplateMultiChoiceQuestion from "./TemplateMultiChoiceQuestion";
import TemplateTextResponseQuestion from "./TemplateTextResponseQuestion";

const Questions = ({
  templateQuestions,
  readOnly,
  questions,
  onUpdateQuestion,
}) => {
  return templateQuestions.map((q, index) => {
    if (q.type === QUESTION_SINGLE_CHOICE) {
      return (
        <TemplateSingleChoiceQuestion
          key={index}
          questionKey={q.questionKey}
          questionBody={q.questionBody}
          choices={q.choices}
          readOnly={readOnly}
          questions={questions}
          onUpdateQuestion={onUpdateQuestion}
        />
      );
    } else if (q.type === QUESTION_MULTI_CHOICE) {
      return (
        <TemplateMultiChoiceQuestion
          key={index}
          questionKey={q.questionKey}
          questionBody={q.questionBody}
          choices={q.choices}
          readOnly={readOnly}
          questions={questions}
          onUpdateQuestion={onUpdateQuestion}
        />
      );
    } else if (q.type === QUESTION_TEXT_RESPONSE) {
      return (
        <TemplateTextResponseQuestion
          key={index}
          questionKey={q.questionKey}
          questionBody={q.questionBody}
          maxResponseLength={q.maxResponseLength}
          readOnly={readOnly}
          questions={questions}
          onUpdateQuestion={onUpdateQuestion}
        />
      );
    } else {
      return "";
    }
  });
};

export default Questions;
