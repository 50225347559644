import styled from "styled-components";

import { useTemplateContext } from "../../context/template_context";
import TemplateRequiredQuestions from "../../../common/questions/TemplateRequiredQuestions";
import TemplateOptionalQuestions from "../../../common/questions/TemplateOptionalQuestions";
import { closeIcon } from "../../../assets";
import FileTextWithIcon from "../../../common/upload/FileTextWithIcon";

const PopupContent = () => {
  const { closeTemplatePopup, resumeMetadata, questions } =
    useTemplateContext();
  return (
    <div>
      <ResumeTextWithIconWrapper>
        <div>Resume</div>
        <FileTextWithIcon metadata={resumeMetadata} />
      </ResumeTextWithIconWrapper>
      <TemplateRequiredQuestions readOnly={true} questions={questions} />
      <TemplateOptionalQuestions readOnly={true} questions={questions} />
      <SaveButtonValid onClick={closeTemplatePopup}>Close</SaveButtonValid>
    </div>
  );
};

const TemplateViewOnlyPopup = () => {
  const { isViewingTemplate, closeTemplatePopup, name } = useTemplateContext();

  return isViewingTemplate ? (
    <Wrapper>
      <PopupBackground />
      <PopupInner>
        <PopupTitle>{name}</PopupTitle>
        <CloseButton>
          <img
            src={closeIcon}
            alt="close"
            onClick={closeTemplatePopup}
            className="popup-close-image"
          />
        </CloseButton>
        <PopupContent />
      </PopupInner>
      ;
    </Wrapper>
  ) : (
    ""
  );
};

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  overflow-y: auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
`;

const PopupInner = styled.div`
  position: absolute;
  top: 50px;
  left: 120px;
  padding: 32px;
  width: 80%;
  max-height: 80%;
  min-width: 700px;
  background-color: #fff;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  height: 40px;
`;

const SaveButtonValid = styled.button`
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: auto;
  display: block;
  height: 40px;
  width: 130px;
  background-color: var(--clr-green-light);
  border-radius: 20px;
  font-size: 15px;
`;

const PopupTitle = styled.h1`
  margin-left: 30px;
  margin-top: 0;
`;

const ResumeTextWithIconWrapper = styled.div`
  margin-left: 35px;
`;

export default TemplateViewOnlyPopup;
