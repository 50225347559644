import styled from "styled-components";
import { ordersIcon } from "../../../assets";

const SidebarMyOrders = () => {
  return (
    <Wrapper>
      <button> My orders </button>
      <img src={ordersIcon} alt="Profile" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  clear: both;
  button {
    background-color: #ffffff;
    margin-top: 0.5rem;
    margin-bottom: 10px;
    font-size: 18px;
    border: none;
    display: block;
    float: left;
  }

  img {
    margin-top: 0.4rem;
    float: left;
    margin-left: 0;
    width: 30px;
  }
`;

export default SidebarMyOrders;
