import React, { useReducer, useContext } from "react";
import reducer from "../reducers/customer_reducer";
import {
  USER_GET_ACCOUNT_INFO_BEGIN,
  USER_GET_ACCOUNT_INFO_SUCCESS,
  USER_GET_ACCOUNT_INFO_ERROR,
} from "../actions";
import { axiosGetWithAuthHeader } from "../../common/axios_utils";

import { useAuth0 } from "@auth0/auth0-react";

export const initialState = {
  templates: [],
  templateNames: [],
  email: "",
  isLoading: false,
  error: false,
};

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchUserProfileInfo = async (userId) => {
    dispatch({ type: USER_GET_ACCOUNT_INFO_BEGIN });

    // Make request to backend
    const responseData = await axiosGetWithAuthHeader(
      {},
      `/customer/template/${userId}/`,
      getAccessTokenSilently,
      "fetchUserProfileInfo",
    );

    // Update states
    if (responseData !== undefined) {
      dispatch({
        type: USER_GET_ACCOUNT_INFO_SUCCESS,
        payload: responseData,
      });
    } else {
      dispatch({ type: USER_GET_ACCOUNT_INFO_ERROR });
    }
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        fetchUserProfileInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
