import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { HomePage } from "./client/pages";
import ClientLayout from "./ClientLayout";
import OperatorLayout from "./OperatorLayout";

const router = createBrowserRouter([
  {
    path: "/client/*",
    element: <ClientLayout />,
  },
  {
    path: "/operator/*",
    element: <OperatorLayout />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
