import styled from "styled-components";
import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
} from "@mui/material";

import { useNewOrderContext } from "../../context/new_order_context";
import { planChoices } from "../../../common/questions/questions";

const NewOrderPlanQuestion = () => {
  const { planChoice, onChoosePlan } = useNewOrderContext();

  const handleTemplateChoice = (event) => {
    onChoosePlan(event.target.value);
  };

  return (
    <PopupContent>
      <Box>
        <FormControl>
          <FormLabel>
            <PlanQuestion>
              <PlanQuestionBody>Please choose a package plan</PlanQuestionBody>
              {/* <PlanQuestionMoreInfo>
                You can cancel or modify the order before it's processed by our
                operators.
              </PlanQuestionMoreInfo> */}
            </PlanQuestion>
          </FormLabel>
          <RadioGroup
            name={"new_order_application_template"}
            value={planChoice}
            onChange={handleTemplateChoice}
          >
            <Stack direction="row" spacing={5}>
              {planChoices.map((plan, index) => {
                return (
                  <FormControlLabel
                    sx={{ fontSize: 11 }}
                    key={index}
                    value={index}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.Mui-checked": {
                            color: "var(--clr-green-light)",
                          },
                        }}
                      />
                    }
                    label={
                      <PlanChoice>
                        <PlanChoiceContent>
                          <PlanName>{plan.planName}</PlanName>
                          <PlanDescription>{plan.description}</PlanDescription>
                          <PlanPrice>{plan.price}$</PlanPrice>
                        </PlanChoiceContent>
                      </PlanChoice>
                    }
                  />
                );
              })}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Box>
    </PopupContent>
  );
};

const PopupContent = styled.div`
  margin-left: 35px;
  overflow-y: scroll;
`;

const PlanQuestion = styled.div`
  margin-top: 10px;
`;

const PlanQuestionBody = styled.div`
  color: #000000;
  font-size: 25px;
`;

// const PlanQuestionMoreInfo = styled.div`
//   margin-top: 10px;
//   color: #var(--clr-green-dark);
//   font-size: 15px;
// `;

const PlanChoice = styled.div`
  margin-top: 20px;
  width: 250px;
  border-color: var(--clr-green-light);
  border-style: solid;
  border-radius: 40px;
`;

const PlanChoiceContent = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const PlanName = styled.div`
  font-size: 25px;
`;

const PlanDescription = styled.div`
  margin-top: 5px;
  font-size: 18px;
`;

const PlanPrice = styled.div`
  margin-top: 5px;
  font-size: 25px;
`;

export default NewOrderPlanQuestion;
