import {
  ORDER_GET_INFO_SUCCESS,
  ORDER_GET_COUNT_SUCCESS,
  ORDER_GET_DETAIL_SUCCESS,
} from "../actions";

const order_reducer = (state, action) => {
  if (action.type === ORDER_GET_INFO_SUCCESS) {
    console.log("Get orders info success");
    return {
      ...state,
      orders: action.ordersInfo,
    };
  }

  if (action.type === ORDER_GET_COUNT_SUCCESS) {
    console.log("Get orders count success", action.ordersCount);
    const c = action.ordersCount;
    return {
      ...state,
      unassignedCount:
        c.unassignedCount >= 0 ? c.unassignedCount : state.unassignedCount,
      inProgressCount:
        c.inProgressCount >= 0 ? c.inProgressCount : state.inProgressCount,
      completedCount:
        c.completedCount >= 0 ? c.completedCount : state.completedCount,
      inDisputeCount:
        c.inDisputeCount >= 0 ? c.inDisputeCount : state.inDisputeCount,
      pastCount: c.pastCount >= 0 ? c.pastCount : state.pastCount,
    };
  }

  if (action.type === ORDER_GET_DETAIL_SUCCESS) {
    console.log("Get order detail success");
    return {
      ...state,
      selectedOrder: action.orderInfo,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default order_reducer;
