import { TableRow, TextField } from "@mui/material";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

import { TableRowCell } from "../../../../common/table_styles";
import { useCompanyContext } from "../../../context/company_context";
import { useOrderContext } from "../../../context/order_context";
import { axiosPutWithAuthHeader } from "../../../../common/axios_utils";
import {
  CreateApplicationRequest,
  EditApplicationRequest,
} from "../../../../common/api_schema/application_schema";
import { SnackbarFailure } from "../../../../common/snackbar/SnackbarHelper";
import CheckButton from "../../common/CheckButton";

const SaveButton = ({ isValid }) => {
  const {
    onUpsertNewApplication,
    editingCompanyName,
    editingPositionName,
    editingPositionLink,
    editingApplicationId,
    cancelEdit,
  } = useCompanyContext();
  const { selectedOrder } = useOrderContext();
  const { user, getAccessTokenSilently } = useAuth0();
  const [failurePopupOpen, setFailurePopupOpen] = useState(false);

  const onClickSave = async () => {
    if (editingApplicationId === "") {
      // Create new application
      const newApplicationData = new CreateApplicationRequest(
        selectedOrder.customerId,
        selectedOrder.orderId,
        editingCompanyName,
        editingPositionName,
        editingPositionLink,
      );
      const responseData = await axiosPutWithAuthHeader(
        `/operator/new-application/${user.sub}/`,
        newApplicationData,
        getAccessTokenSilently,
        "New application",
      );
      if (responseData.success === 0) {
        setFailurePopupOpen(true);
      }
    } else {
      // Edit existing application
      const newEditApplicationRequest = new EditApplicationRequest(
        selectedOrder.customerId,
        editingApplicationId,
        editingCompanyName,
        editingPositionName,
        editingPositionLink,
      );
      const responseData = await axiosPutWithAuthHeader(
        `/operator/edit-application/${user.sub}/`,
        newEditApplicationRequest,
        getAccessTokenSilently,
        "Edit application",
      );
      if (responseData.success === 0) {
        setFailurePopupOpen(true);
      }
    }

    onUpsertNewApplication(selectedOrder.customerId, selectedOrder.orderId);
  };

  const FailureWarning = () => {
    return (
      <SnackbarFailure
        open={failurePopupOpen}
        setOpen={setFailurePopupOpen}
        message={`Failed to create/edit order. The company has already been applied by this customer. Please refresh the page and use the "check if applied" option before adding a new application.`}
      />
    );
  };

  const SaveButtonWithStates = () => {
    if (!isValid) {
      return (
        <TableRowButtonInvalid>
          <FailureWarning />
          Save
        </TableRowButtonInvalid>
      );
    } else {
      return (
        <TableRowButton onClick={onClickSave}>
          <FailureWarning />
          Save
        </TableRowButton>
      );
    }
  };

  return (
    <div>
      <SaveButtonWithStates />
      {editingApplicationId === "" ? (
        ""
      ) : (
        <CancelButton onClick={cancelEdit}>Cancel</CancelButton>
      )}
    </div>
  );
};

const EditCompanyTableRow = () => {
  const {
    editingCompanyName,
    editingPositionLink,
    editingPositionName,
    onUpdateCompanyName,
    onUpdatePositionLink,
    onUpdatePosition,
    checkAppliedStatus,
  } = useCompanyContext();

  const isInputValid =
    editingCompanyName !== "" &&
    editingPositionName !== "" &&
    editingPositionLink !== "" &&
    checkAppliedStatus === 1;

  return (
    <TableRow sx={{ border: 0 }}>
      <TableRowCell w={"2000px"}>
        <div>
          <CompanyEditTextField
            val={editingCompanyName}
            onUpdate={onUpdateCompanyName}
            maxLength={40}
          />
          <CheckButton>check if applied</CheckButton>
        </div>
      </TableRowCell>
      <TableRowCell>
        <CompanyEditTextField
          val={editingPositionName}
          onUpdate={onUpdatePosition}
          maxLength={40}
        />
      </TableRowCell>
      <TableRowCell>
        <CompanyEditTextField
          val={editingPositionLink}
          onUpdate={onUpdatePositionLink}
          maxLength={100}
        />
      </TableRowCell>
      <TableRowCell></TableRowCell>
      <TableRowCell>
        <SaveButton isValid={isInputValid} />
      </TableRowCell>
    </TableRow>
  );
};

const CompanyEditTextField = ({ val, onUpdate, maxLength }) => {
  const handleChange = (event) => {
    onUpdate(event.target.value);
  };
  return (
    <TextField
      multiline
      variant="standard"
      value={val}
      onChange={handleChange}
      inputProps={{
        style: { fontSize: 16 },
        maxLength: maxLength,
      }}
      sx={{
        "& label.Mui-focused": {
          color: "var(--clr-green-light)",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "var(--clr-green-light)",
        },
        "& .MuiInput-underline:hover": {
          borderBottomColor: "var(--clr-green-light)",
        },
        fontSize: 11,
        width: "80%",
      }}
    />
  );
};

const TableRowButton = styled.button`
  height: 40px;
  width: 100px;

  align-items: center;

  font-size: 17px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;

const TableRowButtonInvalid = styled.button`
  height: 40px;
  width: 100px;

  align-items: center;

  font-size: 17px;
  background-color: var(--clr-grey-light);
  border-radius: 40px;
  font-weight: 200;
`;

const CancelButton = styled.button`
  height: 40px;
  width: 100px;
  margin-top: 10px;

  align-items: center;

  font-size: 17px;
  background-color: var(--clr-grey-light);
  border-radius: 40px;
  font-weight: 200;
`;

export default EditCompanyTableRow;
