import styled from "styled-components";
import { TextField } from "@mui/material";

import { useDisputeContext } from "../../context/dispute_context";
import { closeIcon } from "../../../assets";
import CheckButton from "../common/CheckButton";
import { useCompanyContext } from "../../context/company_context";
import ImageUpload from "./ImageUpload";

const PopupContent = () => {
  const {
    editingCompanyName,
    editingPositionLink,
    editingPositionName,
    onUpdateCompanyName,
    onUpdatePositionLink,
    onUpdatePosition,
  } = useCompanyContext();
  return (
    <PopupContentWrapper>
      <EditTextField
        question={"Company Name"}
        val={editingCompanyName}
        onUpdate={onUpdateCompanyName}
        maxLength={40}
      />
      <CheckButton>Check if applied</CheckButton>
      <EditTextField
        question={"Position Name"}
        val={editingPositionName}
        onUpdate={onUpdatePosition}
        maxLength={40}
      />
      <EditTextField
        question={"Position Link"}
        val={editingPositionLink}
        onUpdate={onUpdatePositionLink}
        maxLength={100}
      />
      <ImageUploadWrapper>
        <ImageUpload />
      </ImageUploadWrapper>
    </PopupContentWrapper>
  );
};

const EditTextField = ({ question, val, onUpdate, maxLength }) => {
  const handleChange = (event) => {
    onUpdate(event.target.value);
  };
  return (
    <EditTextFieldWrapper>
      <div> {question}</div>
      <TextField
        multiline
        variant="standard"
        value={val}
        onChange={handleChange}
        inputProps={{
          style: { fontSize: 16 },
          maxLength: maxLength,
        }}
        sx={{
          "& label.Mui-focused": {
            color: "var(--clr-green-light)",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "var(--clr-green-light)",
          },
          "& .MuiInput-underline:hover": {
            borderBottomColor: "var(--clr-green-light)",
          },
          fontSize: 11,
          width: "80%",
        }}
      />
    </EditTextFieldWrapper>
  );
};

const ResolveDisputePopup = () => {
  const { isOpen, closeDisputePopup, onSaveDisputeResolution } =
    useDisputeContext();
  const {
    editingCompanyName,
    editingPositionLink,
    editingPositionName,
    checkAppliedStatus,
  } = useCompanyContext();
  const { image } = useDisputeContext();

  const isInputValid =
    editingCompanyName !== "" &&
    editingPositionName !== "" &&
    editingPositionLink !== "" &&
    checkAppliedStatus === 1 &&
    image !== null;

  return isOpen ? (
    <Wrapper>
      <PopupBackground />
      <PopupInner>
        <PopupTitle>Resolve issue</PopupTitle>
        <CloseButton>
          <img
            src={closeIcon}
            alt="close"
            onClick={closeDisputePopup}
            className="popup-close-image"
          />
        </CloseButton>
        <PopupContent />
        {isInputValid ? (
          <SaveButtonValid onClick={onSaveDisputeResolution}>
            Save
          </SaveButtonValid>
        ) : (
          <SaveButtonInvalid>Save</SaveButtonInvalid>
        )}
      </PopupInner>
      ;
    </Wrapper>
  ) : (
    ""
  );
};

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  overflow-y: auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
`;

const PopupInner = styled.div`
  position: absolute;
  top: 50px;
  left: 120px;
  padding: 32px;
  width: 80%;
  max-height: 80%;
  min-width: 700px;
  background-color: #fff;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  height: 40px;
`;

const SaveButtonValid = styled.button`
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: auto;
  display: block;
  height: 40px;
  width: 130px;
  background-color: var(--clr-green-light);
  border-radius: 20px;
  font-size: 15px;
`;

const SaveButtonInvalid = styled.button`
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: auto;
  display: block;
  height: 40px;
  width: 130px;
  background-color: var(--clr-grey-light);
  border-radius: 20px;
  font-size: 15px;
`;

const PopupTitle = styled.h1`
  margin-left: 30px;
  margin-top: 0;
`;

const PopupContentWrapper = styled.div`
  margin-left: 30px;
`;

const EditTextFieldWrapper = styled.div`
  margin-top: 40px;
`;

const ImageUploadWrapper = styled.div`
  margin-top: 40px;
`;

export default ResolveDisputePopup;
