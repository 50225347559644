import { forwardRef } from "react";
import { Snackbar, Alert } from "@mui/material";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

const SnackbarWrapper = ({ open, setOpen, message, severity }) => {
  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
      <SnackbarAlert onClose={handleClose} severity={severity}>
        {message}
      </SnackbarAlert>
    </Snackbar>
  );
};

export const SnackbarFailure = ({ open, setOpen, message }) => {
  return (
    <SnackbarWrapper
      open={open}
      setOpen={setOpen}
      message={message}
      severity={"error"}
    />
  );
};

export const SnackbarSuccess = ({ open, setOpen, message }) => {
  return (
    <SnackbarWrapper
      open={open}
      setOpen={setOpen}
      message={message}
      severity={"success"}
    />
  );
};
