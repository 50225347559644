import styled from "styled-components";
import { useEffect } from "react";

import { useSidebarContext } from "../../context/sidebar_context";
import { ordersIcon } from "../../../assets";
import OrdersInProgressTabs from "./OrdersInProgressTabs";
import { OrderCreationButton } from "./OrderCreationButton";

const Orders = () => {
  const { selectOngoingOrders } = useSidebarContext();

  useEffect(() => {
    console.log("Ongoing orders useEffect");
    selectOngoingOrders(); // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Header>
        <HeaderOrdersImage src={ordersIcon} alt="Orders" />
        <h1>Orders in progress</h1>
        <CreateOrderButtonWrapper>
          <OrderCreationButton />
        </CreateOrderButtonWrapper>
      </Header>

      <OrdersInProgressTabs />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 1000px;
  margin-left: 30px;
  overflow-x: scroll;
`;

const Header = styled.div`
  width: 100%;
  min-width: 1000px;
  height: 100px;
  margin-bottom: 50px;
  h1 {
    display: block;
    float: left;
    margin-left: 5px;
    margin-top: 20px;
  }
`;

const HeaderOrdersImage = styled.img`
  display: block;
  float: left;
  margin-top: 13px;
  margin-left: 0;
  width: 70px;
`;

const CreateOrderButtonWrapper = styled.div`
  float: right;
  background-color: #f5f5f5;
`;

export default Orders;
