import React from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import Sidebar from "../components/Sidebar/Sidebar";
import MainContent from "../components/MainContent";
import LoadingPage from "./LoadingPage";
import TemplateViewOnlyPopup from "../components/Popup/TemplateViewOnlyPopup";
import ResolveDisputePopup from "../components/Popup/ResolveDisputePopup";

const OperatorHomePage = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    navigate("/operator/login");
    return null;
  }

  return (
    <Wrapper>
      <Sidebar />
      <MainContent />
      <TemplateViewOnlyPopup />
      <ResolveDisputePopup />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  margin: 0px;
  padding: 0px;
  height: 100%;
  display: flex;
`;

export default OperatorHomePage;
