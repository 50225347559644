import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";

import { sortByIcon } from "../../assets";

const SortByButton = ({ orderId, setSortBy, userId }) => {
  const [anchorEl, setAnchorEl] = useState();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const setSortByDate = (e) => {
    console.log("setSortByDate");
    setSortBy(orderId, userId, 0);
    setAnchorEl(null);
  };

  const setSortByPositionName = (e) => {
    console.log("setSortByPositionName");
    setSortBy(orderId, userId, 1);
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <SortByButtonWrapper>
          <SortByText> Sort By </SortByText>
          <SortByImage src={sortByIcon} />
        </SortByButtonWrapper>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          sx={{ fontSize: 15, fontWeight: 200 }}
          onClick={setSortByDate}
        >
          by date
        </MenuItem>
        <MenuItem
          sx={{ fontSize: 15, fontWeight: 200 }}
          onClick={setSortByPositionName}
        >
          by position
        </MenuItem>
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  float: right;
`;

const SortByButtonWrapper = styled.div`
  margin-top: 20px;
  margin-right: 20px;

  display: block;
`;

const SortByImage = styled.img`
  height: 25px;
  width: 25px;
  float: right;
`;

const SortByText = styled.div`
  margin-right: 15px;
  margin-top: 0px;
  font-size: 16px;
  float: left;
  color: black;
`;

export default SortByButton;
