import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import { resumeUploadedIcon } from "../../assets";
import { handleDownloadFromS3 } from "./s3_handler";

const FileTextWithIcon = ({ metadata }) => {
  console.log("FileTextWithIcon, metadata", metadata);
  const { getAccessTokenSilently } = useAuth0();
  const handleDownload = () => {
    handleDownloadFromS3(
      metadata.s3FileName,
      metadata.fileName,
      getAccessTokenSilently,
    );
  };

  return (
    <UploadedText onClick={handleDownload}>
      <UploadedIcon src={resumeUploadedIcon} />
      {metadata.fileName}
    </UploadedText>
  );
};

const UploadedText = styled.button`
  margin-top: 10px;
  font-size: 15px;
`;

const UploadedIcon = styled.img`
  width: 15px;
  margin-right: 5px;
`;

export default FileTextWithIcon;
