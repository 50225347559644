import React, { useReducer, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { axiosGetWithAuthHeader } from "../../common/axios_utils";
import reducer from "../reducers/company_reducers";
import {
  COMPANY_GET_INFO_SUCCESS,
  COMPANY_SET_PAGE,
  COMPANY_SET_SEARCH_KEYWORD,
  COMPANY_SET_SORT_BY,
  COMPANY_UPDATE_COMPANY_NAME,
  COMPANY_UPDATE_LINK,
  COMPANY_UPDATE_POSITION,
  COMPANY_UPDATE_CHECK_STATUS,
  COMPANY_START_EDIT,
  COMPANY_CLEAR_EDITING_STATES,
} from "../actions";
import { APPLICATION_PAGE_SIZE } from "../../common/api_schema/application_schema";
import { useOrderContext } from "./order_context";

export const initialState = {
  editingCompanyName: "",
  editingPositionLink: "",
  editingPositionName: "",
  editingApplicationId: "", // "" is for new application, otherwise, it's editing application's id
  checkAppliedStatus: 0, // 0: not checked, 1: pass check (not applied), 2: need confirmation, 3: failed check (applied before)
  pendingConfirmationCompanyNames: [],
  companies: [],
  totalCount: 0,
  searchQuery: {
    keyword: "",
    sortBy: 0,
    page: 1,
  },
};

const CompanyContext = React.createContext();

export const CompanyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, getAccessTokenSilently } = useAuth0();
  const { fetchOrderDetail } = useOrderContext();
  // console.log(state);

  const fetchCompaniesInfo = async (
    orderId,
    startPage,
    customerId,
    sortBy,
    status = -1,
  ) => {
    const page = startPage !== null ? startPage : state.searchQuery.page;
    const orderBy = sortBy !== null ? sortBy : state.searchQuery.sortBy;
    // console.log("fetchCompaniesInfo, status ", status);
    const responseData = await axiosGetWithAuthHeader(
      {
        orderId: orderId,
        orderBy: orderBy,
        startPage: page,
        pageSize: APPLICATION_PAGE_SIZE,
        keyword: state.searchQuery.keyword,
        status: status,
      },
      `/customer/application/${customerId}/`,
      getAccessTokenSilently,
      "fetchCompaniesInfo",
    );

    dispatch({
      type: COMPANY_GET_INFO_SUCCESS,
      payload: responseData,
    });
    fetchOrderDetail(orderId, user.sub);
  };

  const updateSearchKeyword = (keyWord) => {
    dispatch({
      type: COMPANY_SET_SEARCH_KEYWORD,
      keyWord: keyWord,
    });
  };

  const setPage = (page) => {
    dispatch({
      type: COMPANY_SET_PAGE,
      page: page,
    });
  };

  const setSortBy = async (orderId, userId, sortBy) => {
    console.log("setSortBy ", sortBy);
    dispatch({
      type: COMPANY_SET_SORT_BY,
      sortBy: sortBy,
    });
    await fetchCompaniesInfo(orderId, null, userId, sortBy);
    setPage(1);
  };

  const onUpdateCompanyName = (companyName) => {
    dispatch({
      type: COMPANY_UPDATE_COMPANY_NAME,
      companyName: companyName,
    });
  };

  const onUpdatePositionLink = (link) => {
    dispatch({
      type: COMPANY_UPDATE_LINK,
      link: link,
    });
  };

  const onUpdatePosition = (position) => {
    dispatch({
      type: COMPANY_UPDATE_POSITION,
      position: position,
    });
  };

  const onUpsertNewApplication = async (customerId, orderId) => {
    dispatch({
      type: COMPANY_CLEAR_EDITING_STATES,
    });
    fetchCompaniesInfo(orderId, 1, customerId, null);
  };

  const onPassCompanyCheck = () => {
    dispatch({
      type: COMPANY_UPDATE_CHECK_STATUS,
      checkStatus: 1,
      matchedApplications: [],
    });
  };

  const onFailCompanyCheck = () => {
    dispatch({
      type: COMPANY_UPDATE_CHECK_STATUS,
      checkStatus: 2,
      matchedApplications: [],
    });
  };

  const startConfirmation = (companyNames) => {
    dispatch({
      type: COMPANY_UPDATE_CHECK_STATUS,
      checkStatus: 3,
      companyNames: companyNames,
    });
  };

  const startEditApplication = (
    applicationId,
    companyName,
    positionName,
    positionLink,
  ) => {
    dispatch({
      type: COMPANY_START_EDIT,
      applicationId: applicationId,
      companyName: companyName,
      positionName: positionName,
      positionLink: positionLink,
    });
  };

  const cancelEdit = () => {
    dispatch({
      type: COMPANY_CLEAR_EDITING_STATES,
    });
  };

  return (
    <CompanyContext.Provider
      value={{
        ...state,
        fetchCompaniesInfo,
        updateSearchKeyword,
        setPage,
        setSortBy,
        onUpdateCompanyName,
        onUpdatePositionLink,
        onUpdatePosition,
        onUpsertNewApplication,
        onPassCompanyCheck,
        onFailCompanyCheck,
        startConfirmation,
        startEditApplication,
        cancelEdit,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext = () => {
  return useContext(CompanyContext);
};
