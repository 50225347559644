import axios from "axios";

export const axiosGetWithAuthHeader = async (
  params,
  requestUrl,
  getAccessTokenSilently,
  loggingName,
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.get(requestUrl, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = response.data;
    console.log("responseData for ", loggingName, responseData);
    return responseData;
  } catch (error) {
    console.error(
      "Error fetching data: ",
      loggingName,
      requestUrl,
      params,
      error,
    );
    return undefined;
  }
};

export const axiosPutWithAuthHeader = async (
  requestUrl,
  data,
  getAccessTokenSilently,
  loggingName,
) => {
  try {
    const token = await getAccessTokenSilently();
    const response = await axios.put(requestUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = response.data;
    console.log("responseData for ", loggingName, responseData);
    return responseData;
  } catch (error) {
    console.error(
      "Error fetching data: ",
      loggingName,
      requestUrl,
      data,
      error,
    );
    return undefined;
  }
};
