import styled from "styled-components";

import { optionalQuestions } from "./questions";
import Questions from "./TemplateQuestions";

const TemplateOptionalQuestions = ({
  readOnly,
  questions,
  onUpdateQuestion,
}) => {
  return (
    <PopupContent>
      {readOnly ? (
        ""
      ) : (
        <Subheader>
          These questions below are optional but will help our operators to find
          the most relevant jobs for you.
        </Subheader>
      )}

      <Questions
        templateQuestions={optionalQuestions}
        readOnly={readOnly}
        questions={questions}
        onUpdateQuestion={onUpdateQuestion}
      />
    </PopupContent>
  );
};

const PopupContent = styled.div`
  margin-left: 35px;
  overflow-y: scroll;
`;

const Subheader = styled.div`
  margin-top: 25px;
  font-size: 18px;
`;

export default TemplateOptionalQuestions;
