import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import { CompaniesTable } from "../Company/CompaniesTable";
import { useCompanyContext } from "../../context/company_context";
import { companiesIcon } from "../../../assets";
import CompaniesSearchBar from "../../../common/components/CompaniesSearchBar";

const Companies = () => {
  const { user } = useAuth0();
  const { updateSearchKeyword, fetchCompaniesInfo, setPage, setSortBy } =
    useCompanyContext();

  const handleOnClickSearch = () => {
    fetchCompaniesInfo("", 1, user.sub, null);
    setPage(1);
  };

  return (
    <Wrapper>
      <Header>
        <img src={companiesIcon} alt="Companies" />
        <h1>Companies</h1>
      </Header>
      <CompaniesSearchBar
        orderId={""}
        updateKeyword={updateSearchKeyword}
        handleOnClickSearch={handleOnClickSearch}
        setSortBy={setSortBy}
        userId={user.sub}
      />
      <CompaniesTable orderId="" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-left: 30px;
`;

const Header = styled.div`
  height: 100px;
  h1 {
    display: block;
    float: left;
    margin-left: 5px;
    margin-top: 20px;
  }

  img {
    display: block;
    float: left;
    margin-top: 22px;
    margin-left: 0;
    width: 50px;
  }
`;

export default Companies;
