import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { useSidebarContext } from "../../context/sidebar_context";

const SidebarPastOrders = () => {
  const { isPastOrdersSelected } = useSidebarContext();

  return (
    <Wrapper>
      <NavLink
        to="/client/past-orders"
        className={`${
          isPastOrdersSelected ? "sidebar-btn-selected" : "sidebar-btn"
        }`}
      >
        Past
      </NavLink>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 5px;
  .sidebar-btn {
    background-color: #ffffff;
    font-size: 18px;
    border: none;
    float: left;
    text-decoration: none;
  }

  .sidebar-btn-selected {
    font-size: 18px;
    border: none;
    float: left;
    text-decoration: none;
  }
`;

export default SidebarPastOrders;
