// Sidebar
export const SIDEBAR_SELECT_PROFILE = "SIDEBAR_SELECT_PROFILE";
export const SIDEBAR_SELECT_NEW_ORDERS = "SIDEBAR_SELECT_NEW_ORDERS";
export const SIDEBAR_SELECT_ONGOING_ORDERS = "SIDEBAR_SELECT_ONGOING_ORDERS";
export const SIDEBAR_SELECT_IN_DISPUTE_ORDERS =
  "SIDEBAR_SELECT_IN_DISPUTE_ORDERS";
export const SIDEBAR_SELECT_SUBMITTED_ORDERS =
  "SIDEBAR_SELECT_SUBMITTED_ORDERS";
export const SIDEBAR_SELECT_PAST_ORDERS = "SIDEBAR_SELECT_PAST_ORDERS";

// Order
export const ORDER_GET_INFO_SUCCESS = "ORDER_GET_INFO_SUCCESS";
export const ORDER_GET_COUNT_SUCCESS = "ORDER_GET_COUNT_SUCCESS";
export const ORDER_GET_DETAIL_SUCCESS = "ORDER_GET_DETAIL_SUCCESS";

// Template
export const TEMPLATE_POPPUP_VIEW = "TEMPLATE_POPPUP_VIEW";
export const TEMPLATE_POPPUP_CLOSE = "TEMPLATE_POPPUP_CLOSE";

// Company
export const COMPANY_GET_INFO_SUCCESS = "COMPANY_GET_INFO_SUCCESS";
export const COMPANY_SET_PAGE = "COMPANY_SET_PAGE";
export const COMPANY_SET_SEARCH_KEYWORD = "COMPANY_SET_SEARCH_KEYWORD";
export const COMPANY_SET_SORT_BY = "COMPANY_SET_SORT_BY";
export const COMPANY_UPDATE_COMPANY_NAME = "COMPANY_UPDATE_COMPANY_NAME";
export const COMPANY_UPDATE_LINK = "COMPANY_UPDATE_LINK";
export const COMPANY_UPDATE_POSITION = "COMPANY_UPDATE_POSITION";
export const COMPANY_CLEAR_EDITING_STATES = "COMPANY_CLEAR_EDITING_STATES";
export const COMPANY_UPDATE_CHECK_STATUS = "COMPANY_UPDATE_CHECK_STATUS";
export const COMPANY_START_EDIT = "COMPANY_START_EDIT";

// Dispute
export const DISPUTE_POPUP_OPEN = "DISPUTE_POPUP_OPEN";
export const DISPUTE_POPUP_CLOSE = "DISPUTE_POPUP_CLOSE";
export const DISPUTE_UPLOAD_IMAGE = "DISPUTE_UPLOAD_IMAGE";
