import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled as MuiStyled } from "@mui/material/styles";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useOrderContext } from "../../../context/order_context";
import { TableRowCell, TableHeaderCell } from "../../../../common/table_styles";
import {
  ORDER_SUBMITTED,
  ORDER_PAGE_SIZE,
} from "../../../../common/api_schema/order_schema";
import {
  getNumberOfPages,
  getRemainingTime,
  isMoreThanNDaysAgo,
} from "../../../../common/utils";
import { OrderProgress } from "../../../../common/components/OrderProgress";
import { axiosPutWithAuthHeader } from "../../../../common/axios_utils";
import { SnackbarFailure } from "../../../../common/snackbar/SnackbarHelper";

const CompleteOrderButton = ({ order }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [failurePopupOpen, setFailurePopupOpen] = useState(false);

  const onClickComplete = async () => {
    const responseData = await axiosPutWithAuthHeader(
      `/operator/finish-order/${user.sub}/orderid=${order.orderId}/`,
      {},
      getAccessTokenSilently,
      "Finish order",
    );
    if (responseData === 1) {
      // success
      window.location.reload();
    } else {
      // fail
      setFailurePopupOpen(true);
    }
  };

  const FailureWarning = () => {
    return (
      <SnackbarFailure
        open={failurePopupOpen}
        setOpen={setFailurePopupOpen}
        message={`Failed to complete order. The order may have already been completed. Please refresh the page.`}
      />
    );
  };

  /** For testing **/
  // return (
  //   <OrderButton onClick={onClickComplete}>
  //     <FailureWarning />
  //     Complete
  //   </OrderButton>
  // );

  if (isMoreThanNDaysAgo(order.completeTimeRaw)) {
    return (
      <OrderButton onClick={onClickComplete}>
        <FailureWarning />
        Complete
      </OrderButton>
    );
  } else {
    return <OrderButtonInvalid>Complete </OrderButtonInvalid>;
  }
};

export const SubmittedOrderTable = () => {
  const [page, setPage] = useState(1);
  const { user } = useAuth0();

  const { fetchOrdersInfo, inProgressCout, orders } = useOrderContext();
  useEffect(() => {
    console.log("OrderTable useEffect ");
    fetchOrdersInfo(ORDER_SUBMITTED, 1, user.sub); // eslint-disable-next-line
  }, []);

  const handlePagination = (_, value) => {
    console.log("Order table pagination, fetch page ", value);
    fetchOrdersInfo(ORDER_SUBMITTED, value, user.sub); // eslint-disable-next-line
    setPage(value);
  };

  console.log("CompletedOrderTable, orders: ", orders);

  return (
    <TableContainer sx={{ height: "1300px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="table-header">
            <TableHeaderCell>Order Title </TableHeaderCell>
            <TableHeaderCell>Date of Creation</TableHeaderCell>
            <TableHeaderCell>Date of Submission</TableHeaderCell>
            <TableHeaderCell>Progress</TableHeaderCell>
            <TableHeaderCell>Remaining time </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow key={row.orderId} sx={{ border: 0 }}>
              <TableRowCell>
                <div>
                  <PositionName>{row.positionName} </PositionName>
                  <OrderId>Order id: {row.orderId}</OrderId>
                </div>
              </TableRowCell>
              <TableRowCell>{row.createTime}</TableRowCell>
              <TableRowCell>{row.completeTime}</TableRowCell>
              <TableRowCell>
                {<OrderProgress order={row}></OrderProgress>}
              </TableRowCell>
              <TableRowCell>
                {getRemainingTime(row.completeTimeRaw)}
              </TableRowCell>
              <TableRowCell>
                <CompleteOrderButton order={row} />
              </TableRowCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StyledPagination
        count={getNumberOfPages(inProgressCout, ORDER_PAGE_SIZE)}
        size="large"
        page={page}
        onChange={handlePagination}
        sx={{ marginTop: "20px", marginLeft: "30%", marginRight: "auto" }}
      />
    </TableContainer>
  );
};

const StyledPagination = MuiStyled(Pagination)({
  "& .MuiPaginationItem-page": {
    fontFamily: "Mulish",
  },
  "& .Mui-selected": {
    color: "var(--clr-green-light)",
    border: 1,
    borderColor: "var(--clr-green-light)",
    borderStyle: "solid",
    backgroundColor: "var(--clr-grey-white)",
  },
});

const PositionName = styled.div`
  font-size: 16px;
  font-weight: 200;
`;

const OrderId = styled.div`
  font-size: 13px;
  font-weight: 200;
  color: var(--clr-grey-dark);
`;

const OrderButton = styled.button`
  height: 50px;
  width: 150px;

  text-align: center;

  font-size: 20px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;

const OrderButtonInvalid = styled.button`
  height: 50px;
  width: 150px;

  text-align: center;

  font-size: 20px;
  background-color: var(--clr-grey-light);
  border-radius: 40px;
  font-weight: 200;
`;
