const PENDING_COMPLETE_DAYS = 3;

export const parseISOLocal = (s) => {
  var b = s.split(/\D/);
  return b[0] + "/" + b[1] + "/" + b[2];
};

const getTimeDifferenceInMilliseconds = (d) => {
  // Input date is in UTC
  const inputDate = new Date(d);

  // Get current date in UTC
  const now = new Date();
  const currentDate = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds(),
  );

  return currentDate - inputDate;
};

export const isMoreThanNDaysAgo = (d, n = null) => {
  if (n === null) {
    n = PENDING_COMPLETE_DAYS;
  }
  const diff = getTimeDifferenceInMilliseconds(d);
  const differenceInDays = diff / (1000 * 60 * 60 * 24);
  return differenceInDays >= n;
};

export const getRemainingTime = (d, n = null) => {
  if (n === null) {
    n = PENDING_COMPLETE_DAYS;
  }
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const millisecondsInAnHour = 1000 * 60 * 60;
  const millisecondsInAMinute = 1000 * 60;

  const diff = n * millisecondsInADay - getTimeDifferenceInMilliseconds(d);
  if (diff < 0) {
    return "0 min";
  }

  const days = Math.floor(diff / millisecondsInADay);
  const hours = Math.floor((diff % millisecondsInADay) / millisecondsInAnHour);
  const minutes = Math.floor(
    (diff % millisecondsInAnHour) / millisecondsInAMinute,
  );

  var res = "";
  if (days > 0) {
    res += days + " d ";
  }
  if (hours > 0) {
    res += hours + " h ";
  }
  if (minutes > 0) {
    res += minutes + " m";
  }
  return res;
};

export const getNumberOfPages = (count, pageSize) => {
  if (isNaN(count) || count === 0) {
    return 1;
  }
  return count % pageSize === 0
    ? Math.floor(count / pageSize)
    : Math.floor(count / pageSize) + 1;
};

export const truncateText = (text, maxLength) => {
  const defaultMaxLength = 30;
  if (!maxLength) {
    maxLength = defaultMaxLength;
  }

  return text.length > maxLength
    ? text.substring(0, maxLength - 3) + "..."
    : text;
};
