import {
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
} from "@mui/material";

const TemplateMultiChoiceQuestion = ({
  questionKey,
  questionBody,
  choices,
  readOnly,
  questions,
  onUpdateQuestion,
}) => {
  const handleChosenItemsChange = (event) => {
    const index = questions[questionKey].indexOf(event.target.value);
    if (index === -1) {
      onUpdateQuestion(questionKey, [
        ...questions[questionKey],
        event.target.value,
      ]);
    } else {
      onUpdateQuestion(
        questionKey,
        questions[questionKey].filter(
          (chosen) => chosen !== event.target.value,
        ),
      );
    }
  };

  console.log("Multi choice selection", questions);

  return (
    <Box>
      <Box>
        <FormControl>
          <FormLabel>
            <Box component="div" fontSize={18} color={"#000000"} marginTop={3}>
              {questionBody}
            </Box>
          </FormLabel>
          <FormGroup>
            {choices.map((c, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      disabled={readOnly}
                      key={index}
                      value={c}
                      checked={questions[questionKey].includes(c)}
                      onChange={handleChosenItemsChange}
                      sx={{
                        "&.Mui-checked": {
                          color: "var(--clr-green-light)",
                        },
                      }}
                    />
                  }
                  label={
                    <Box component="div" fontSize={15}>
                      {c}
                    </Box>
                  }
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default TemplateMultiChoiceQuestion;
