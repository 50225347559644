import styled from "styled-components";
import StartApplyButton from "./StartApplyButton";
import { logo } from "../../../assets";

const HomePageHeader = ({
  scrollToAboutUs,
  scrollToGuide,
  scrollToPricing,
  scrollToFaq,
}) => {
  return (
    <Wrapper>
      <LogoImage src={logo} />
      <AboutUsButtonWrapper>
        <HeaderButton onClick={scrollToAboutUs}>About us</HeaderButton>
      </AboutUsButtonWrapper>
      <HeaderButton onClick={scrollToGuide}>User guide</HeaderButton>
      <HeaderButton onClick={scrollToPricing}>Pricing</HeaderButton>
      <HeaderButton onClick={scrollToFaq}>FAQ</HeaderButton>
      <StartApplyButtonWrapper>
        <StartApplyButton hasBorder={true} />
      </StartApplyButtonWrapper>
    </Wrapper>
  );
};

const LogoImage = styled.img`
  margin-top: -10px;
  width: 150px;
  float: left;
`;

const StartApplyButtonWrapper = styled.div`
  float: right;
`;

const AboutUsButtonWrapper = styled.div`
  margin-left: 5%;
  float: left;
  background-color: var(--clr-grey-white);
`;

const HeaderButton = styled.button`
  float: left;
  margin-top: 15px;
  margin-left: 30px;
  background-color: var(--clr-grey-white);
  font-size: 20px;
  font-weight: 200;
`;

const Wrapper = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 25px;
  height: 100px;
  background-color: var(--clr-grey-white);
`;

export default HomePageHeader;
