import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const OrderProgress = ({ order }) => {
  const progress = Math.min((order.appliedCount / order.totalCount) * 100, 100);
  return (
    <Wrapper>
      <CircularProgress
        variant="determinate"
        value={progress}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "100%",
          boxShadow: "inset 0 0 0px 5px #d9d9d9",
          backgroundColor: "transparent",
          color: "#45ae21",
        }}
        thickness={5}
      />
      <ProgressText>{progress}%</ProgressText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressText = styled.div`
  margin-left: 10px;
  font-size: 15px;
  color: var(--clr-green-light);
`;
