import {
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const TemplateSingleChoiceQuestion = ({
  questionKey,
  questionBody,
  choices,
  readOnly,
  questions,
  onUpdateQuestion,
}) => {
  const handleChange = (event) => {
    onUpdateQuestion(questionKey, event.target.value);
  };

  console.log("Single choice selection", questions);

  return (
    <Box>
      <FormControl>
        <FormLabel>
          <Box component="div" fontSize={18} color={"#000000"} marginTop={3}>
            {questionBody}
          </Box>
        </FormLabel>
        <RadioGroup
          name={questionKey}
          value={questions[questionKey]}
          onChange={handleChange}
        >
          {choices.map((c, index) => {
            return (
              <FormControlLabel
                sx={{ fontSize: 11 }}
                key={index}
                value={index}
                control={
                  <Radio
                    disabled={readOnly}
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "var(--clr-green-light)",
                      },
                    }}
                  />
                }
                label={
                  <Box component="div" fontSize={15}>
                    {c}
                  </Box>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default TemplateSingleChoiceQuestion;
