import React, { useReducer, useContext } from "react";
import reducer from "../reducers/template_reducers";
import { useAuth0 } from "@auth0/auth0-react";

import {
  TEMPLATE_POPPUP_CLOSE,
  TEMPLATE_POPPUP_EDIT,
  TEMPLATE_POPPUP_UPLOAD_RESUME,
  TEMPLATE_POPPUP_INCREMENT_STEP,
  TEMPLATE_POPPUP_DECREMENT_STEP,
  TEMPLATE_POPPUP_UPDATE_QUESTION_ANSWER,
  TEMPLATE_POPPUP_VIEW,
  TEMPLATE_POPPUP_CREATE,
  TEMPLATE_POPPUP_SAVE_BEGIN,
  TEMPLATE_POPPUP_SAVE_SUCCESS,
  TEMPLATE_POPPUP_SAVE_ERROR,
} from "../actions";

import {
  QUESTION_LEGALLY_AUTHORIZED_TO_WORK,
  QUESTION_EMPLOYMENT_SPONSORSHIP,
  QUESTION_WILLING_TO_RELOCATE,
  QUESTION_REMOTE_ONSITE_HYBRID,
  QUESTION_JOB_TITLE,
  QUESTION_COUNTRY,
  QUESTION_FULLTIME_PARTTIME,
  QUESTION_SPONSORSHIP_DETAIL,
  QEUSTION_SALARY,
  QUESTION_EXPERIENCE_LEVEL,
  QUESTION_ADDIONAL_INSTRUCTIONS,
  QUESTION_LINKEDIN_LINK,
  QUESTION_PHONE_NUMBER,
} from "../../common/questions/questions";
import {
  FileMetadata,
  TemplateData,
} from "../../common/api_schema/template_schema";
import { useUserContext } from "./user_context";
import { handleUploadToS3 } from "../../common/upload/s3_handler";
import { axiosPutWithAuthHeader } from "../../common/axios_utils";

export const initialTemplateQuestionsState = {
  [QUESTION_LEGALLY_AUTHORIZED_TO_WORK]: -1,
  [QUESTION_EMPLOYMENT_SPONSORSHIP]: -1,
  [QUESTION_WILLING_TO_RELOCATE]: -1,
  [QUESTION_REMOTE_ONSITE_HYBRID]: [],
  [QUESTION_JOB_TITLE]: "",
  [QUESTION_COUNTRY]: "",
  [QUESTION_FULLTIME_PARTTIME]: [],
  // Optional fields
  [QUESTION_SPONSORSHIP_DETAIL]: "",
  [QEUSTION_SALARY]: "",
  [QUESTION_EXPERIENCE_LEVEL]: [],
  [QUESTION_ADDIONAL_INSTRUCTIONS]: "",
  [QUESTION_LINKEDIN_LINK]: "",
  [QUESTION_PHONE_NUMBER]: "",
};

export const initialState = {
  templateId: "",
  isEditingTemplate: false,
  isViewingTemplate: false,
  name: "",
  resume: undefined,
  resumeMetadata: undefined,
  resumeErrorText: "",
  step: 1,
  questions: initialTemplateQuestionsState,
  isLoading: false,
};

const TemplateContext = React.createContext();

export const TemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { fetchUserProfileInfo } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();

  console.log("TemplateProvider state", state);

  const onCreateTemplate = () => {
    dispatch({ type: TEMPLATE_POPPUP_CREATE });
  };

  const triggerTemplatePopup = (templateData) => {
    dispatch({ type: TEMPLATE_POPPUP_EDIT, templateData: templateData });
  };

  const triggerTemplatePopupViewOnly = (templateSnapshot) => {
    console.log("templateSnapshot", templateSnapshot);
    dispatch({
      type: TEMPLATE_POPPUP_VIEW,
      templateSnapshot: templateSnapshot,
    });
  };

  const closeTemplatePopup = () => {
    dispatch({ type: TEMPLATE_POPPUP_CLOSE });
  };

  const onSaveTemplate = async (userId) => {
    dispatch({ type: TEMPLATE_POPPUP_SAVE_BEGIN });
    try {
      var resumeMetaDataSerialized = FileMetadata.getSerializedData(
        state.resumeMetadata.fileName,
        state.resumeMetadata.s3Filename,
      );
      if (state.resume !== undefined) {
        const resumeS3Filename = await handleUploadToS3(
          state.resume,
          getAccessTokenSilently,
        );
        if (resumeS3Filename === "") {
          dispatch({ type: TEMPLATE_POPPUP_SAVE_ERROR });
          return;
        }
        resumeMetaDataSerialized = FileMetadata.getSerializedData(
          state.resume.name,
          resumeS3Filename,
        );
      }
      console.log("resumeMetaDataSerialized", resumeMetaDataSerialized);

      const newTemplateData = TemplateData.constructFromTemplateState(
        state,
        resumeMetaDataSerialized,
      );
      await axiosPutWithAuthHeader(
        `/customer/template/${userId}/`,
        newTemplateData,
        getAccessTokenSilently,
        "New template",
      );

      dispatch({ type: TEMPLATE_POPPUP_SAVE_SUCCESS });
      fetchUserProfileInfo(userId);
    } catch (error) {
      console.log("Fetch template error: ", error);
      dispatch({ type: TEMPLATE_POPPUP_SAVE_ERROR });
    }
  };

  const onUploadResume = (resume, resumeErrorText) => {
    dispatch({
      type: TEMPLATE_POPPUP_UPLOAD_RESUME,
      resume: resume,
      resumeErrorText: resumeErrorText,
    });
  };

  const onClickNext = () => {
    dispatch({
      type: TEMPLATE_POPPUP_INCREMENT_STEP,
    });
  };

  const onClickBack = () => {
    dispatch({
      type: TEMPLATE_POPPUP_DECREMENT_STEP,
    });
  };

  const onUpdateTemplateQuestionAnswer = (questionKey, answerValue) => {
    dispatch({
      type: TEMPLATE_POPPUP_UPDATE_QUESTION_ANSWER,
      questionKey: questionKey,
      answerValue: answerValue,
    });
  };

  return (
    <TemplateContext.Provider
      value={{
        ...state,
        onCreateTemplate,
        triggerTemplatePopup,
        triggerTemplatePopupViewOnly,
        closeTemplatePopup,
        onUploadResume,
        onSaveTemplate,
        onClickNext,
        onClickBack,
        onUpdateTemplateQuestionAnswer,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplateContext = () => {
  return useContext(TemplateContext);
};
