import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import ProfileItem from "./ProfileItem";

const ProfileContactSection = () => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  // console.log("user", user);

  return (
    <Wrapper>
      <ProfileSubsectionContent>
        <h4>Contact information</h4>
        <ProfileItem
          leftText="Email"
          rightItemType="display_text"
          rightItemText={user.email}
        />
      </ProfileSubsectionContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  clear: both;
  margin-left: 15px;
  background-color: #ffffff;
  overflow: hidden;
  h4 {
    display: block;
    font-size: 18px;
    color: var(--clr-green-light);
    margin-bottom: 40px;
  }
`;

const ProfileSubsectionContent = styled.div`
  clear: both;
  overflow: hidden;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
`;

export default ProfileContactSection;
