import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled as MuiStyled } from "@mui/material/styles";
import { useEffect } from "react";
import Link from "@mui/material/Link";
import styled from "styled-components";

import { APPLICATION_PAGE_SIZE } from "../../../../common/api_schema/application_schema";
import {
  getNumberOfPages,
  parseISOLocal,
  truncateText,
} from "../../../../common/utils";
import { TableHeaderCell, TableRowCell } from "../../../../common/table_styles";
import { useOrderContext } from "../../../context/order_context";
import EditCompanyTableRow from "./EditCompanyTableRow";
import { useCompanyContext } from "../../../context/company_context";

const EditButton = ({ company }) => {
  const { startEditApplication } = useCompanyContext();

  const onClickEdit = () => {
    startEditApplication(
      company.applicationId,
      company.companyName,
      company.positionName,
      company.positionLink,
    );
  };

  return <TableRowButton onClick={onClickEdit}> Edit</TableRowButton>;
};

const getCompanyLists = (companies, selectedOrder, editingApplicationId) => {
  const rows = [];

  // Row for adding new application
  if (
    selectedOrder.appliedCount < selectedOrder.totalCount &&
    editingApplicationId === ""
  ) {
    rows.push(<EditCompanyTableRow key={"first_row"} />);
  }

  for (let i = 0; i < companies.length; i++) {
    const row = companies[i];

    if (row.applicationId !== editingApplicationId) {
      rows.push(
        <TableRow key={row.applicationId} sx={{ border: 0 }}>
          <TableRowCell>{row.companyName}</TableRowCell>
          <TableRowCell>{row.positionName}</TableRowCell>
          <TableRowCell>
            <Link
              sx={{ fontSize: 15, fontWeight: 200, color: "black" }}
              href={row.positionLink}
            >
              {truncateText(row.positionLink)}
            </Link>
          </TableRowCell>
          <TableRowCell>{parseISOLocal(row.createTime)}</TableRowCell>
          <TableRowCell>
            <EditButton company={row}>Edit</EditButton>
          </TableRowCell>
        </TableRow>,
      );
    } else if (row.applicationId !== "") {
      rows.push(<EditCompanyTableRow key={row.applicationId} />);
    }
  }

  return rows;
};

export const InProgressCompaniesTable = ({ orderId, customerId }) => {
  const {
    companies,
    fetchCompaniesInfo,
    editingApplicationId,
    totalCount,
    page,
    setPage,
  } = useCompanyContext();
  const { selectedOrder } = useOrderContext();

  useEffect(() => {
    fetchCompaniesInfo(orderId, 1, customerId, null); // eslint-disable-next-line
  }, []);

  const handlePagination = (event, value) => {
    fetchCompaniesInfo(orderId, value, customerId, null); // eslint-disable-next-line
    setPage(value);
  };

  console.log("CompaniesTable, companies", companies);

  return (
    <TableContainer sx={{ height: "1300px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="table-header">
            <TableHeaderCell> Company </TableHeaderCell>
            <TableHeaderCell> Position </TableHeaderCell>
            <TableHeaderCell>Link</TableHeaderCell>
            <TableHeaderCell>Date of application</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCompanyLists(companies, selectedOrder, editingApplicationId)}
        </TableBody>
      </Table>
      <StyledPagination
        count={getNumberOfPages(totalCount, APPLICATION_PAGE_SIZE)}
        size="large"
        page={page}
        onChange={handlePagination}
        sx={{ marginTop: "20px", marginLeft: "30%", marginRight: "auto" }}
      />
    </TableContainer>
  );
};

const StyledPagination = MuiStyled(Pagination)({
  "& .MuiPaginationItem-page": {
    fontFamily: "Mulish",
  },
  "& .Mui-selected": {
    color: "var(--clr-green-light)",
    border: 1,
    borderColor: "var(--clr-green-light)",
    borderStyle: "solid",
    backgroundColor: "var(--clr-grey-white)",
  },
});

const TableRowButton = styled.button`
  height: 40px;
  width: 100px;

  align-items: center;

  font-size: 17px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;
