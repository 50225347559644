import { parseISOLocal } from "../utils";

export class OrderCreationRequest {
  constructor(templateId, totalCount, positionName, templateContent) {
    this.templateId = templateId;
    this.totalCount = totalCount;
    this.positionName = positionName;
    this.templateContent = templateContent;
  }
}

export class OrderTemplateSnapshot {
  constructor(questionsSerialized, resumeMetadataSerialized) {
    this.questionsSerialized = questionsSerialized;
    this.resumeMetadataSerialized = resumeMetadataSerialized;
  }

  toString() {
    return JSON.stringify(this);
  }
}

export const ORDER_PAGE_SIZE = 15;

export const ORDER_UNASSIGNED = 0;
export const ORDER_IN_PROGRESS = 1;
export const ORDER_SUBMITTED = 2;
export const ORDER_PAST = 3;
export const ORDER_IN_DISPUTE = 4;

export const ORDER_SORT_BY_CREAT_TIME = 0;
export const ORDER_SORT_BY_POSITION_NAME = 1;

export class OrderDetail {
  constructor(
    orderId,
    appliedCount,
    assignedOperatorId,
    createTime,
    customerId,
    disputedCount,
    positionName,
    resolvedCount,
    status,
    templateId,
    totalCount,
    updateTime,
    assignedTime,
    completeTime,
    finishedTime,
    completeTimeRaw,
  ) {
    this.orderId = orderId;
    this.appliedCount = appliedCount;
    this.assignedOperatorId = assignedOperatorId;
    this.createTime = createTime;
    this.customerId = customerId;
    this.disputedCount = disputedCount;
    this.positionName = positionName;
    this.resolvedCount = resolvedCount;
    this.status = status;
    this.templateId = templateId;
    this.totalCount = totalCount;
    this.updateTime = updateTime;
    this.assignedTime = assignedTime;
    this.completeTime = completeTime;
    this.finishedTime = finishedTime;
    this.completeTimeRaw = completeTimeRaw;
  }

  static fromPayload(payload) {
    const createTimeReformatted = parseISOLocal(payload["createTime"]);
    const assignedTime =
      payload["assignedTime"] === undefined || payload["assignedTime"] === null
        ? null
        : parseISOLocal(payload["assignedTime"]);
    const completeTime =
      payload["completeTime"] === undefined || payload["completeTime"] === null
        ? null
        : parseISOLocal(payload["completeTime"]);
    const finishedTime =
      payload["finishedTime"] === undefined || payload["finishedTime"] === null
        ? null
        : parseISOLocal(payload["finishedTime"]);

    return new OrderDetail(
      payload["orderId"],
      payload["appliedCount"],
      payload["assignedOperatorId"],
      createTimeReformatted,
      payload["customerId"],
      payload["disputedCount"],
      payload["positionName"],
      payload["resolvedCount"],
      payload["status"],
      payload["templateId"],
      payload["totalCount"],
      payload["updateTime"],
      assignedTime,
      completeTime,
      finishedTime,
      payload["completeTime"],
    );
  }
}

export class OrderCountsResponse {
  constructor(
    unassignedCount,
    inProgressCount,
    completedCount,
    pastCount,
    inDisputeCount,
  ) {
    this.unassignedCount = unassignedCount;
    this.inProgressCount = inProgressCount;
    this.completedCount = completedCount;
    this.pastCount = pastCount;
    this.inDisputeCount = inDisputeCount;
  }

  static fromPayload(payload) {
    return new OrderCountsResponse(
      payload["unassignedCount"],
      payload["inProgressCount"],
      payload["completedCount"],
      payload["pastCount"],
      payload["inDisputeCount"],
    );
  }
}

export const intToStatus = (i) => {
  if (i === 0) {
    return "Pending assignment";
  } else if (i === 1) {
    return "In progress";
  } else if (i === 2) {
    return "Completed";
  } else if (i === 3) {
    return "Past";
  } else if (i === 4) {
    return "Resolving issues";
  }
  return "Error";
};
