import styled from "styled-components";
import { DialogContent, DialogContentText } from "@mui/material";

export const DialogButton = styled.button`
  color: var(--clr-green-light);
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const DialogText = ({ children }) => {
  return (
    <DialogContent sx={{ fontFamily: "Mulish" }}>
      <DialogContentText id="dialog-description" fontSize={"20px"}>
        {children}
      </DialogContentText>
    </DialogContent>
  );
};
