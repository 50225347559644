import styled from "styled-components";
import { addOrdersIcon } from "../../../assets";
import { useNewOrderContext } from "../../context/new_order_context";

export const OrderCreationButton = () => {
  const { triggerNewOrderPopup } = useNewOrderContext();
  return (
    <CreateOrderButtonWrapper>
      <CreateOrderTopPadding />
      <CreateOrderButton onClick={triggerNewOrderPopup}>
        <CreateOrderImage src={addOrdersIcon} />
        Create new order
      </CreateOrderButton>
    </CreateOrderButtonWrapper>
  );
};

const CreateOrderButton = styled.button`
  height: 50px;
  width: 250px;

  display: flex;
  align-items: center;

  font-size: 16px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
`;

const CreateOrderButtonWrapper = styled.div`
  background-color: #f5f5f5;
`;

const CreateOrderTopPadding = styled.div`
  height: 30px;
`;

const CreateOrderImage = styled.img`
  order: 0;
  margin-left: 15%;
  margin-right: 10px;
  height: 25px;
  width: 25px;
`;
