import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import Link from "@mui/material/Link";

import { actionsIcon } from "../../../assets";

const OrderActionMenu = ({ order }) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ActionsButtonImage src={actionsIcon} />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <Link
            onClick={handleClose}
            href={"/client/order/" + order.orderId}
            sx={{ fontSize: 15, fontWeight: 200, color: "black" }}
            style={{ textDecoration: "none" }}
          >
            View Detail
          </Link>
        </MenuItem>
        <MenuItem sx={{ fontSize: 15, fontWeight: 200 }} onClick={handleClose}>
          Close
        </MenuItem>
      </Menu>
    </div>
  );
};

const ActionsButtonImage = styled.img`
  height: 4px;
  width: 20px;
`;

export default OrderActionMenu;
