import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";

import { OrderTable } from "./OrderTable";
import { OrdersEmptyPage } from "./OrdersEmptyPage";
import { useOrderContext } from "../../context/order_context";
import {
  ORDER_IN_PROGRESS,
  ORDER_UNASSIGNED,
} from "../../../common/api_schema/order_schema";

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "green",
  },
});

export const OrdersInProgressTabs = () => {
  const [value, setValue] = useState("all");
  const { unassignedCount, inProgressCount, fetchOrdersCount } =
    useOrderContext();
  const { user, isLoading } = useAuth0();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log("OrdersInProgressTabs useEffect, fetch order counts ");
    fetchOrdersCount(user.sub); // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const totalCount = unassignedCount + inProgressCount;
  const allTabText = "All (" + totalCount.toString() + ")";
  const unassignedTabText = "Unassigned (" + unassignedCount.toString() + ")";
  const inProgressTabText = "In progress (" + inProgressCount.toString() + ")";

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            TabIndicatorProps={{
              style: { background: "var(--clr-green-light)" },
            }}
          >
            <StyledTab label={allTabText} value="all" />
            <StyledTab label={unassignedTabText} value="unassigned" />
            <StyledTab label={inProgressTabText} value="in_progress" />
          </TabList>
        </Box>
        <TabPanel value="all">
          {totalCount > 0 ? (
            <OrderTable status={-1} count={totalCount} />
          ) : (
            <OrdersEmptyPage />
          )}
        </TabPanel>
        <TabPanel value="unassigned">
          {unassignedCount > 0 ? (
            <OrderTable status={ORDER_UNASSIGNED} count={unassignedCount} />
          ) : (
            <OrdersEmptyPage />
          )}
        </TabPanel>
        <TabPanel value="in_progress">
          {inProgressCount > 0 ? (
            <OrderTable status={ORDER_IN_PROGRESS} count={inProgressCount} />
          ) : (
            <OrdersEmptyPage />
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default OrdersInProgressTabs;
