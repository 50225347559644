import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import { Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import { actionsIcon } from "../../../assets";
import { axiosPutWithAuthHeader } from "../../../common/axios_utils";
import {
  DialogButton,
  DialogText,
} from "../../../common/dialog/DialogComponents";
import { useCompanyContext } from "../../context/company_context";
import { SnackbarFailure } from "../../../common/snackbar/SnackbarHelper";
import { APPLICATION_COMPLETED_FOR_DISPUTE } from "../../../common/api_schema/application_schema";
import { handleDownloadFromS3 } from "../../../common/upload/s3_handler";

const CompanyActionMenu = ({ company }) => {
  const [anchorEl, setAnchorEl] = useState();
  const { user, getAccessTokenSilently } = useAuth0();
  const { fetchCompaniesInfo } = useCompanyContext();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ReportProblemDialog = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [failurePopupOpen, setFailurePopupOpen] = useState(false);

    const reportProblem = async () => {
      const responseData = await axiosPutWithAuthHeader(
        `/client/create-dispute/${user.sub}/application=${company.applicationId}/order=${company.orderId}/`,
        {},
        getAccessTokenSilently,
        "report problem",
      );
      setDialogOpen(false);
      if (responseData.success === 1) {
        // success
        fetchCompaniesInfo(company.orderId, 1, user.sub, null);
      } else {
        // fail
        setFailurePopupOpen(true);
      }
    };

    const handleOpenDialog = () => {
      setDialogOpen(true);
    };

    const FailureWarning = () => {
      return (
        <SnackbarFailure
          open={failurePopupOpen}
          setOpen={setFailurePopupOpen}
          message={`We noticed that you have reported an unusually large amount of problematic applications. Please directly reach out to our customer support at xxx.applieronline.com`}
        />
      );
    };

    return (
      <>
        <FailureWarning />
        <MenuItem
          sx={{ fontSize: 15, fontWeight: 200 }}
          onClick={handleOpenDialog}
        >
          Report a problem
        </MenuItem>

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle sx={{ fontFamily: "Mulish" }}>
            Report a problem
          </DialogTitle>
          <DialogText>
            If you have not received email notification from{" "}
            {company.companyName}, you can report the problem there. Our
            operator will submit another replacement application as soon as
            possible.
          </DialogText>
          <DialogActions>
            <DialogButton onClick={() => setDialogOpen(false)}>
              CANCEL
            </DialogButton>
            <DialogButton onClick={reportProblem} autoFocus>
              CONFIRM
            </DialogButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const DownloadScreenshotMenuItem = () => {
    const downloadScreenshot = async () => {
      const extra = JSON.parse(company.extra);
      const s3ScreenshotUrl = extra.s3ScreenshotUrl;
      await handleDownloadFromS3(s3ScreenshotUrl, null, getAccessTokenSilently);
    };
    return (
      <MenuItem
        sx={{ fontSize: 15, fontWeight: 200 }}
        onClick={downloadScreenshot}
      >
        Download screenshot
      </MenuItem>
    );
  };

  return (
    <div>
      {/* ------------ action button icon ------------ */}
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ActionsButtonImage src={actionsIcon} />
      </Button>

      {/* ------------ Menu items ------------ */}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {company.status !== APPLICATION_COMPLETED_FOR_DISPUTE ? (
          <ReportProblemDialog />
        ) : (
          <DownloadScreenshotMenuItem />
        )}
        <MenuItem sx={{ fontSize: 15, fontWeight: 200 }} onClick={handleClose}>
          Close
        </MenuItem>
      </Menu>
    </div>
  );
};

const ActionsButtonImage = styled.img`
  height: 4px;
  width: 20px;
`;

export default CompanyActionMenu;
