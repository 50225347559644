import React, { useReducer, useContext } from "react";
import reducer from "../reducers/order_reducers";
import { useAuth0 } from "@auth0/auth0-react";

import {
  ORDER_GET_COUNT_SUCCESS,
  ORDER_GET_DETAIL_SUCCESS,
  ORDER_GET_INFO_SUCCESS,
} from "../actions";
import {
  ORDER_PAGE_SIZE,
  OrderCountsResponse,
  OrderDetail,
} from "../../common/api_schema/order_schema";
import { axiosGetWithAuthHeader } from "../../common/axios_utils";

export const initialState = {
  orders: [],
  selectedOrder: {
    customerId: "",
    orderId: "",
    positionName: "",
    createTime: "",
    appliedCount: 0,
    resolvedCount: 0,
  },
  unassignedCount: -1,
  inProgressCount: -1,
  completedCount: -1,
  inDisputeCount: -1,
  pastCount: -1,
};

const OrderContext = React.createContext();

export const OrderProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchOrdersInfo = async (status, sortBy, startPage, userId) => {
    const responseData = await axiosGetWithAuthHeader(
      {
        status: status,
        orderBy: sortBy,
        startPage: startPage,
        pageSize: ORDER_PAGE_SIZE,
        keyword: "",
        operatorId: "",
      },
      `/customer/order/${userId}/`,
      getAccessTokenSilently,
      "fetchOrdersInfo",
    );
    console.log("fetchOrdersInfo, responseData", responseData);
    const ordersData = responseData.map((d) => OrderDetail.fromPayload(d));
    console.log("ordersData", ordersData);
    dispatch({ type: ORDER_GET_INFO_SUCCESS, ordersInfo: ordersData });
  };

  const fetchOrderDetail = async (orderId, userId) => {
    const responseData = await axiosGetWithAuthHeader(
      { orderId: orderId },
      `/customer/orderdetail/${userId}/`,
      getAccessTokenSilently,
      "fetchOrderDetail",
    );

    dispatch({ type: ORDER_GET_DETAIL_SUCCESS, orderInfo: responseData });
  };

  const fetchOrdersCount = async (userId) => {
    const responseData = await axiosGetWithAuthHeader(
      {},
      `customer/ordercount/user=${userId}/`,
      getAccessTokenSilently,
      "fetchOrdersCount",
    );

    const orderCountsData = OrderCountsResponse.fromPayload(responseData);
    dispatch({ type: ORDER_GET_COUNT_SUCCESS, ordersCount: orderCountsData });
    console.log("orders state", state);
  };

  return (
    <OrderContext.Provider
      value={{
        ...state,
        fetchOrdersInfo,
        fetchOrdersCount,
        fetchOrderDetail,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  return useContext(OrderContext);
};
