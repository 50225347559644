import styled from "styled-components";
import { Link } from "react-router-dom";

const StartApplyButton = ({ hasBorder }) => {
  // const { loginWithRedirect } = useAuth0();

  if (hasBorder) {
    return (
      <Link to="/client/login/">
        <WrapperWithBorder>Start applying</WrapperWithBorder>
      </Link>
    );
  } else {
    return (
      <Link to="/client/login/">
        <Wrapper>Start applying</Wrapper>
      </Link>
    );
  }
};

const WrapperWithBorder = styled.button`
  height: 50px;
  width: 180px;

  align-items: center;

  font-size: 20px;
  background-color: var(--clr-grey-white);
  border-radius: 40px;
  font-weight: 200;

  border-color: black;
  border-color: var(--clr-green-light);
  border-style: solid;
`;

const Wrapper = styled.button`
  height: 60px;
  width: 220px;

  align-items: center;

  font-size: 23px;
  background-color: var(--clr-green-light);
  border-radius: 40px;
  font-weight: 200;
`;

export default StartApplyButton;
