import styled from "styled-components";
import StartApplyButton from "./StartApplyButton";
import { logo, homeBackground, homeOrder } from "../../../assets";

const HomePageWelcome = () => {
  return (
    <Wrapper>
      <WelcomeWrapper>
        <WelcomeFirstLine>Automate your job applications</WelcomeFirstLine>
        <WelcomeSecondLine>
          <div>
            <WelcomeSecondLineText>with</WelcomeSecondLineText>
            <LogoImage src={logo} />
          </div>
        </WelcomeSecondLine>
        <WelcomeSubtext>
          We will submit your job applications for you!
        </WelcomeSubtext>
        <StartApplyButtonWrapper>
          <StartApplyButton hasBorder={false} />
        </StartApplyButtonWrapper>
        <WelcomImageWrapper>
          <HomeOrderWelcomeImage src={homeOrder} />
        </WelcomImageWrapper>
      </WelcomeWrapper>
    </Wrapper>
  );
};

const LogoImage = styled.img`
  float: left;
  margin-left: 40px;
  margin-top: -30px;
  width: 300px;
`;

const HomeOrderWelcomeImage = styled.img`
  width: 1000px;
`;

const WelcomImageWrapper = styled.div`
  display: flex;
  margin-top: 70px;
  justify-content: center;
  align-items: center;
`;

const WelcomeFirstLine = styled.div`
  font-size: 70px;
  text-align: center;
  font-weight: 200;
`;

const WelcomeSecondLine = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  font-weight: 200;
`;

const WelcomeSecondLineText = styled.div`
  font-size: 70px;
  float: left;
`;

const WelcomeSubtext = styled.div`
  margin-top: 50px;
  font-size: 25px;
  color: var(--clr-grey-dark);
  text-align: center;
  font-weight: 200;
`;

const Wrapper = styled.div`
  background-color: var(--clr-grey-white);
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
`;

const StartApplyButtonWrapper = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const WelcomeWrapper = styled.div`
  background-image: url(${homeBackground});
  background-size: contain;
  backgroundposition: "center";
`;

export default HomePageWelcome;
