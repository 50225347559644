import {
  ORDER_GET_INFO_SUCCESS,
  ORDER_GET_COUNT_SUCCESS,
  ORDER_GET_DETAIL_SUCCESS,
} from "../actions";

const order_reducer = (state, action) => {
  if (action.type === ORDER_GET_INFO_SUCCESS) {
    console.log("Get orders info success");
    return {
      ...state,
      orders: action.ordersInfo,
    };
  }

  if (action.type === ORDER_GET_COUNT_SUCCESS) {
    console.log("Get orders count success");
    const c = action.ordersCount;
    return {
      ...state,
      unassignedCount: c.unassignedCount,
      inProgressCount: c.inProgressCount,
      completedCount: c.completedCount,
      pastCount: c.pastCount,
      inDisputeCount: c.inDisputeCount,
    };
  }

  if (action.type === ORDER_GET_DETAIL_SUCCESS) {
    console.log(
      "Get order detail success, set selectedOrder",
      action.orderInfo,
    );
    return {
      ...state,
      selectedOrder: action.orderInfo,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default order_reducer;
