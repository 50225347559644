export const APPLICATION_PAGE_SIZE = 15;
export const APPLICATION_SORT_BY_CREAT_TIME = 0;
export const APPLICATION_SORT_BY_POSITION_NAME = 1;

export const APPLICATION_COMPLETE = 0;
export const APPLICATION_DISPUTED = 1;
export const APPLICATION_DISPUTE_RESOLVED = 2;
export const APPLICATION_COMPLETED_FOR_DISPUTE = 3;

export class CreateApplicationRequest {
  constructor(customerId, orderId, companyName, positionName, positionLink) {
    this.customerId = customerId;
    this.orderId = orderId;
    this.companyName = companyName;
    this.positionName = positionName;
    this.positionLink = positionLink;
  }
}

export class EditApplicationRequest {
  constructor(
    customerId,
    applicationId,
    companyName,
    positionName,
    positionLink,
  ) {
    this.customerId = customerId;
    this.applicationId = applicationId;
    this.companyName = companyName;
    this.positionName = positionName;
    this.positionLink = positionLink;
  }
}

export class CheckApplicationExistsRequest {
  constructor(customerId, applicationId, companyName) {
    this.customerId = customerId;
    this.applicationId = applicationId;
    this.companyName = companyName;
  }
}

export class ResolveDisputeApplicationRequest {
  constructor(
    disputedApplicationId,
    disputedCompanyName,
    orderId,
    customerId,
    newCompanyName,
    newPositionName,
    newPositionLink,
    screenshotS3Url,
  ) {
    this.disputedApplicationId = disputedApplicationId;
    this.disputedCompanyName = disputedCompanyName;
    this.orderId = orderId;
    this.customerId = customerId;
    this.newCompanyName = newCompanyName;
    this.newPositionName = newPositionName;
    this.newPositionLink = newPositionLink;
    this.screenshotS3Url = screenshotS3Url;
  }
}
