import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ClientLoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect(); // eslint-disable-next-line
  }, []);
  return <div></div>;
};

export default ClientLoginPage;
