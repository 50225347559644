import React, { useReducer, useContext } from "react";
import reducer from "../reducers/sidebar_reducers";
import {
  SIDEBAR_SELECT_PROFILE,
  SIDEBAR_SELECT_ONGOING_ORDERS,
  SIDEBAR_SELECT_PAST_ORDERS,
  SIDEBAR_SELECT_COMPANIES,
} from "../actions";

const initialState = {
  isProfileSelected: false,
  isOngoingOrdersSelected: true,
  isPastOrdersSelected: false,
  isCompaniesSelected: false,
};

const SidebarContext = React.createContext();

export const SidebarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const selectProfile = () => {
    dispatch({ type: SIDEBAR_SELECT_PROFILE });
  };

  const selectOngoingOrders = () => {
    dispatch({ type: SIDEBAR_SELECT_ONGOING_ORDERS });
  };

  const selectPastOrders = () => {
    dispatch({ type: SIDEBAR_SELECT_PAST_ORDERS });
  };

  const selectCompanies = () => {
    dispatch({ type: SIDEBAR_SELECT_COMPANIES });
  };

  return (
    <SidebarContext.Provider
      value={{
        ...state,
        selectProfile,
        selectOngoingOrders,
        selectPastOrders,
        selectCompanies,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  return useContext(SidebarContext);
};
