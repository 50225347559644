import styled from "styled-components";

const HomePageAboutUs = () => {
  return (
    <Wrapper>
      <ExplanationWrapper>
        <ExplanationTitle>
          We will submit your job applications for you!
        </ExplanationTitle>
        <ExplanationText>
          In today’s job market, it’s not uncommon for people to apply to
          hundreds of job postings before getting an interview. This is tedious
          and time-consuming, so we are here to automate this process for you!
        </ExplanationText>
        <ExplanationGroup style={{ listStyleType: "decimal" }}>
          <ExplanationPoint>
            Fill out the questionnaire and tell us how you want your
            applications to be submitted.
          </ExplanationPoint>
          <ExplanationPoint>
            A dedicated operator will be assigned to you in 2 days and start
            submitting applications based on the information you provided.
          </ExplanationPoint>
          <ExplanationPoint>
            Monitor application progress on dashboard and check which companies
            you have applied.
          </ExplanationPoint>
          <ExplanationPoint>
            Wait for your email inbox to be filled with “Application
            received/submitted” emails!
          </ExplanationPoint>
        </ExplanationGroup>
      </ExplanationWrapper>
    </Wrapper>
  );
};

const ExplanationTitle = styled.div`
  font-size: 40px;
  text-align: center;
  margin-top: 150px;
  font-weight: 200;
`;

const ExplanationText = styled.ol`
  font-weight: 200;
  margin-top: 100px;
`;

const ExplanationGroup = styled.ol`
  font-weight: 200;
  margin-top: 50px;
`;

const ExplanationPoint = styled.li`
  margin-top: 30px;
`;

const Wrapper = styled.div`
  background-color: var(--clr-grey-white);
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
`;

const ExplanationWrapper = styled.div`
  background-color: var(--clr-grey-white);
  margin-left: 200px;
  margin-right: 200px;
`;

export default HomePageAboutUs;
