// Sidebar
export const SIDEBAR_SELECT_PROFILE = "SIDEBAR_SELECT_PROFILE";
export const SIDEBAR_SELECT_ONGOING_ORDERS = "SIDEBAR_SELECT_ONGOING_ORDERS";
export const SIDEBAR_SELECT_PAST_ORDERS = "SIDEBAR_SELECT_PAST_ORDERS";
export const SIDEBAR_SELECT_COMPANIES = "SIDEBAR_SELECT_COMPANIES";

// Template
export const TEMPLATE_POPPUP_SAVE_BEGIN = "TEMPLATE_POPPUP_SAVE_BEGIN";
export const TEMPLATE_POPPUP_SAVE_SUCCESS = "TEMPLATE_POPPUP_SAVE_SUCCESS";
export const TEMPLATE_POPPUP_SAVE_ERROR = "TEMPLATE_POPPUP_SAVE_ERROR";

export const TEMPLATE_POPPUP_CREATE = "TEMPLATE_POPPUP_CREATE";
export const TEMPLATE_POPPUP_EDIT = "TEMPLATE_POPPUP_EDIT";
export const TEMPLATE_POPPUP_VIEW = "TEMPLATE_POPPUP_VIEW";
export const TEMPLATE_POPPUP_FETCH = "TEMPLATE_POPPUP_FETCH";
export const TEMPLATE_POPPUP_CLOSE = "TEMPLATE_POPPUP_CLOSE";
export const TEMPLATE_POPPUP_UPLOAD_RESUME = "TEMPLATE_POPPUP_UPLOAD_RESUME";
export const TEMPLATE_POPPUP_INCREMENT_STEP = "TEMPLATE_POPPUP_INCREMENT_STEP";
export const TEMPLATE_POPPUP_DECREMENT_STEP = "TEMPLATE_POPPUP_DECREMENT_STEP";
export const TEMPLATE_POPPUP_UPDATE_QUESTION_ANSWER =
  "TEMPLATE_POPPUP_UPDATE_QUESTION_ANSWER";

// User info
export const USER_GET_ACCOUNT_INFO_BEGIN = "USER_GET_ACCOUNT_INFO_BEGIN";
export const USER_GET_ACCOUNT_INFO_SUCCESS = "USER_GET_ACCOUNT_INFO_SUCCESS";
export const USER_GET_ACCOUNT_INFO_ERROR = "USER_GET_ACCOUNT_INFO_ERROR";

// New order
export const NEW_ORDER_POPPUP_OPEN = "NEW_ORDER_POPPUP_OPEN";
export const NEW_ORDER_POPPUP_CLOSE = "NEW_ORDER_POPPUP_CLOSE";
export const NEW_ORDER_POPPUP_CHOOSE_TEMPLATE =
  "NEW_ORDER_POPPUP_CHOOSE_TEMPLATE";
export const NEW_ORDER_POPPUP_CHOOSE_PLAN = "NEW_ORDER_POPPUP_CHOOSE_PLAN";
export const NEW_ORDER_POPPUP_SUBMIT = "NEW_ORDER_POPPUP_SUBMIT";
export const NEW_ORDER_INCREMENT_STEP = "NEW_ORDER_INCREMENT_STEP";
export const NEW_ORDER_DECREMENT_STEP = "NEW_ORDER_DECREMENT_STEP";

// Orders
export const ORDER_GET_INFO_SUCCESS = "ORDER_GET_INFO_SUCCESS";
export const ORDER_GET_COUNT_SUCCESS = "ORDER_GET_COUNT_SUCCESS";
export const ORDER_GET_DETAIL_SUCCESS = "ORDER_GET_DETAIL_SUCCESS";

// Companies
export const COMPANY_GET_INFO_SUCCESS = "COMPANY_GET_INFO_SUCCESS";
export const COMPANY_SET_SEARCH_KEYWORD = "COMPANY_SET_SEARCH_KEYWORD";
export const COMPANY_SET_PAGE = "COMPANY_SET_PAGE";
export const COMPANY_SET_SORT_BY = "COMPANY_SET_SORT_BY";
