import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled as MuiStyled } from "@mui/material/styles";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { OrderProgress } from "../../../common/components/OrderProgress";
import OrderActionMenu from "./OrderActionMenu";
import { useOrderContext } from "../../context/order_context";
import { TableRowCell, TableHeaderCell } from "../../../common/table_styles";
import {
  ORDER_IN_DISPUTE,
  ORDER_PAGE_SIZE,
  ORDER_PAST,
  ORDER_SORT_BY_CREAT_TIME,
  intToStatus,
} from "../../../common/api_schema/order_schema";
import { getNumberOfPages } from "../../../common/utils";

const ProgressHeaderCell = ({ status }) => {
  if (status === ORDER_IN_DISPUTE) {
    return <TableHeaderCell>Remaining issues</TableHeaderCell>;
  } else {
    return <TableHeaderCell>Progress</TableHeaderCell>;
  }
};

const ProgressTableCell = ({ status, order }) => {
  if (status === ORDER_IN_DISPUTE) {
    return (
      <TableRowCell>{order.disputedCount - order.resolvedCount}</TableRowCell>
    );
  } else {
    return <TableRowCell>{<OrderProgress order={order} />}</TableRowCell>;
  }
};

const AppliedCountTableCell = ({ status, order }) => {
  if (status === ORDER_IN_DISPUTE) {
    return "";
  } else {
    return <TableRowCell>{order.appliedCount}</TableRowCell>;
  }
};

const AppliedCountHeaderCell = ({ status }) => {
  if (status === ORDER_IN_DISPUTE) {
    return "";
  } else {
    return <TableHeaderCell>Applied</TableHeaderCell>;
  }
};

export const OrderTable = ({ status, count }) => {
  const { orders, fetchOrdersInfo } = useOrderContext();
  const [page, setPage] = useState(1);
  const { user } = useAuth0();

  const handlePagination = (event, value) => {
    console.log("Order table pagination, fetch page ", value);
    fetchOrdersInfo(status, ORDER_SORT_BY_CREAT_TIME, value, user.sub, true); // eslint-disable-next-line
    setPage(value);
  };

  useEffect(() => {
    console.log("Order table useEffect, fetch status ", status);
    fetchOrdersInfo(status, ORDER_SORT_BY_CREAT_TIME, 1, user.sub, true); // eslint-disable-next-line
  }, []);

  return (
    <TableContainer sx={{ height: "1300px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="table-header">
            <TableHeaderCell>Position </TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Date of creation</TableHeaderCell>
            <ProgressHeaderCell status={status} />
            <AppliedCountHeaderCell status={status} />
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row) => (
            <TableRow key={row.orderId} sx={{ border: 0 }}>
              <TableRowCell>
                <div>
                  <PositionName>{row.positionName} </PositionName>
                  <OrderId>Order id: {row.orderId}</OrderId>
                </div>
              </TableRowCell>
              <TableRowCell>{intToStatus(row.status)}</TableRowCell>
              <TableRowCell>{row.createTime}</TableRowCell>
              <ProgressTableCell status={status} order={row} />
              <AppliedCountTableCell status={status} order={row} />
              <TableRowCell>
                {status !== ORDER_PAST ? <OrderActionMenu order={row} /> : ""}
              </TableRowCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StyledPagination
        count={getNumberOfPages(count, ORDER_PAGE_SIZE)}
        size="large"
        page={page}
        onChange={handlePagination}
        sx={{ marginTop: "20px", marginLeft: "30%", marginRight: "auto" }}
      />
    </TableContainer>
  );
};

const StyledPagination = MuiStyled(Pagination)({
  "& .MuiPaginationItem-page": {
    fontFamily: "Mulish",
  },
  "& .Mui-selected": {
    color: "var(--clr-green-light)",
    border: 1,
    borderColor: "var(--clr-green-light)",
    borderStyle: "solid",
    backgroundColor: "var(--clr-grey-white)",
  },
});

const PositionName = styled.div`
  font-size: 16px;
  font-weight: 200;
`;

const OrderId = styled.div`
  font-size: 13px;
  font-weight: 200;
  color: var(--clr-grey-dark);
`;
