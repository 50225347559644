import {
  COMPANY_GET_INFO_SUCCESS,
  COMPANY_SET_PAGE,
  COMPANY_SET_SEARCH_KEYWORD,
  COMPANY_SET_SORT_BY,
  COMPANY_UPDATE_COMPANY_NAME,
  COMPANY_UPDATE_LINK,
  COMPANY_UPDATE_POSITION,
  COMPANY_UPDATE_CHECK_STATUS,
  COMPANY_START_EDIT,
  COMPANY_CLEAR_EDITING_STATES,
} from "../actions";
import { initialState } from "../context/company_context";

const company_reducer = (state, action) => {
  if (action.type === COMPANY_GET_INFO_SUCCESS) {
    console.log("Get companies info success");
    return {
      ...state,
      companies: action.payload.applications,
      totalCount: action.payload.totalCount,
    };
  }

  if (action.type === COMPANY_SET_SEARCH_KEYWORD) {
    console.log("Set companies search query");
    return {
      ...state,
      searchQuery: { ...state.searchQuery, keyword: action.keyWord },
    };
  }

  if (action.type === COMPANY_SET_PAGE) {
    console.log("Set companies search query page");
    return {
      ...state,
      searchQuery: { ...state.searchQuery, page: action.page },
    };
  }

  if (action.type === COMPANY_SET_SORT_BY) {
    console.log("Set companies sort by");
    return {
      ...state,
      searchQuery: { ...state.searchQuery, sortBy: action.sortBy },
    };
  }

  if (action.type === COMPANY_UPDATE_COMPANY_NAME) {
    console.log("Update company name");
    return {
      ...state,
      editingCompanyName: action.companyName,
      checkAppliedStatus: 0,
      pendingConfirmationApplicationNames: [],
    };
  }

  if (action.type === COMPANY_UPDATE_LINK) {
    console.log("Update company link");
    return {
      ...state,
      editingPositionLink: action.link,
    };
  }

  if (action.type === COMPANY_UPDATE_POSITION) {
    console.log("Update company position");
    return {
      ...state,
      editingPositionName: action.position,
    };
  }

  if (action.type === COMPANY_CLEAR_EDITING_STATES) {
    console.log("Clear editing states");
    return {
      ...state,
      editingPositionName: "",
      editingCompanyName: "",
      editingPositionLink: "",
      editingApplicationId: "",
      checkAppliedStatus: 0,
      pendingConfirmationCompanyNames: [],
    };
  }

  if (action.type === COMPANY_UPDATE_CHECK_STATUS) {
    console.log("Update application check status ", action.checkStatus);
    return {
      ...state,
      checkAppliedStatus: action.checkStatus,
      pendingConfirmationCompanyNames: action.companyNames,
      searchQuery: initialState.searchQuery,
    };
  }

  if (action.type === COMPANY_START_EDIT) {
    console.log("Start editing company");
    return {
      ...state,
      editingCompanyName: action.companyName,
      editingPositionLink: action.positionLink,
      editingPositionName: action.positionName,
      editingApplicationId: action.applicationId,
      checkAppliedStatus: 0,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default company_reducer;
