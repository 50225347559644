import styled from "styled-components";

import { useNewOrderContext } from "../../context/new_order_context";
import { planChoices } from "../../../common/questions/questions";

const NewOrderPaymentSummary = () => {
  const { planChoice } = useNewOrderContext();

  return (
    <PopupContent>
      <GreySeparator />
      <CostRow>
        <CostRowLeft>Subtotal</CostRowLeft>
        <CostRowRight>${planChoices[planChoice].price}</CostRowRight>
      </CostRow>
      <CostRow>
        <CostRowLeft>Sales tax</CostRowLeft>
        <CostRowRight>$1.5</CostRowRight>
      </CostRow>
      <FinalCostRow>
        <CostRowLeft>Total</CostRowLeft>
        <CostRowRight>$11.5</CostRowRight>
      </FinalCostRow>
      <GreySeparatorBottom />
    </PopupContent>
  );
};

const PopupContent = styled.div`
  margin-left: 35px;
  margin-top: 40px;
  overflow-y: scroll;
`;

const GreySeparator = styled.div`
  width: 100%;
  margin-top: 30px;
  border-top-style: solid;
  border-top-color: var(--clr-grey-dark);
`;

const GreySeparatorBottom = styled.div`
  width: 100%;
  margin-bottom: 50px;
  border-top-style: solid;
  border-top-color: var(--clr-grey-dark);
`;

const CostRow = styled.div`
  width: 100%;
  display: inline-block;
  margin-top: 45px;
  color: var(--clr-grey-dark);
`;

const FinalCostRow = styled.div`
  width: 100%;
  display: inline-block;
  margin-top: 45px;
  margin-bottom: 45px;
`;

const CostRowLeft = styled.div`
  text-align: left;
  margin-left: 30px;
`;

const CostRowRight = styled.div`
  margin-top: -30px;
  margin-right: 40px;
  text-align: right;
`;

export default NewOrderPaymentSummary;
