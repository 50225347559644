import styled from "styled-components";

import CircularProgress from "@mui/material/CircularProgress";

const LoadingPage = () => {
  return (
    <Wrapper>
      <CircularProgress
        style={{
          width: "100px",
          height: "100px",
          backgroundColor: "transparent",
          color: "#45ae21",
          marginTop: "-10%",
        }}
      />
      <LoadingTextWrapper> Loading ...</LoadingTextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
`;

const LoadingTextWrapper = styled.div`
  margin-top: 50px;
  font-size: 25px;
`;

export default LoadingPage;
