import { useParams } from "react-router-dom";
import { useEffect } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

import { CompaniesTable } from "../Company/CompaniesTable";
import { useOrderContext } from "../../context/order_context";
import { useCompanyContext } from "../../context/company_context";
import { OrderDetailHeaderTable } from "../../../common/components/OrderDetailHeaderTable";
import { useTemplateContext } from "../../context/template_context";
import OrderDetailHeader from "../../../common/components/OrderDetailHeader";
import CompaniesSearchBar from "../../../common/components/CompaniesSearchBar";

const OrderDetail = () => {
  const { id } = useParams();
  const { fetchOrderDetail, selectedOrder } = useOrderContext();
  const { user, isLoading } = useAuth0();
  const { triggerTemplatePopupViewOnly } = useTemplateContext();
  const { updateSearchKeyword, fetchCompaniesInfo, setPage, setSortBy } =
    useCompanyContext();

  // Use useEffect to set selectedOrder here.
  useEffect(() => {
    console.log("OrderDetail useEffect, orderId", id);
    fetchOrderDetail(id, user.sub); // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const onClickViewTemplate = () => {
    triggerTemplatePopupViewOnly(selectedOrder.templateSnapshot);
  };

  const handleOnClickSearch = () => {
    fetchCompaniesInfo(id, 1, user.sub, null);
    setPage(1);
  };

  return (
    <Wrapper>
      <OrderDetailHeader
        selectedOrder={selectedOrder}
        backLink={"/client/ongoing-orders/"}
      />
      <OrderDetailHeaderTable
        orderData={selectedOrder}
        onClickViewTemplate={onClickViewTemplate}
      />
      <CompaniesSearchBar
        orderId={id}
        updateKeyword={updateSearchKeyword}
        handleOnClickSearch={handleOnClickSearch}
        setSortBy={setSortBy}
        userId={user.sub}
      />
      <CompaniesTable orderId={id} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-left: 30px;
`;

export default OrderDetail;
