import styled from "styled-components";
import { useEffect } from "react";

import { requiredQuestions } from "./questions";
import Questions from "./TemplateQuestions";

const TemplateRequiredQuestions = ({
  readOnly,
  questions,
  onUpdateQuestion,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PopupContent>
      {readOnly ? (
        ""
      ) : (
        <Subheader>
          These questions below are often required by the resume submission
          process of job postings. Our operators will use the answers in this
          questionaire when submitting resumes on your behalf.
        </Subheader>
      )}
      <Questions
        templateQuestions={requiredQuestions}
        readOnly={readOnly}
        questions={questions}
        onUpdateQuestion={onUpdateQuestion}
      />
    </PopupContent>
  );
};

const PopupContent = styled.div`
  margin-left: 35px;
  overflow-y: scroll;
`;

const Subheader = styled.div`
  margin-top: 25px;
  font-size: 18px;
`;

export default TemplateRequiredQuestions;
