import styled from "styled-components";
import { useRef } from "react";

import HomePageHeader from "../components/Home/HomePageHeader";
import HomePageWelcome from "../components/Home/HomePageWelcome";
import HomePageGuide from "../components/Home/HomePageUserGuide";
import HomePagePricing from "../components/Home/HomePagePricing";
import HomePageFAQ from "../components/Home/HomePageFAQ";
import HomePageFooter from "../components/Home/HomePageFooter";
import HomePageAboutUs from "../components/Home/HomePageAboutUs";

const HomePage = () => {
  const aboutUsRef = useRef(null);
  const guideRef = useRef(null);
  const pricingRef = useRef(null);
  const faqRef = useRef(null);

  const scrollToAboutUs = () => {
    aboutUsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToGuide = () => {
    guideRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToPricing = () => {
    pricingRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFaq = () => {
    faqRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Wrapper>
      <HomePageHeader
        scrollToAboutUs={scrollToAboutUs}
        scrollToGuide={scrollToGuide}
        scrollToPricing={scrollToPricing}
        scrollToFaq={scrollToFaq}
      />
      <HomePageWelcome />
      <div ref={aboutUsRef}>
        <HomePageAboutUs />
      </div>
      <div ref={guideRef}>
        <HomePageGuide />
      </div>
      <div ref={pricingRef}>
        <HomePagePricing />
      </div>
      <div ref={faqRef}>
        <HomePageFAQ />
      </div>
      <HomePageFooter />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: var(--clr-grey-white);
  width: 100%;
  height: 100%;
  min-width: 1280px;
  overflow-y: auto;
`;

export default HomePage;
