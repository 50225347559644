import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled as MuiStyled } from "@mui/material/styles";
import { useEffect } from "react";
import Link from "@mui/material/Link";
import { useAuth0 } from "@auth0/auth0-react";

import { useCompanyContext } from "../../context/company_context";
import { TableRowCell, TableHeaderCell } from "../../../common/table_styles";
import {
  getNumberOfPages,
  parseISOLocal,
  truncateText,
} from "../../../common/utils";
import {
  APPLICATION_DISPUTED,
  APPLICATION_DISPUTE_RESOLVED,
  APPLICATION_PAGE_SIZE,
} from "../../../common/api_schema/application_schema";
import CompanyActionMenu from "./CompanyActionsMenu";
import { useOrderContext } from "../../context/order_context";
import {
  ORDER_IN_DISPUTE,
  ORDER_PAST,
  ORDER_SUBMITTED,
} from "../../../common/api_schema/order_schema";
import { useSidebarContext } from "../../context/sidebar_context";

const showActionMenu = (selectedOrder, applicaiton) => {
  return (
    (selectedOrder.status === ORDER_IN_DISPUTE ||
      selectedOrder.status === ORDER_SUBMITTED) &&
    applicaiton.status !== APPLICATION_DISPUTED &&
    applicaiton.status !== APPLICATION_DISPUTE_RESOLVED
  );
};

export const CompaniesTable = ({ orderId }) => {
  const { companies, fetchCompaniesInfo, totalCount, page, setPage } =
    useCompanyContext();
  const { user } = useAuth0();
  const { selectedOrder } = useOrderContext();
  const { selectOngoingOrders, selectPastOrders } = useSidebarContext();

  useEffect(() => {
    console.log("Company table useEffect, orderId", orderId);
    if (
      selectedOrder.status === ORDER_IN_DISPUTE ||
      selectedOrder.status === ORDER_SUBMITTED ||
      selectedOrder.status === ORDER_PAST
    ) {
      selectPastOrders();
    } else {
      selectOngoingOrders();
    }

    fetchCompaniesInfo(orderId, 1, user.sub, null); // eslint-disable-next-line
  }, [selectedOrder]);

  const handlePagination = (_, value) => {
    console.log("Company table pagination, fetch page ", value, orderId);
    fetchCompaniesInfo(orderId, value, user.sub, null); // eslint-disable-next-line
    setPage(value);
  };

  return (
    <TableContainer sx={{ height: "1300px" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="table-header">
            <TableHeaderCell> Company </TableHeaderCell>
            <TableHeaderCell> Position </TableHeaderCell>
            <TableHeaderCell>Link</TableHeaderCell>
            <TableHeaderCell>Date of application</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map((row) => (
            <TableRow key={row.applicationId} sx={{ border: 0 }}>
              <TableRowCell data={row} isFirstColumn={true}>
                {row.companyName}
              </TableRowCell>
              <TableRowCell TableRowCell data={row}>
                {row.positionName}
              </TableRowCell>
              <TableRowCell TableRowCell data={row}>
                <Link
                  sx={{ fontSize: 15, fontWeight: 200, color: "black" }}
                  href={
                    row.positionLink.startsWith("https")
                      ? row.positionLink
                      : `https://${row.positionLink}`
                  }
                >
                  {truncateText(row.positionLink)}
                </Link>
              </TableRowCell>
              <TableRowCell
                applicationInvalid={row.status === APPLICATION_DISPUTED}
              >
                {parseISOLocal(row.createTime)}
              </TableRowCell>
              <TableRowCell>
                {showActionMenu(selectedOrder, row) ? (
                  <CompanyActionMenu company={row} />
                ) : (
                  ""
                )}
              </TableRowCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StyledPagination
        count={getNumberOfPages(totalCount, APPLICATION_PAGE_SIZE)}
        size="large"
        page={page}
        onChange={handlePagination}
        sx={{ marginTop: "20px", marginLeft: "30%", marginRight: "auto" }}
      />
    </TableContainer>
  );
};

const StyledPagination = MuiStyled(Pagination)({
  "& .MuiPaginationItem-page": {
    fontFamily: "Mulish",
  },
  "& .Mui-selected": {
    color: "var(--clr-green-light)",
    border: 1,
    borderColor: "var(--clr-green-light)",
    borderStyle: "solid",
    backgroundColor: "var(--clr-grey-white)",
  },
});
