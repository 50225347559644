import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

const OperatorLoginRedirectPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Wrapper>
      <SignInTitle>Applier Operator Sign In </SignInTitle>
      <WrapperWithBorder onClick={() => loginWithRedirect()}>
        Sign in
      </WrapperWithBorder>
    </Wrapper>
  );
};

const WrapperWithBorder = styled.button`
  height: 50px;
  width: 180px;

  align-items: center;

  font-size: 20px;
  border-radius: 40px;
  font-weight: 200;

  border-color: black;
  border-color: var(--clr-green-light);
  border-style: solid;
`;

const SignInTitle = styled.div`
  font-size: 40px;
  margin-bottom: 150px;
  font-weight: 200;
`;

const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  height: 400px;
`;

export default OperatorLoginRedirectPage;
