// Question types
export const QUESTION_SINGLE_CHOICE = "single_choice";
export const QUESTION_MULTI_CHOICE = "multi_choice";
export const QUESTION_TEXT_RESPONSE = "text_response";

// Required question keys
export const QUESTION_JOB_TITLE = "QUESTION_JOB_TITLE";
export const QUESTION_COUNTRY = "QUESTION_COUNTRY";
export const QUESTION_LEGALLY_AUTHORIZED_TO_WORK =
  "QUESTION_LEGALLY_AUTHORIZED_TO_WORK";
export const QUESTION_EMPLOYMENT_SPONSORSHIP =
  "QUESTION_EMPLOYMENT_SPONSORSHIP";
export const QUESTION_WILLING_TO_RELOCATE = "QUESTION_WILLING_TO_RELOCATE";
export const QUESTION_REMOTE_ONSITE_HYBRID = "QUESTION_REMOTE_ONSITE_HYBRID";
export const QUESTION_FULLTIME_PARTTIME = "QUESTION_FULLTIME_PARTTIME";

// Optional question keys
export const QUESTION_TEMPLATE_NAME = "QUESTION_TEMPLATE_NAME";
export const QUESTION_PHONE_NUMBER = "QUESTION_PHONE_NUMBER";
export const QUESTION_EXPERIENCE_LEVEL = "QUESTION_EXPERIENCE_LEVEL";
export const QUESTION_SPONSORSHIP_DETAIL = "QUESTION_SPONSORSHIP_DETAIL";
export const QEUSTION_SALARY = "QEUSTION_SALARY";
export const QUESTION_LINKEDIN_LINK = "QUESTION_LINKEDIN_LINK";
export const QUESTION_ADDIONAL_INSTRUCTIONS = "QUESTION_ADDIONAL_INSTRUCTIONS";

export const requiredQuestions = [
  {
    type: QUESTION_TEXT_RESPONSE,
    questionKey: QUESTION_JOB_TITLE,
    questionBody: "What is the title of the position you are applying for?",
    maxResponseLength: 50,
  },
  {
    type: QUESTION_MULTI_CHOICE,
    questionKey: QUESTION_REMOTE_ONSITE_HYBRID,
    questionBody: "Do you want to apply for remote/onsite/hybrid work?",
    choices: ["Remote", "Onsite", "Hybrid"],
  },
  {
    type: QUESTION_MULTI_CHOICE,
    questionKey: QUESTION_FULLTIME_PARTTIME,
    questionBody: "Which job type(s) are you applying to?",
    choices: ["Full-time", "Part-time", "Intership", "Contract", "Other"],
  },
  {
    type: QUESTION_TEXT_RESPONSE,
    questionKey: QUESTION_COUNTRY,
    questionBody:
      "Which country/countries will the applied jobs be located in?",
    maxResponseLength: 50,
  },
  {
    type: QUESTION_SINGLE_CHOICE,
    questionKey: QUESTION_LEGALLY_AUTHORIZED_TO_WORK,
    questionBody:
      "Are you legally authorized to work in the country where the job is located without restrictions?",
    choices: ["Yes", "No"],
  },
  {
    type: QUESTION_SINGLE_CHOICE,
    questionKey: QUESTION_EMPLOYMENT_SPONSORSHIP,
    questionBody:
      "Will you now or in the future require sponsorship for employment visa status (e.g. H-1B)?",
    choices: ["Yes", "No"],
  },
  {
    type: QUESTION_SINGLE_CHOICE,
    questionKey: QUESTION_WILLING_TO_RELOCATE,
    questionBody: "Are you willing to relocate?",
    choices: ["Yes", "No"],
  },
];

export const optionalQuestions = [
  // {
  //   type: QUESTION_TEXT_RESPONSE,
  //   questionKey: QUESTION_TEMPLATE_NAME,
  //   questionBody:
  //     "Create a name for your template (by default, the template name will be your job title)",
  //   maxResponseLength: 2000,
  // },
  {
    type: QUESTION_TEXT_RESPONSE,
    questionKey: QUESTION_PHONE_NUMBER,
    questionBody: "Your phone number",
    maxResponseLength: 50,
  },
  {
    type: QUESTION_TEXT_RESPONSE,
    questionKey: QUESTION_LINKEDIN_LINK,
    questionBody: "Link to your LinkedIn profile",
    maxResponseLength: 150,
  },
  {
    type: QUESTION_MULTI_CHOICE,
    questionKey: QUESTION_EXPERIENCE_LEVEL,
    questionBody: "Which experience level are you targeting?",
    choices: [
      "Entry level",
      "Mid level",
      "Senior level",
      "Internship",
      "Principle or high",
      "Other",
    ],
  },
  {
    type: QUESTION_TEXT_RESPONSE,
    questionKey: QUESTION_SPONSORSHIP_DETAIL,
    questionBody:
      "If applicable, please provide more details on what type of sponsorship is required (e.g. H1B).",
    maxResponseLength: 50,
  },
  {
    type: QUESTION_TEXT_RESPONSE,
    questionKey: QEUSTION_SALARY,
    questionBody: "What is your desired salary range?",
    maxResponseLength: 50,
  },
  {
    type: QUESTION_TEXT_RESPONSE,
    questionKey: QUESTION_ADDIONAL_INSTRUCTIONS,
    questionBody:
      "You can provide additional instructions for our operators for submitting your resume.",
    maxResponseLength: 2000,
  },
];

// Plan choices
export const planChoices = [
  {
    planName: "Basic",
    description: "Send resumes to 50 companies",
    price: 10,
    count: 50,
  },
  {
    planName: "Standard",
    description: "Send resumes to 100 companies",
    price: 15,
    count: 100,
  },
  {
    planName: "Premium",
    description: "Send resumes to 500 companies",
    price: 50,
    count: 500,
  },
];
