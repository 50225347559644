import {
  SIDEBAR_SELECT_PROFILE,
  SIDEBAR_SELECT_ONGOING_ORDERS,
  SIDEBAR_SELECT_PAST_ORDERS,
  SIDEBAR_SELECT_COMPANIES,
} from "../actions";

const sidebar_reducer = (state, action) => {
  if (action.type === SIDEBAR_SELECT_PROFILE) {
    console.log("Sidebar select profile");
    return {
      ...state,
      isProfileSelected: true,
      isOngoingOrdersSelected: false,
      isPastOrdersSelected: false,
      isCompaniesSelected: false,
    };
  }

  if (action.type === SIDEBAR_SELECT_ONGOING_ORDERS) {
    console.log("Sidebar select ongoing orders");
    return {
      ...state,
      isProfileSelected: false,
      isOngoingOrdersSelected: true,
      isPastOrdersSelected: false,
      isCompaniesSelected: false,
    };
  }

  if (action.type === SIDEBAR_SELECT_PAST_ORDERS) {
    console.log("Sidebar select past orders");
    return {
      ...state,
      isProfileSelected: false,
      isOngoingOrdersSelected: false,
      isPastOrdersSelected: true,
      isCompaniesSelected: false,
    };
  }

  if (action.type === SIDEBAR_SELECT_COMPANIES) {
    console.log("Sidebar select past orders");
    return {
      ...state,
      isProfileSelected: false,
      isOngoingOrdersSelected: false,
      isPastOrdersSelected: false,
      isCompaniesSelected: true,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default sidebar_reducer;
