import React, { useReducer, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import reducer from "../reducers/dispute_reducers";
import {
  DISPUTE_POPUP_CLOSE,
  DISPUTE_POPUP_OPEN,
  DISPUTE_UPLOAD_IMAGE,
} from "../actions";
import { handleUploadToS3 } from "../../common/upload/s3_handler";
import {
  APPLICATION_DISPUTED,
  ResolveDisputeApplicationRequest,
} from "../../common/api_schema/application_schema";
import { useOrderContext } from "./order_context";
import { useCompanyContext } from "./company_context";
import { axiosPutWithAuthHeader } from "../../common/axios_utils";

export const initialState = {
  isOpen: false,
  image: null,
  imageMetadata: null,
  imageErrorText: "",
  disputedApplicationId: "",
  disputedCompanyName: "",
};

const DisputeContext = React.createContext();

export const DisputeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getAccessTokenSilently, user } = useAuth0();
  const { selectedOrder } = useOrderContext();
  const {
    editingCompanyName,
    editingPositionName,
    editingPositionLink,
    fetchCompaniesInfo,
    cancelEdit,
  } = useCompanyContext();

  console.log("DisputeProvider", state);

  const triggerDisputePopup = (applicationId, companyName) => {
    dispatch({
      type: DISPUTE_POPUP_OPEN,
      applicationId: applicationId,
      companyName: companyName,
    });
  };

  const closeDisputePopup = () => {
    cancelEdit();
    dispatch({ type: DISPUTE_POPUP_CLOSE });
  };

  const onUpload = (image, errorText) => {
    dispatch({
      type: DISPUTE_UPLOAD_IMAGE,
      image: image,
      errorText: errorText,
    });
  };

  const onSaveDisputeResolution = async () => {
    try {
      if (state.image !== undefined) {
        const s3Filename = await handleUploadToS3(
          state.image,
          getAccessTokenSilently,
        );
        if (s3Filename === "") {
          return;
        }

        const request = new ResolveDisputeApplicationRequest(
          state.disputedApplicationId,
          state.disputedCompanyName,
          selectedOrder.orderId,
          selectedOrder.customerId,
          editingCompanyName,
          editingPositionName,
          editingPositionLink,
          s3Filename,
        );

        console.log("ResolveDisputeApplicationRequest", request);

        await axiosPutWithAuthHeader(
          `/operator/resolve-dispute/${user.sub}/`,
          request,
          getAccessTokenSilently,
          "Resolve dispute application",
        );
      }

      fetchCompaniesInfo(
        selectedOrder.orderId,
        1,
        selectedOrder.customerId,
        null,
        APPLICATION_DISPUTED,
      );
      closeDisputePopup();
    } catch (error) {
      console.log("Resolve dispute error: ", error);
      // dispatch({ type: HANDlE_SAVE_ERROR });
    }
  };

  return (
    <DisputeContext.Provider
      value={{
        ...state,
        triggerDisputePopup,
        closeDisputePopup,
        onUpload,
        onSaveDisputeResolution,
      }}
    >
      {children}
    </DisputeContext.Provider>
  );
};

export const useDisputeContext = () => {
  return useContext(DisputeContext);
};
